export default {
  NAV_IMG: 'logo_es.png',
  HOME: {
    INTRO: {
      TITLE: "Entrenadores de fútbol amateur",
      SUBTITLE: "Te ayudamos a crear sesiones físicas más efectivas",
      BUTTON: "Elija su programa",
      WORDS: ["Jóvenes", "Seniors", "Futbol sala", "Porteros"],
      INITIAL: "Jóvenes",
    },
    CARDS: {
      TITLE: "Eres entrenador ¿qué te gustaría mejorar?",
      CARDS: [{
          IMG: "Grupo 1028.png",
          TITLE: "Mejorar el entrenamiento físico de las personas mayores en el fútbol",
          TEXT: `
          <p>
            Encontrarás información sobre la preparación física en el fútbol para personas mayores.
          </p>
          <p>
            Pero también, ejemplos de preparación física antes de la temporada, ejemplos de preparación física durante las vacaciones de invierno.
          </p>`,
          BUTTON: "Programa pretemporada",
          LINK: "TreveEstivale"
        },
        {
          IMG: "Grupo 1029.png",
          TITLE: "Mejorar el entrenamiento físico de U8 a U17 en Fútbol",
          TEXT: `<p>
            Encontrarás ejemplos de ejercicios para desarrollar las cualidades de coordinación, velocidad, resistencia, flexibilidad o incluso fuerza.
          </p>
          <p>
            También encontrará ejemplos de sesiones y planificación durante 4 semanas para cada categoría desde U8 hasta U17.
          </p>`,
          BUTTON: "Guia para jóvenes",
          LINK: "GuideJeunes"
        },
        {
          IMG: "Grupo 1031.png",
          TITLE: "Mejora tus conocimientos sobre la preparación física de los jóvenes.",
          TEXT: `<p>
            Encontrarás mucha información sobre el desarrollo de los futbolistas jóvenes. Formar a los jóvenes es complejo y conocer sus características (que cambian con la edad) nos parece fundamental. Encontrarás las características generales por categoría de edad.
          </p>
          <p>
            Pero también módulos explicativos sobre cada cualidad física, así como sobre prevención de lesiones, pruebas y preparación mental.
          </p>`,
          BUTTON: "Guia para jóvenes",
          LINK: "GuideJeunes"
        },
      ],
    },
    ICONINFO: [{
        img: "Grupo 665.png",
        text: `<h3>+ de 10,000</h3>
        <p>Los entrenadores y jugadores confían en nosotros</p>`,
      },
      {
        img: "Icon awesome-users.png",
        text: `<h3>+ de 40,000</h3>
        <p>Miembros activos</p>`,
      },
      {
        img: "Icon awesome-facebook-square.png",
        text: `<h3>+ de 100,000</h3>
        <p>Seguidores en facebook e instagram</p>`,
      },
      {
        img: "Grupo 1034.png",
        text: `<h3>1 millón</h3>
        <p>Visitantes al sitio de 172 países</p>`,
      },
    ],
    FORMATION: `Puede ayudarse a sí mismo e inspirarse en los programas que ofrecemos para crear sus propios planes.`,
    INFOSEC2: {
      img: "Gif-B.gif",
      text: `
        <h4 class="first">
          Si quieres ir más allá, también te ofrecemos:
        </h4>
        <h4 class="subtitle">
        Formaciones en línea sobre la preparación física en fútbol amateur
        </h4>
        <p>
        Hemos tenido en cuenta todas las necesidades y peticiones de muchos entrenadores y hemos creado cursos de formación para poder darles respuesta y ayudarte en la medida de lo posible.
        </p>
        <p>El objetivo es darte las claves de la preparación física para el fútbol, claves que podrás utilizar rápidamente en el terreno de juego. Los campos fueron creados por preparadores físicos especializados en fútbol.
      </p>`,
      button: "para saber más, haz clic aquí",
    },
    INFOSEC3: {
      img: "gif-a (1).gif",
      title: "Programas de entrenamiento físico en línea",
      text: `
        <p>
          Ofrecemos programas de entrenamiento físico para jóvenes (u8 a u17) y adultos mayores. Incluyen la parte atlética, calentamiento y enfriamiento, pero no la parte técnica y táctica.
        </p>
        <p>
          Ofrecemos programación progresiva en ciclos de 4 semanas o durante una temporada completa (10 meses) con una temática diferente cada mes (potencia aeróbica, fuerza, velocidad, explosividad, ...) según la época del año.
      </p>`,
      button: "para saber más, haz clic aquí",
    },
    INFOSEC4: {
      img: "Gif-A.gif",
      title: "Ideas para ejercicios físicos",
      text: `<p>
              Descubra guías en línea con muchos ejemplos de ejercicios físicos para jóvenes, adultos mayores, porteros y fútbol sala.
              </p>
              <p>
              Los ejercicios se presentan en formato de video y diagramas 3D.
            </p>
            <p>
              Todas las guías contienen también una parte teórica para entender cómo proponer los ejercicios en cuanto a carga de trabajo (tiempo de ejercicio, tiempo de recuperación, intensidad del ejercicio, número de repeticiones, número de series ...).
            </p>`,
      button: "Para saber más clic aquí",
    },
    INFOSEC5: {
      img: "Grupo 133.png",
      title: 'Hágase miembro y reciba "Correos electrónicos privados de Thibaud"',
      text: `<p>
                Descubre información exclusiva gratuita cada semana para 
                mejorar tu entrenamiento físico y tu actividad de coaching 
                (ideas de ejercicios, consejos sobre coaching, gestión y preparación mental).
              </p>
              <p>
              ¡Más de 40.000 entrenadores reciben nuestros correos electrónicos cada semana!
             </p>`,
      button: "para saber más, haz clic aquí",
    },
    INFOSEC5_MOBILE: {
      img: "Grupo 133.png",
      title: 'Hágase miembro y reciba "Correos electrónicos privados de Thibaud"',
      text: `<p>
                Descubre información exclusiva gratuita cada semana para 
                mejorar tu entrenamiento físico y tu actividad de coaching 
                (ideas de ejercicios, consejos sobre coaching, gestión y preparación mental).
              </p>`,
      button: "para saber más, haz clic aquí",
    },
    APPSEC: {
      img: "Home/gif-g (6).gif",
      title: `¡Descubre 300 ideas de ejercicio físico gratis para entrenadores de fútbol con nuestra aplicación móvil!`,
      title_mobile: '¡Descubre 300 ideas de ejercicio físico gratis con nuestra aplicación móvil!',
      button: "Para saber más clic aquí",
    },
    OPINION_TITLE: `Los entrenadores confían en nosotros: más de 10.000 entrenadores ya se han suscrito a uno de nuestros programas.`,
    OPINION_MOBILE_TITLE: "Los entrenadores y jugadores confían en nosotros…",
  },
  MENU: {
    PAGE_1: [
      'Inicio',
      'Blog',
      `Entrenador de jóvenes,<br>
      empieza aquí`,
      `Entrenador de adultos,<br> empieza aquí`,
      `Ejemplos<br />
      de ejercicios`,
      'Programas',
      'Formaciones',
      'Ver más',
    ],
    PAGE_2: [
      'Tienda en línea',
      'Correos electrónicos privados',
      `Correos<br />de miembros`,
      'Contacto',
      'FAQ',
    ],
    PRINCIPAL: 'Menu Principal',
    PROGRAMMES: [
      `Programas para <br> entrenadores de jóvenes`,
      `Programas para <br> entrenadores seniors`,
    ],
    IDEES: {
      TITLE: "Idées D'exercices",
      EXERCICES: [
        {
          id: 0,
          title: "Guía de 800 ejercicios físicos",
        },
        {
          id: 1,
          title: "200 ideas de juegos reducidos",
        },
        {
          id: 2,
          title: "300 ideas de ejercicios físicos",
        },
        {
          id: 3,
          title: "200 ideas de ejercicios físicos para los porteros",
        },
        {
          id: 4,
          title: "Blog",
        },
        {
          id: 5,
          title: "200 ideas de ejercicios físicos en el fútbol sala",
        },
        {
          id: 6,
          title: "Pack",
        },]
    },
    FORMATION: {
      TITLE: 'Formaciones',
      ITEMS: [
        {
          id: 0,
          title:
            "Formación completa sobre la preparación física en el fútbol amateur",
        },
        {
          id: 1,
          title: "Cómo planificar una temporada en el fútbol amateur",
        },
        {
          id: 2,
          title:
            "Cómo trabajar y desarrollar la velocidad y la resistencia en el fútbol amateur",
        },
        {
          id: 3,
          title:
            "Cómo trabajar y desarrollar la motricidad de los jóvenes",
        },
        {
          id: 4,
          title: "Guía gerencia",
        },
        {
          id: 5,
          title:
            "Guia preparación física de los jóvenes",
        },
        {
          id: 6,
          title: "Pack",
        },
      ],
    },
  },
  FORMATION: {
    INFOSEC1: {
      img: "Gif-B.gif",
      title:
        "Curso en línea sobre la preparación física en el fútbol amateur",
    },
    INFOSEC2: {
      img: "Grupo 1006.png",
      title: "Únase a la lista de espera",
      text: `<p>Recibirá toda la información sobre el curso de preparación física en el fútbol, tendrá prioridad en las inscripciones y obtendrá descuentos</p>`,
      button: "Comience aquí",
    },
    INFOSEC3: {
      img: "garantia.png",
      title: "¡Usted se beneficiará de una satisfacción total garantizada!",
      text: `<p>Si no está satisfecho, por el motivo que sea, ¡le devolveremos su dinero íntegro! Sólo tiene que enviarnos un correo electrónico informándonos de su decisión.</p>`,
    },
    CARD: {
      TITLE: `“Me gustaría tener un grupo de jugadores que estén físicamente en su mejor momento, sin lesiones, que progresen y que apliquen mis ideas de juego, que disfruten en los entrenamientos y en los partidos (y que alegren a mi familia y a los espectadores)” (Ludovic, entrenador)`,
      TEXT: `Hace poco hicimos una encuesta para conocer las necesidades y dificultades de los entrenadores. Tuvimos cientos de respuestas. Tomamos en cuenta todas las necesidades y peticiones de los entrenadores y esperamos que esta nueva versión del curso en línea “Preparación física en el fútbol” satisfaga sus expectativas (así como las de Ludovic). Muy pronto revelaremos toda la información (para los preinscritos).`,
    },
    IMG_CARDS: {
      CARDS: {
        CARD_1: `PREPARACIÓN<br />FÍSICA<br />INTEGRADA`,
        CARD_2: `PERIODIZACIÓN<br />TÁCTICA`
      },
      CONTENT: {
        TITLE: `Por ejemplo, encontrará:`,
        TEXT: `Nuevos métodos y enfoques de entrenamiento y de fútbol, sesiones innovadoras (en las que los jugadores disfrutarán participando), ideas quizás más acorde con su filosofía y sus deseos. `,
      }
    },
    SAISON: {
      TEXT: `Planificaciones de una temporada completa (10 meses de entrenamiento físico con ejemplos de ejercicios) para cada categoría desde la sub8 hasta la senior (pero también para el entrenamiento de porteros y el fútbol sala) para darle una pauta y ejemplos en los cuales basar su trabajo físico y alcanzar sus objetivos. Nuevos ejercicios físicos y eficaces (que los jugadores adoran) `,
      IMG: {
        TITLE: `UNA TEMPORADA COMPLETA`,
        SUB_1: `Porteros Futbol sala`,
        SUB_2: `U8-U17 Seniors`,
      }
    },
    VIDEO_SUB: `Nuevos ejercicios divertidos y efectivos (a los jugadores les encanta)`,
    EXEMPLES: {
      TITLE: `Por ejemplo, encontrará:`,
      CARDS: [
        {
          link:
            "https://www.preparationphysiquefootball.com/images/exercices-inedits.png",
          text: `      
          <h5 class="card-title">
            Des exercices inédits
          </h5>`,
        },
        {
          link:
            "https://www.preparationphysiquefootball.com/images/formation-fabien-richard.png",
          text: `
          <h5 class="card-title">
            Un super bonus (pour l’un des participants) avec l’inscription offerte à une 
            formation de 3 jours (en live) proposée par le préparateur physique de R.Marhez, S.Mané, F.Thauvin, M.pjanic... 
            (plus d’infos sur <a href="https://www.fabienrichardconsulting.com/individuel" target="_blank">cette formation ICI)</a>.
          </h5>`,
        },
      ],
      ATTENTION: {
        TITLE: `Muy pronto develaremos el resto.`,
        CONTENT: [
          `Atención: Para ofrecer un seguimiento más personalizado y de calidad, tenemos que limitar las inscripciones al curso en línea (cientos de entrenadores hicieron la primera versión y recibimos muchas preguntas cada día. `,
          `Por lo tanto, para ofrecer un seguimiento de calidad, tenemos que limitar el número de inscripciones).`,
          `Si este curso le interesa, le sugerimos apuntarse a la lista de espera (recibirá toda la información sobre el curso de preparación física en el fútbol, tendrá prioridad en las inscripciones y obtendrá descuentos).`,
        ]
      }
    },
    QUESTIONS: {
      TITLE: `Preguntas frecuentes`,
      ITEMS: [
        {
          id: 71,
          question:
            "¿Es necesario tener conocimientos básicos sobre la preparación física para hacer el curso en línea?",
          description: `
            <p>
              No, este curso es accesible a todos. Hemos tratado de hacerlo lo más comprensible posible.
            </p>
            <p>
              Sin embargo, ¡tener algún conocimiento básico es obviamente una ventaja!
            </p>`,
        },
        {
          id: 72,
          question:
            "Soy un experto en preparación física, ¿el curso en línea está adaptado a mi nivel?",
          description: `
            <p>
              No tenemos la pretensión ¡ni la legitimidad de proponer cursos para “expertos”! Por lo tanto, ¡proponemos un curso de formación bastante adaptado a los que se inician en el aprendizaje de la preparación física!
            </p>`,
        },
        {
          id: 73,
          question: "¿Es un curso 100% teórico?",
          description: `
          <p>
            No. El objetivo de este curso es darle las claves de la preparación física en el fútbol, pero sobretodo, ¡darle las claves que pueda utilizar inmediatamente en el campo!
          </p>
          <p>
            La parte teórica es esencial, ¡pero la parte práctica será consistente! 
          </p>
          <p>
            ¡Tendrá acceso a numerosos videos de ejercicios!
          </p>`,
        },
        {
          id: 74,
          question: "¿Cómo funciona después de hacer el pedido?",
          description: `
          <p>
            De 1 a 2 minutos después de hacer el pedido, recibirá un correo electrónico de confirmación con el enlace de acceso al curso en línea. Usted debe registrarse (apellido, nombres,...) y tendrá acceso directo al curso. Es decir, unos minutos después de realizar su pedido. 
          </p>`,
        },
        {
          id: 75,
          question: "No entiendo algo del curso",
          description: `
          <p>
            Comparta sus preguntas en el foro del curso, seguro que mucha gente sabrá responderle. ¡Es normal tener dificultades en el aprendizaje!
          </p>`,
        },
        {
          id: 76,
          question: "No estoy de acuerdo con algo del curso",
          description: `
          <p>
            Estamos aquí para intercambiar, ¡háganos saber sus desacuerdos! Este es el principio de un curso dinámico!
          </p>`,
        },
        {
          id: 77,
          question: "¿Por medio de qué soportes se puede acceder al curso?",
          description: `
          <p>
            Nuestro curso puede accederse por todos los tipos de soporte (teléfono, tableta, computadora).
          </p>`,
        },
        {
          id: 78,
          question: "¿Por cuánto tiempo tengo acceso al curso?",
          description: `
          <p>
            Una vez realizado el pedido, tendrá acceso a la plataforma del curso durante 12 meses… ¡lo que le dará tiempo de sobra para verlo todo! Renovamos gratuitamente por otro año (o más) si así lo necesita. 
          </p>`,
        },
        {
          id: 79,
          question: "¿El pago es seguro?",
          description: `
          <p>
            Sí, utilizamos PayPal que es bien conocido y muy seguro.
          </p>`,
        },
        {
          id: 80,
          question: "Si no me gusta el curso, ¿me devuelven el dinero?",
          description: `
          <p>
            ¡Por supuesto que sí! O está satisfecho con lo que ha aprendido y su inversión es rentable, o no lo está; le reembolsamos directamente y usted recupera su dinero. ¡Así que no hay ningún riesgo!
          </p>`,
        },
        {
          id: 81,
          question: "¿Tiene alguna otra pregunta o preocupación?",
          description: `
          <p>
            No hay problema. Escríbanos y le responderemos rápidamente. 
          </p>`,
        },
        {
          id: 82,
          question: "¡Usted se beneficiará de una satisfacción total garantizada!",
          description: `
          <p>
            Si no está satisfecho con el curso, por el motivo que sea, ¡le devolveremos su dinero íntegro! Sólo tiene que enviarnos un correo electrónico (prepafisicafutbol10@gmail.com) informándonos de su decisión. 
          </p>`,
        },
      ],
    },
    MODULOS: {
      TITLE: `Contenido del curso en línea`,
      ITEMS: [
        {
          name: "Módulos",
          count: "1-8",
          items: [
            {
              id: 1,
              title: "Módulo 1: Análisis del fútbol moderno",
              description: `<p>
              Descubrirá: 
                </p>
                <p>
                ¿Cómo se analizan los esfuerzos de los partidos de fútbol, cuáles son los esfuerzos típicos del futbolista (no siempre son los que pensamos)? ¿Cómo influye esto en la preparación física? Las diferentes concepciones de la preparación física (la que inspiró, las nuevas tendencias,…)
                </p>`,
            },
            {
              id: 2,
              title:
                "Módulo 2: Las diversas planificaciones del entrenamiento",
              description: `<p>
                  Descubrirá:
                </p>
                <p>
                  Las particularidades de la planificación en el fútbol (muy diferente de la planificación “clásica” que encontramos en otros deportes).
                </p>
                <p>
                  Cómo evaluar la carga de entrenamiento en el fútbol amateur y saber si sus sesiones son adecuadas (y conocer la condición física de los jugadores).
                </p>
                <p>
                  Ejemplos concretos de planificación para toda la temporada a nivel amateur y todos los períodos claves de una temporada. 
                </p>
                <p>
                  El mejor software de seguimiento (gratuito) en nuestra opinión.
                </p>`,
            },
            {
              id: 3,
              title: "Módulo 3: La fisiología del deporte",
              description: `<p>
                  La fisiología nos permite comprender los efectos de los ejercicios en el cuerpo y muestra cómo se adapta éste. Cubriremos los aspectos básicos de lo que necesita saber. 
                </p>`,
            },
            {
              id: 4,
              title: "Módulo 4: La evaluación del futbolista",
              description: `<p>
                  Descubrirá:
                </p>
                <p>
                  Por qué hacer pruebas. La mejor prueba para las diferentes cualidades físicas. Una aplicación que permite hacer una prueba sencilla (y por menos de 10 euros) y muy útil (la más útil a nivel amateur en nuestra opinión). Los límites de las pruebas.
                </p>`,
            },
            {
              id: 5,
              title: "Módulo 5: La motricidad del jugador moderno",
              description: `<p>
                  Descubrirá:
                </p>
                <p>
                  Qué es la motricidad, cómo desarrollarla en el fútbol. Un nuevo concepto muy interesante para ayudarle a encontrar la posición perfecta para sus jugadores, pero también “para optimizar sus rendimientos y evitar las lesiones”. 
                </p>`,
            },
            {
              id: 6,
              title: "Módulo 6: La resistencia",
              description: `<p>
                  Descubrirá:
                </p>
                <p>
                  Los conceptos clave de la resistencia, los métodos para desarrollar la resistencia en el fútbol, qué método utilizar según la época del año, cómo planificar la resistencia  a lo largo del año, cómo ponerla aprueba, cómo trabajarla en los jóvenes, cómo trabajarla con balón. Usted tendrá acceso a numerosos ejemplos de ejercicios. 
                </p>`,
            },
            {
              id: 7,
              title: "Módulo 7: La velocidad",
              description: `<p>
                  Descubrirá:
                </p>
                <p>
                  Qué es la velocidad en el fútbol. Métodos para desarrollar la velocidad. Cuándo trabajarla durante el año, durante la semana, durante la sesión. Cómo planificarla durante el año. Cómo probarla. ¿Puede trabajarse con balón? Usted tendrá acceso a numerosos ejemplos de ejercicios.
                </p>`,
            },
            {
              id: 8,
              title: "Módulo 8: La fuerza",
              description: `<p>
                  Descubrirá:
                </p>
                <p>
                  Los métodos de desarrollo de la fuerza. Cuándo trabajarla durante el año, durante la semana, durante la sesión. Cómo planificarla durante el año. Cómo probarla. ¿Puede trabajarse con balón? Usted tendrá acceso a numerosos ejemplos de ejercicios.
                </p>`,
            },
          ],
        },
        {
          name: "Módulos",
          count: "9-16",
          items: [
            {
              id: 9,
              title: "Módulo 9: Coordinación",
              description: `
              <p>
              Descubrirá: 
              </p>
              <p>
                Los métodos de desarrollo de la fuerza; cuándo trabajarla en el año, la semana, la sesión; Cómo planificarla en el año; Cómo probarla; ¿Puede trabajarse la fuerza con balón? Tendrá acceso a numerosos ejemplos de ejercicios.
              </p>`,
            },
            {
              id: 10,
              title: "Módulo 10: Flexibilidad",
              description: `
              <p>
              Descubrirá:
              </p>
              <p>
                ¿La flexibilidad es importante en el fútbol? ¿Es necesario estirar antes y después de un partido? ¿Existe un único método de estiramiento? ¿Cómo mejorar la flexibilidad? ¿A qué edad se debe empezar a estirar? ¿Cómo se hace en la práctica?
              </p>`,
            },
            {
              id: 11,
              title: "Módulo 11: Calentamiento en el fútbol",
              description: `
              <p>
              Aprenderá los objetivos y problemas del calentamiento, por dónde empezar (y por dónde no empezar) un calentamiento; tendrá ejemplos de calentamiento típicos. También verá los ejercicios que deben hacerse en el calentamiento para reducir el índice de lesiones en un 50% (según la UEFA).
              </p>`,
            },
            {
              id: 12,
              title: "Módulo 12: Recuperación",
              description: `
              <p>
                Conocerá todos los medios de recuperación del futbolista, los 3 pilares de la recuperación y un protocolo de recuperación.
              </p>`,
            },
            {
              id: 13,
              title: "Módulo 13: Diseño de las sesiones",
              description: `
              <p>
                Aprenderá a crear sesiones en función de diferentes parámetros (su filosofía, sus objetivos, el momento, las instrucciones…) y a dirigir una sesión.
              </p>`,
            },
            {
              id: 14,
              title: "Módulo 14: Jóvenes",
              description: `
              <p>
                Verá las diferencias entre los seniors y los jóvenes, las nociones que hay que saber para entrenar jóvenes, cómo trabajar lo físico para cada categoría de edad y responderemos a muchas preguntas sobre el trabajo físico en los jóvenes (si pueden trabajar la fuerza, la potencia, la flexibilidad, a qué edad se trabaja la velocidad…)
              </p>`,
            },
            {
              id: 15,
              title: "Módulo 15: Entrenamiento mental",
              description: `
              <p>
                El entrenamiento mental en el fútbol todavía no es muy utilizado. Sin embargo, ¡su importancia es enorme! Por ejemplo, aprenderá a mejorar la confianza en sí mismo, a manejar sus emociones, la técnica para fijar objetivos, a mejorar la cohesión grupal…
              </p>`,
            },
            {
              id: 16,
              title: "Módulo 16: Prevención de lesiones",
              description: `
              <p>
              ¿Por qué nos lesionamos? ¿Cómo fortalecer los izquiotibiales, los cuádriceps, los músculos aductores, las pantorrillas? ¿Cuáles son los métodos para fortalecer los tobillos y las rodillas? Aquí encontrará nuestras respuestas y soluciones. Verá qué ejercicios hacer para reducir el índice de lesiones en un 50% (según la UEFA).
              </p>`,
            },
          ],
        },
        {
          name: "Módulos",
          count: "17-24",
          items: [
            {
              id: 17,
              title: "Módulo 17: Equipo",
              description: `
              <p>
              Cómo utilizar las diferentes herramientas para entrenarse en el fútbol amateur, cuáles son las ventajas y desventajas.
              </p>`,
            },
            {
              id: 18,
              title: "Módulo 18: Nutrición del futbolista",
              description: `
              <p>
              ¿Por qué la nutrición es tan importante en el fútbol? ¿Cuáles son las reglas básicas? ¿Qué comer antes y después de un partido? ¿Cómo cambiar los hábitos alimenticios? ¿Cómo hacer que los jugadores pierdan peso? 
              </p>`,
            },
            {
              id: 19,
              title: "Módulo 19: Gestión",
              description: `
              <p>
                Descubrirá:
              </p>
              <p>
                La manera cómo los grandes entrenadores dirigen a su equipo. Cómo escogen a su líder, cómo transmiten sus ideas… Lo que dicen en el primer entrenamiento con los jugadores, en el medio tiempo, cómo dirigen a los suplentes…  
              </p>`,
            },
            {
              id: 20,
              title: "Módulo 20: Programas de entrenamiento físico",
              description: `
              <p>
                Para cada categoría (desde la sub8 a la senior), usted tendrá acceso a un ejemplo de planificación del trabajo físico a lo largo del año (con una media de 2 sesiones de entrenamiento físico por semana, es decir, más de 80 sesiones para cada categoría). Asimismo, tendrá acceso a 40 sesiones físicas para fútbol sala y para los porteros.
              </p>`,
            },
            {
              id: 21,
              title: "Módulo 21: Preparación física integrada",
              description: `
              <p>
                eremos si es pertinente integrar el trabajo físico en el fútbol. Si es así, ¿podemos trabajar todas las cualidades físicas con el balón? Para cada cualidad física, proponemos las ventajas y desventajas de trabajar con el balón. Daremos muchos ejemplos de ejercicios.
              </p>`,
            },
            {
              id: 22,
              title: "Módulo 22: Periodización táctica",
              description: `
              <p>
                Le presentaremos este método de entrenamiento que es utilizado cada vez más. 
              </p>
              <p>
              Descubrirá su filosofía, cómo aplicarla, sus ventajas y desventajas, ejemplos de ejercicios, cómo crear sus propios ejercicios… 
              </p>`,
            },
            {
              id: 23,
              title: "Módulo 23: Bono 1",
              description: `
              <p>
              Aquí habrá consejos de entrenamiento, gestión, fisiología, biomecánica… pero también habrá información sobre la reatletización, el fútbol sala… 
              </p>`,
            },
            {
              id: 24,
              title: "Módulo 24: Bono 2",
              description: `
              <p>
              En este último módulo, le ofrecemos nuestra bibliografía, los libros y las formaciones que recomendamos… le proponemos cuestionarios relacionados con los módulos. 
              </p>`,
            },
          ],
        },
      ],
    },
    OPINION_TITLE: `Los entrenadores confían en nosotros: más de 10000 entrenadores ya se han suscrito a uno de nuestros programas.`,
    OPINION_TITLE_MOBILE: `Los entrenadores y jugadores confían en nosotros…`,
    ICONINFO: [
      {
        img: "soccer.png",
        text: `<h3>ADAPTADO</h3>
      <p>a la categoría amateur</p>`,
      },
      {
        img: "uniform.png",
        text: `<h3>PRÁCTICA</h3>
      <p>Con numerosos ejemplos de videos y de ejemplos de programas</p>`,
      },
      {
        img: "ball.png",
        text: `<h3>DINÁMICA</h3>
      <p>Gracias al foro y a los intercambios con otros entrenadores y formadores</p>`,
      },
      {
        img: "field.png",
        text: `<h3>REFLEXIVO</h3>
      <p>Para integrar y comprender mejor la preparación física</p>`,
      },
    ],
    CONFIANCE: {
      TITLE: `¿POR QUÉ USTED TAMBIÉN DEBERÍA CONFIAR EN NOSOTROS?`,
      CONTENT: [
        `Hemos desarrollado nuestro enfoque tomando lo mejor de cada gran preparador físico o entrenador con el que hemos tenido la suerte de trabajar, pero también lo mejor de nuestras investigaciones y nuestra formación universitaria y privada. `,
        `Por lo tanto, el diseño de este curso en línea se basa en este enfoque que hemos intentado adaptar lo mejor posible a sus necesidades...`,
        `Un curso creado para los preparadores físicos cualificados y especializados en el fútbol. `,
      ],
    },
    END: {
      TITLE: `Únase a la lista de espera`,
      TEXT: `Recibirá toda la información sobre el curso de preparación física en el fútbol,  tendrá prioridad en las inscripciones y se beneficiará de descuentos.`,
      BUTTON: `Comience aquí`,
    }
  },
  COACH_JEUNES: {
    INFOSEC1: {
      img: "GIF-F (6).gif",
      title: "",
      text: `<h5>
              Si ha llegado a este sitio, podría preguntarse: ¿Por qué este blog sobre la preparación física en el fútbol amateur y cómo puede ayudarme?
             </h5>`,
    },
    INFOSEC2: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
        Hace algunos años, durante una formación de entrenamiento con varios preparadores físicos y entrenadores de nivel amateur, todos nos dimos cuenta de la falta de información sobre la preparación física en el fútbol amateur.
        </p>
        <p>
        Así, ¡se puso en marcha la idea de crear un blog para compartir e intercambiar conocimientos sobre la preparación física! 
        </p>
        <p>
        Y rápidamente cientos de entrenadores me hablaron de su gratitud y sus necesidades (y de ayudarles en lo posible).
        </p>`,
    },
    INFOSEC2_MOBILE: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
      Hace algunos años, durante una formación de entrenamiento con varios preparadores físicos y entrenadores de nivel amateur, todos nos dimos cuenta de :
     </p>
     <p>La falta de información sobre la preparación física en el fútbol amateur.</p>
     <p>Así, ¡se puso en marcha la idea de crear un blog para compartir e intercambiar conocimientos sobre la preparación física!</p>
     <p>
     Y rápidamente cientos de entrenadores me hablaron de su gratitud y sus necesidades (y de ayudarles en lo posible).
     </p>`,
    },
    INFOSEC3: {
      img: "Gif-B.gif",
      title: "Para ello, he puesto varios medios a disposición: ",
      text: `<p>
             Por ejemplo, usted tiene acceso a un blog con información totalmente gratuita sobre 
             muchos temas relacionados con la preparación física en el fútbol, así como sobre la gestión, 
             la preparación mental o incluso sobre nutrición. </p>
             
             <p>Hay muchos artículos para leer y 
             ¡no es necesariamente fácil encontrar el camino! Por eso hemos creado categorías en el blog
             para facilitar la búsqueda.
             </p>`,
      button: "Ir al blog",
    },
    INFOSEC3_MOBILE: {
      img: "Gif-B.gif",
      title: "Para ello, he puesto varios medios a disposición: ",
      text: `<p>
         Por ejemplo, usted tiene acceso a un blog con información totalmente gratuita sobre muchos temas relacionados con la preparación física en el fútbol, así como sobre la gestión, la preparación mental o incluso sobre nutrición.
        </p>
        <p>Hay muchos artículos para leer y 
         ¡no es necesariamente fácil encontrar el camino! Por eso hemos creado categorías en el blog
         para facilitar la búsqueda.
         </p>`,
      button: "Ir al blog",
    },
    APPSEC: {
      img: "Coaches/Grupo 1025@2x.png",
      title: `Usted puede encontrar 300 ejemplos de ejercicios físicos en nuestra APLICACIÓN MÓVIL`,
      button: "PARA SABER MÁS, HAGA CLIC AQUÍ",
    },
    LIST_1: {
      TITLE: `Este es un rápido resumen de las necesidades y peticiones que surgen a menudo:`,
      ITEMSL: [
        "Me gustaría tener ideas de ejercicios físicos para variar mis entrenamientos (para disminuir la monotonía y motivar a mis jugadores)",
        "Me gustaría saber qué cualidad física trabajar principalmente con mis jugadores sub12",
        "Me gustaría saber cómo desarrollar la resistencia, la fuerza y la velocidad de mis jugadores sub15",
        "Me gustaría saber cómo planificar el trabajo físico en la temporada (para evitar los errores y permitir a mis jugadores sub14 mejorar a lo largo de la temporada)",
      ],
      ITEMSR: [
        "Me gustaría tener ideas de ejercicios físicos adaptados a mi grupo de jugadores sub10.",
        "Me gustaría saber cómo trabajar la coordinación con mis jugadores sub8",
        "Me gustaría saber cómo utilizar los estiramientos con mis jugadores sub13 (y así poder aconsejar a mis jugadores y ofrecerles un trabajo eficaz)",
        "Me gustaría tener información sobre los diferentes métodos de preparación física en el fútbol amateur, por ejemplo, la preparación física integrada o la periodización táctica (y saber si estos métodos son relevantes para los jugadores jóvenes)",
      ],
      END: `¡Hemos recibido cientos de peticiones como éstas!`
    },
    ROTATE: {
      TITLE: "¡Hemos recibido cientos de peticiones como éstas!",
      ICONINFO: [
        `<h3>Más de 10000</h3>
        <p>Actualmente, ¡más de 10000 entrenadores se han unido a una de nuestras guías, programas o cursos!</p>`,
        `<h3>Más de 40000</h3>
        <p>Más de  40000 personas leen los consejos diarios por correo electrónico.</p>`,
        `<h3>Más de 100000</h3>
        <p>Más de 100000 personas siguen las publicaciones en Facebook e Instagram.</p>`,
      ],
      ICONINFO_MOBILE: [
        `<h3>Más de 10000</h3>
        <p>Los entrenadores y jugadores confían en nosotros</p>`,
        `<h3>Más de 40000</h3>
        <p>Más de 40.000 leen los consejos diarios por correo electrónico.</p>`,
        `<h3>Más de 100000</h3>
        <p>Miembros activos</p>`,
      ],
      END: `Para resumir rápidamente, quiero ayudarles intentando satisfacer las necesidades de cada uno.`,
    },
    CARDS: [{
        img: "Grupo 1022.png",
        text: "También tiene acceso a una pequeña guía de información sobre los jóvenes",
        buttons: [{
          text: "GUÍA PARA LOS JÓVENES",
          link: 'GuideJeunes',
          external: false,
        }],
      },
      {
        img: "football.png",
        text: "Usted podrá encontrar ejemplos de ejercicios en nuestras páginas Facebook e Instagram",
        buttons: [{
          text: "Facebook",
          link: 'https://www.facebook.com/preparationphysiquefootball/',
          external: true,
        }, {
          text: "Instagram",
          link: 'https://www.instagram.com/preparationphysiquefootball/',
          external: true,
        }, ]
      },
    ],
    LINKS: {
      TITLE: `Si desea profundizar, también puede acceder a las guías, programas y cursos en línea:`,
      CHECKSL: [{
          text: "Una guía con más de 800 ejercicios físicos",
          next: "Exercices",
        },
        {
          text: "Programas sobre ciclos de 4 semanas",
          next: "CoachJeunes",
        },
      ],
      CHECKSR: [{
          text: "Una guía sobre gestión",
          next: "GuideManagement",
        },
        {
          text: "Programas sobre una temporada completa",
          next: "SaisonJeunes",
        },
      ],
      CHECKM: {
        text: "Un curso con más módulos sobre todo lo relacionado con la optimización del rendimiento",
        next: "Formation",
      },
    },
    LIST_2: {
      TITLE: 'Esperamos satisfacer todas sus necesidades y, en definitiva, permitirle:',
      ITEMSA: [
        `Ser autónomo en la aplicación de la preparación física y no tener que pedir consejos todo el tiempo (en su lugar, conviértase en la persona a quien le pedimos consejo ;) )`,
        `Tener un sentimiento de competencia en el campo de la preparación física y que su trabajo sea reconocido por sus jugadores, los otros entrenadores, sus familiares… `,
        `O para permitirle ¡intercambiar más fácilmente información sobre la preparación física con los otros jugadores y entrenadores!`,
      ],
    }
  },
  COACH_SENIOR: {
    INFOSEC1: {
      img: "GIF-F (6).gif",
      title: "",
      text: `<h5>
              Si ha llegado a este sitio web, podría preguntarse: ¿Por qué este blog sobre la preparación física en el fútbol amateur y en qué puede ayudarme?
             </h5>`,
    },
    INFOSEC2: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
              Hace algunos años, durante una formación de entrenamiento con varios preparadores físicos y entrenadores de nivel amateur, todos nos dimos cuenta de la falta de información sobre la preparación física en el fútbol amateur.
             </p>
             <p>
              Así, ¡se puso en marcha la idea de crear un blog para compartir e intercambiar conocimientos sobre la preparación física! <br> Y rápidamente cientos de entrenadores me hablaron de su gratitud y sus necesidades (y de ayudarles en lo posible).
             </p>`,
    },
    INFOSEC2_MOBILE: {
      img: "GIF-e.gif",
      title: "",
      text: `<p>
      Hace algunos años, durante una formación de entrenamiento con varios preparadores físicos y entrenadores de nivel amateur, todos nos dimos cuenta de :
     </p>
     <p>La falta de información sobre la preparación física en el fútbol amateur.</p>
     <p>Así, ¡se puso en marcha la idea de crear un blog para compartir e intercambiar conocimientos sobre la preparación física!</p>
     <p>
      Y rápidamente cientos de entrenadores me hablaron de su gratitud y sus necesidades (y de ayudarles en lo posible).
     </p>`,
    },
    INFOSEC3: {
      img: "Gif-B.gif",
      title: "Para ello, he puesto varios medios a disposición:",
      text: `<p>
              Por ejemplo, usted tiene acceso a un blog con información totalmente gratuita sobre muchos temas relacionados con la preparación física en el fútbol, así como sobre la gestión, la preparación mental o incluso sobre nutrición. Hay muchos artículos para leer y ¡no es necesariamente fácil encontrar el camino! Por eso he preparado un lo “mejor de” de los artículos del blog, un auténtico resumen de los artículos imprescindibles que hay que leer.
             </p>`,
      button: "Lo mejor de los artículos",
    },
    INFOSEC3_MOBILE: {
      img: "Gif-B.gif",
      title: "Para ello, he puesto varios medios a disposición:",
      text: `<p>
            Por ejemplo, usted tiene acceso a un blog con información totalmente gratuita sobre muchos temas relacionados con la preparación física en el fútbol, así como sobre la gestión, la preparación mental o incluso sobre nutrición.
        </p>
        <p>
            Hay muchos artículos para leer y ¡no es necesariamente fácil encontrar el camino! Por eso he preparado un lo “mejor de” de los artículos del blog, un auténtico resumen de los artículos imprescindibles que hay que leer.
        </p>`,
      button: "Lo mejor de los artículos",
    },
    APPSEC: {
      img: "Coaches/Grupo 1025@2x.png",
      title: `Usted puede encontrar 300 ejemplos de ejercicios físicos en nuestra APLICACIÓN MÓVIL`,
      button: "PARA SABER MÁS, HAGA CLIC AQUÍ",
    },
    LIST_1: {
      TITLE: `Este es un rápido resumen de las necesidades y peticiones que surgen a menudo: `,
      ITEMSL: [
        "Me gustaría tener ideas de ejercicios físicos para variar mis entrenamientos (para disminuir la monotonía y motivar a mis jugadores)",
        "Me gustaría saber qué cualidad física trabajar principalmente con mis jugadores ",
        "Me gustaría saber cómo utilizar los estiramientos (y poder aconsejar a mis jugadores y ofrecerles un trabajo eficaz)",
        "Me gustaría tener información sobre los diferentes métodos de preparación física en el fútbol amateur, por ejemplo la preparación física o la periodización táctica. ",
      ],
      ITEMSR: [
        "Me gustaría tener ideas de sesiones físicas adaptadas a mi grupo de seniors.",
        "Me gustaría saber cómo optimizar la recuperación de mis jugadores (y poder responderles cuando me pidan consejo)",
        "Me gustaría saber cómo planificar el trabajo físico en la temporada (para evitar los errores y permitir a mis jugadores mejorar a lo largo de la temporada)",
      ],
      END: `¡Hemos recibido cientos de peticiones como éstas!`
    },
    ROTATE: {
      TITLE: "¡Hemos recibido cientos de peticiones como éstas!",
      ICONINFO: [
        `<h3>+ de 10000</h3>
        <p>Actualmente, ¡más de 10000 entrenadores se han unido a una de nuestras guías, programas o cursos!</p>`,
        `<h3>+ de 40000</h3>
        <p>Más de  40000 personas leen los consejos diarios por correo electrónico.</p>`,
        `<h3>+ de 100000</h3>
        <p>Más de 100000 personas siguen las publicaciones en Facebook e Instagram.</p>`,
      ],
      ICONINFO_MOBILE: [
        `<h3>+ de 10000</h3>
        <p>Los entrenadores y jugadores confían en nosotros</p>`,
        `<h3>+ de 40000</h3>
        <p>Más de  40000 personas leen los consejos diarios por correo electrónico.</p>`,
        `<h3>+ de 100000</h3>
        <p>Miembros activos</p>`,
      ],
      END: `Para resumir rápidamente, quiero ayudarles intentando satisfacer las necesidades de cada uno.`,
    },
    CARDS: [{
        img: "player.svg",
        text: "También tiene acceso a un ejemplo de programa para el trabajo físico de pretemporada.",
        buttons: [{
          text: "Programa de pretemporada",
          link: 'TreveEstivale',
          external: false,
        }],
      },
      {
        img: "football.png",
        text: "Usted podrá encontrar ejemplos de ejercicios en nuestras páginas de Facebook e Instagram",
        buttons: [{
          text: "Facebook",
          link: 'https://www.facebook.com/preparationphysiquefootball/',
          external: true,
        }, {
          text: "Instagram",
          link: 'https://www.instagram.com/preparationphysiquefootball/',
          external: true,
        }, ]
      },
    ],
    LINKS: {
      TITLE: `Si desea profundizar, también puede acceder a las guías, programas y cursos en línea:`,
      CHECKSL: [{
          text: "Una guía con más de 800 ejercicios físicos",
          next: "Exercices",
        },
        {
          text: "Programas sobre ciclos de 4 semanas",
          next: "special-vitesse",
        },
      ],
      CHECKSR: [{
          text: "Una guía sobre gestión",
          next: "GuideManagement",
        },
        {
          text: "Programas sobre una temporada completa",
          next: "SaisonSenior",
        },
      ],
      CHECKM: {
        text: "Un curso con más de 20 módulos sobre todo lo relacionado con la optimización del rendimiento",
        next: "Formation",
      },
    },
    LIST_2: {
      TITLE: 'Esperamos satisfacer todas sus necesidades y, en definitiva, permitirle:',
      ITEMSA: [
        `Ser autónomo en la aplicación de la preparación física y no tener que pedir consejos todo el tiempo (en su lugar, conviértase en la persona a quien le pedimos consejo ;) )`,
        `Tener un sentimiento de competencia en el campo de la preparación física y que su trabajo sea reconocido por sus jugadores, los otros entrenadores, sus familiares… `,
        `O para permitirle ¡intercambiar más fácilmente información sobre la preparación física con los otros jugadores y entrenadores! `,
      ],
    }
  },
  SAISON_SENIOR: {
    INFOSEC1: {
      img: "gif-a (1).gif",
      title: "¿No cuenta con un preparador físico en su club?",
      text: `<p>
            Planificar el trabajo físico y crear sesiones eficaces es bastante complejo.
           </p>
           <p>
            Muchos de entrenadores nos han pedido un poco de “ayuda” para la creación de ciclos de trabajo y de sesiones físicas.
           </p>`,
      button: "Ver las sesiones para SENIORS",
    },
    INFOSEC2: {
      img: "Grupo 633@2x.png",
      title: "Las sesiones también están disponibles para los teléfonos celulares, las tabletas y las computadoras. ",
      text: `<p>
            Programación progresiva con un tema diferente cada mes (ciclo de 4 semanas) durante 10 meses. Las sesiones son con y sin balón. <br> 1 sesión por semana para las categorías FUTSAL y PORTEROS y 2 o 3 sesiones por semana para las categorías seniors (a elegir).
           </p>
           <p>
           Todo está adaptado al fútbol amateur y usted tiene la opción de modificar o no los ejercicios propuestos según su grupo. 
           </p>
           <p>
            Los temas son variados y se programan según el período del año (“potencia aeróbica”, “fuerza”, “velocidad”, “explosividad”,...) Las sesiones incluyen la parte atlética, el calentamiento y el enfriamiento, pero no la parte técnico-táctica.
           </p>`,
      button: "Ver las sesiones para SENIORS",
    },
    ROTATE: {
      TITLE: `Sesiones de entrenamiento físico para una temporada completa`,
      SUBTITLE: `Hemos intentado satisfacer esta necesidad y ahora ofrecemos sesiones de entrenamiento 
      físico (ciclo de 4 semanas) para una temporada completa (10 meses)`,
      ICONINFO: [{
          img: "message.png",
          text: `<p>¡Recibirá 10 meses de sesiones de preparación física!</p>`,
        },
        {
          img: "clock.png",
          text: `<p>¡Usted ahorra un tiempo precioso y aprende al mismo tiempo!</p>`,
        },
        {
          img: "mail.png",
          text: `<p>Puede debatir en el foro con otros entrenadores y preparadores físicos.</p>`,
        },
        {
          img: "lightbulb.png",
          text: `<p>Puede modificar sus sesiones, adaptarlas o inspirarse en ellas…</p>`,
        }
      ],
    },
    CARDS: {
      TOP: `Después de hacer el pedido, recibirá directamente un correo electrónico 
      con el enlace de acceso a la plataforma en línea y a sus sesiones.`,
      TITLE: `SESIONES de entrenamiento físico para una temporada completa`,
      TITLE_MOBILE: `SESIONES DURANTE UNA TEMPORADA`,
      BUTTON: 'Toda la información aquí (sesiones para jóvenes y seniors)',
      CARDS1: [{
          img: "Senior2.png",
          category: "2 sesiones por semana",
          body: {
            sessions: "8 sesiones / mes",
            interval: "Sus sesiones durante 10 meses (de agosto a mayo)",
          },
        },
        {
          img: "Senior3.png",
          category: "3 sesiones por semana",
          body: {
            sessions: "12 sesiones / mes",
            interval: "Sus sesiones durante 10 meses (de agosto a mayo)",
          },
        }
      ],
      CARDS2: [{
          img: "Senior4.png",
          category: "Sesiones de porteros",
          body: {
            sessions: "4 sesiones / mes",
            interval: "Sus sesiones durante 10 meses (de agosto a mayo)",
          },
        },
        {
          img: "Senior1.png",
          category: "Sesiones de fútbol sala",
          body: {
            sessions: "4 sesiones / mes",
            interval: "Sus sesiones durante 10 meses (de agosto a mayo)",
          },
        }
      ],
    },
    GARANTIE: {
      TITLE: 'Garantía de satisfacción o le devolvemos su dinero',
      SUBTITLE: 'Si usted no está completamente satisfecho, ¡envíe un simple correo electrónico pidiendo la devolución de su dinero!',
    },
    PAIEMENT: {
      TITLE: 'Pago 100% seguro',
      SUBTITLE: 'El pago es totalmente seguro por las plataformas reconocidas como PayPal.',
    },
    CONTACT: {
      TEXT_1: 'Si tiene alguna duda sobre la guía, no dude en preguntarnos',
      TEXT_2: 'en el chat (abajo, a la derecha de la página) o en nuestra',
      LINK: 'página de contacto'
    },
    EXERCICES: {
      TITLE: "Ejemplos de ejercicios de preparación física",
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en las sesiones de entrenamiento físico.`,
      CARDS: [{
          id: 0,
          img: "Imagen 90.png",
          title: 'INTERMITENTE 30-30 AL 100 % DE LA VAM “INTERMITENTE” DEL JUGADOR (CON PASE)',
          sub: "Ejemplo de ejercicio de resistencia",
          text: `
        <p>Trabajo de apoyos (pies) + al final de la carrera el jugador debe realizar un pase (objetivo: precisión, pase aplicado a pesar de la fatiga y la velocidad).</p>
        <p>2 grupos: cuando un grupo corre, el segundo grupo recupera y realiza el pase a los jugadores que llegan al final de la carrera.</p>`,
          hide: ` <p>Usted puede variar el pase (por tierra, en el aire, pase de pecho, de cabeza, etc.) Cada distancia se individualiza en función de la VAM intermitente de sus jugadores (VAM calculada por un test intermitente como el 30-15 o el 45-15, por ejemplo). 2 series de 10 repeticiones (10 carreras de 30 segundos por serie) según el nivel y la condición física de sus jugadores. 3 minutos de recuperación entre las 2 series. Recuperación pasiva (caminando) entre las carreras.</p>`,
          open: false,
        },
        {
          id: 1,
          img: "Imagen 92.png",
          title: '“TRABAJO DE PIES-FUERZA + REACCIÓN”',
          sub: "Ejemplos de ejercicios de resistencia",
          text: `<p>Los jugadores realizan un trabajo de fuerza (saltos sobre vallas, pliometría), luego, al llegar a los conos amarillos, uno de los dos jugadores (aquí el jugador azul en el diagrama) elige la dirección de la carrera rápida (a derecha o a izquierda).</p>`,
          hide: `<p>El otro jugador tiene que tocarlo antes de que cruce la línea de meta (los 2 conos naranjas). Puede cambiar el trabajo de fuerza por un trabajo de coordinación o de pies, o por un trabajo de frecuencia o simplemente una aceleración con frenado… 5 o 6 turnos por cada jugador (recuperación de 45 segundos a 1 minuto)</p>`,
          open: false,
        },
        {
          id: 2,
          img: "Imagen 91.png",
          title: "INTERMITENTE DE FUERZA",
          sub: "Ejemplos de ejercicios de resistencia",
          text: `<p>15 segundos de esfuerzo de tipo pliométrico (saltos) - 15 segundos de recuperación</p>
        <p>15 segundos de carrera a VAM - 15 segundos de recuperación</p>`,
          hide: `<p>15 segundos de esfuerzo de tipo pliométrico (saltos) - 15 segundos de recuperación, 15 segundos de carrera a VAM - 15 segundos de recuperación… etc.  durante 7 a 10 minutos (según el nivel y la condición física de sus jugadores) 2 series 1. Saltos ida y vuelta sobre bancos 2. Apoyos de pies en la escalera rítmica 3. Saltos con las piernas extendidas 4. Saltos con las piernas flexionadas 5. Apoyos de pies escalonados</p>`,
          open: false,
        },
      ],
    },
    CHECK: {
      TITLE: 'Cada sesión incluye:',
      CONTENT: `Una propuesta de calentamiento con 2 a 4 ejercicios. <br />
      El cuerpo de la sesión con 3 a 4 ejercicios de trabajo de una de las cualidades físicas. <br />
      Enfriamiento.`,
      CONTENT_MOBILE: [
        `Una propuesta de calentamiento con 2 a 4 ejercicios.`,
        `El cuerpo de la sesión con 3 a 4 ejercicios de trabajo de una de las cualidades físicas.`,
        `Enfriamiento.`,
      ]
    },
    OPINION_TITLE: 'Los entrenadores y jugadores confían en nosotros…',
    END: {
      TEXT: `¿Estás interesado en un programa?`,
      BUTTON: 'Toda la información aquí',
    }
  },
  SAISON_JEUNES: {
    INFOSEC1: {
      img: "gif-a (1).gif",
      title: "¿No cuenta con un preparador físico en su club?",
      text: `<p>
            Planificar el trabajo físico y crear sesiones eficaces es bastante complejo.
           </p>
           <p>
            Muchos de entrenadores nos han pedido un poco de “ayuda” para la creación de ciclos de trabajo y de sesiones físicas.
           </p>`,
      button: "Ver las sesiones para jóvenes",
    },
    INFOSEC2: {
      img: "Grupo 633@2x.png",
      title: "Las sesiones también están disponibles para los teléfonos celulares, las tabletas y las computadoras. ",
      text: `<p>
             Programación progresiva con un tema diferente cada mes (ciclo de 4 semanas) durante 10 meses. Las sesiones son con y sin balón. <br> 1 sesión por semana para las categorías FUTSAL y PORTEROS y 2 o 3 sesiones por semana para las categorías seniors (a elegir).
           </p>
           <p>
            Todo está adaptado al fútbol amateur y usted tiene la opción de modificar o no los ejercicios propuestos según su grupo. 
           </p>
           <p>
           Los temas son variados y se programan según el período del año (“potencia aeróbica”, “fuerza”, “velocidad”, “explosividad”,...) Las sesiones incluyen la parte atlética, el calentamiento y el enfriamiento, pero no la parte técnico-táctica.
           </p>`,
      button: "Ver las sesiones para jóvenes",
    },
    ROTATE: {
      TITLE: `Sesiones de entrenamiento físico para una temporada completa`,
      SUBTITLE: `Hemos intentado satisfacer esta necesidad y ahora ofrecemos sesiones de entrenamiento 
      físico (ciclo de 4 semanas) para una temporada completa (10 meses)`,
      ICONINFO: [{
          img: "message.png",
          text: `<p>¡Recibirá 10 meses de sesiones de preparación física!</p>`,
        },
        {
          img: "clock.png",
          text: `<p>¡Usted ahorra un tiempo precioso y aprende al mismo tiempo!</p>`,
        },
        {
          img: "mail.png",
          text: `<p>Puede debatir en el foro con otros entrenadores y preparadores físicos.</p>`,
        },
        {
          img: "lightbulb.png",
          text: `<p>Puede modificar sus sesiones, adaptarlas o inspirarse en ellas…</p>`,
        }
      ],
    },
    CARDS: {
      TOP: `Después de hacer el pedido, recibirá directamente un correo electrónico 
      con el enlace de acceso a la plataforma en línea y a sus sesiones.`,
      TITLE: `SESIONES de entrenamiento físico para una temporada completa`,
      TITLE_MOBILE: `SESIONES DURANTE UNA TEMPORADA`,
      BUTTON: 'Toda la información aquí (sesiones para jóvenes y seniors)',
      CARDS1: [{
          img: "Grupo 1051.png",
          category: "Categorías sub8-sub9",
          body: {
            sessions: "2 sesiones por semana",
            interval: "Sus sesiones durante 10 meses (de agosto a mayo)",
          },
        },
        {
          img: "Grupo 157.png",
          category: "Categorías sub10-sub11",
          body: {
            sessions: "2 sesiones por semana",
            interval: "Sus sesiones durante 10 meses (de agosto a mayo)",
          },
        },
        {
          img: "Grupo 1053.png",
          category: "Categorías sub12-sub13",
          body: {
            sessions: "2 sesiones por semana",
            interval: "Sus sesiones durante 10 meses (de agosto a mayo)",
          },
        },
      ],
      CARDS2: [{
          img: "Grupo 1055.png",
          category: "Categorías sub14-sub15",
          body: {
            sessions: "2 sesiones por semana",
            interval: "Sus sesiones durante 10 meses (de agosto a mayo)",
          },
        },
        {
          img: "Grupo 1057.png",
          category: "Categorías sub16-sub17",
          body: {
            sessions: "2 sesiones por semana",
            interval: "Sus sesiones durante 10 meses (de agosto a mayo)",
          },
        },
      ],
    },
    GARANTIE: {
      TITLE: 'Garantía de satisfacción o le devolvemos su dinero',
      SUBTITLE: 'Si usted no está completamente satisfecho, ¡envíe un simple correo electrónico pidiendo la devolución de su dinero!',
    },
    PAIEMENT: {
      TITLE: 'Pago 100% seguro',
      SUBTITLE: 'El pago es totalmente seguro por las plataformas reconocidas como PayPal.',
    },
    CONTACT: {
      TEXT_1: 'Si tiene alguna duda sobre la guía, no dude en preguntarnos',
      TEXT_2: 'en el chat (abajo, a la derecha de la página) o en nuestra',
      LINK: 'página de contacto'
    },
    EXERCICES: {
      TITLE: "Ejemplos de ejercicios de preparación física",
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en las sesiones de entrenamiento físico.`,
      CARDS: [{
          id: 0,
          img: "Imagen 90.png",
          title: 'INTERMITENTE 30-30 AL 100 % DE LA VAM “INTERMITENTE” DEL JUGADOR (CON PASE)',
          sub: "Ejemplo de ejercicio de resistencia",
          text: `
        <p>Trabajo de apoyos (pies) + al final de la carrera el jugador debe realizar un pase (objetivo: precisión, pase aplicado a pesar de la fatiga y la velocidad).</p>
        <p>2 grupos: cuando un grupo corre, el segundo grupo recupera y realiza el pase a los jugadores que llegan al final de la carrera.</p>`,
          hide: ` <p>Usted puede variar el pase (por tierra, en el aire, pase de pecho, de cabeza, etc.) Cada distancia se individualiza en función de la VAM intermitente de sus jugadores (VAM calculada por un test intermitente como el 30-15 o el 45-15, por ejemplo). 2 series de 10 repeticiones (10 carreras de 30 segundos por serie) según el nivel y la condición física de sus jugadores. 3 minutos de recuperación entre las 2 series. Recuperación pasiva (caminando) entre las carreras.</p>`,
          open: false,
        },
        {
          id: 1,
          img: "Imagen 92.png",
          title: '“TRABAJO DE PIES-FUERZA + REACCIÓN”',
          sub: "Ejemplos de ejercicios de resistencia",
          text: `<p>Los jugadores realizan un trabajo de fuerza (saltos sobre vallas, pliometría), luego, al llegar a los conos amarillos, uno de los dos jugadores (aquí el jugador azul en el diagrama) elige la dirección de la carrera rápida (a derecha o a izquierda).</p>`,
          hide: `<p>El otro jugador tiene que tocarlo antes de que cruce la línea de meta (los 2 conos naranjas). Puede cambiar el trabajo de fuerza por un trabajo de coordinación o de pies, o por un trabajo de frecuencia o simplemente una aceleración con frenado… 5 o 6 turnos por cada jugador (recuperación de 45 segundos a 1 minuto)</p>`,
          open: false,
        },
        {
          id: 2,
          img: "Imagen 91.png",
          title: "INTERMITENTE DE FUERZA",
          sub: "Ejemplos de ejercicios de resistencia",
          text: `<p>15 segundos de esfuerzo de tipo pliométrico (saltos) - 15 segundos de recuperación</p>
        <p>15 segundos de carrera a VAM - 15 segundos de recuperación</p>`,
          hide: `<p>15 segundos de esfuerzo de tipo pliométrico (saltos) - 15 segundos de recuperación, 15 segundos de carrera a VAM - 15 segundos de recuperación… etc.  durante 7 a 10 minutos (según el nivel y la condición física de sus jugadores) 2 series 1. Saltos ida y vuelta sobre bancos 2. Apoyos de pies en la escalera rítmica 3. Saltos con las piernas extendidas 4. Saltos con las piernas flexionadas 5. Apoyos de pies escalonados</p>`,
          open: false,
        },
      ],
    },
    CHECK: {
      TITLE: 'Cada sesión incluye:',
      CONTENT: `Una propuesta de calentamiento con 2 a 4 ejercicios. <br />
      El cuerpo de la sesión con 3 a 4 ejercicios de trabajo de una de las cualidades físicas. <br />
      Enfriamiento.`,
      CONTENT_MOBILE: [
        `Una propuesta de calentamiento con 2 a 4 ejercicios.`,
        `El cuerpo de la sesión con 3 a 4 ejercicios de trabajo de una de las cualidades físicas.`,
        `Enfriamiento.`,
      ]
    },
    OPINION_TITLE: 'Los entrenadores y jugadores confían en nosotros…',
    END: {
      TEXT: `¿Estás interesado en un programa?`,
      BUTTON: 'Toda la información aquí',
    }
  },
  EXERCICES: {
    QUESTIONS: {
      TITLE: 'GUÍA DE 800 EJERCICIOS',
      SUBTITLE: 'DIAGRAMAS Y VIDEOS EN 3D',
      ITEMS: [
        "¿A veces le faltan ideas para aportar algo nuevo a sus sesiones de entrenamiento (grupales o individuales)?",
        "¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?",
        "¿Le gustaría ofrecer calentamiento y ejercicios físicos más divertidos que sus jugadores aprecien?",
        "¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?",
      ],
    },
    LE_GUIDE: {
      TITLE: 'La guía en línea está disponible para teléfonos celulares, tabletas y computadoras.',
      CONTENT: {
        TEXT_1_1: `Ofrecemos una “guía” en línea con más de 800 ejercicios en video y diagramas en formato 3D.`,
        TEXT_1_2: `Comprende una parte de “explicación” para cada cualidad física y una parte de “ejercicios”. Estos ejercicios son para realizarlos sin equipo (con o sin balón) o con poco equipo (que todos los clubes pueden tener, con y sin balón).`,
        TEXT_2_1: `Esta guía está dirigida a entrenadores y jugadores que buscan ideas de ejercicios para sus sesiones de entrenamiento físico.`,
        TEXT_2_2: `Más de 700 jugadores`,
        TEXT_2_3: `y entrenadores utilizan ya esta guía!`,
        TEXT_3_1: "A través de",
        TEXT_3_2: "800 ejercicios en formato de videos y diagramas 3D",
        TEXT_3_3: ", usted descubrirá los diferentesmétodos (y ejercicios) de entrenamiento posibles de:",
        TEXT_4: `La guía en línea posee “garantía de devolución de dinero”, por lo que no corre ningún riesgo al pedirla. Si no le gusta, le devolveremos el dinero directamente.`,
        CHECKS: [
          'Velocidad',
          'Fuerza',
          'Resistencia',
          'Coordinación',
          'Flexibilidad',
          'Calentamiento',
          'Prevención de lesiones',
        ],
      }
    },
    VOTRE: {
      TITLE: 'SU GUÍA',
      CONTENT: {
        TEXT_1: `Encontrará una “biblioteca” o “repertorio” de ejercicios para
        variar sus entrenamientos (en el club o individual). Para
        progresar, a veces (o a menudo) es necesario aportar variedad y
        novedad, con el fin de sorprender al cuerpo (fuente de progreso)
        y de evitar la monotonía (pérdida de motivación,
        sobre-entrenamiento).`,
        TEXT_2: 'Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios! ',
        TEXT_3: "Usted puede proponer (o hacer) los mismos ejercicios, pero también puede inspirarse y crear sus propios ejercicios. ",
        TEXT_4: "¡El principal objetivo es darle ideas y ejemplos que le sirvan de base sobre la cual diseñar sus propios ejercicios!",
        TEXT_5: '¿Está interesado en la Guía de 800 ejercicios?',
        BUTTON: 'Toda la información aquí',
      }
    },
    GARANTIE: {
      TITLE: 'Garantía de satisfacción o le devolvemos su dinero',
      SUBTITLE: 'Si usted no está completamente satisfecho, ¡envíe un simple correo electrónico pidiendo la devolución de su dinero!',
    },
    PAIEMENT: {
      TITLE: 'Pago 100% seguro',
      SUBTITLE: 'El pago es totalmente seguro por las plataformas reconocidas como PayPal.',
    },
    CONTACT: {
      TEXT_1: 'Si tiene alguna duda sobre la guía, no dude en preguntarnos',
      TEXT_2: 'en el chat (abajo, a la derecha de la página) o en nuestra',
      LINK: 'página de contacto'
    },
    EXAMPLES: {
      TITLE: "Ejemplos de ejercicios que se encuentran en la guía",
      SUBTITLE: "Estos son algunos ejemplos de los ejercicios físicos en formato 3D que encontrarás en la guía en línea.",
      EXERCICES1: [{
        img: "Exercices/exercice1.png",
        category: "Calentamiento",
        title: "EJERCICIOS DE PASES Y COORDINACIÓN",
        sub: "Ejemplo de ejercicios de calentamiento",
        text: `El jugador realiza un pase, seguido de un trabajo de coordinación de pies… 
        luego, ocupa el lugar del compañero a quien le hizo el pase… un ejercicio diferente  cada vez.`,
      },
      {
        img: "Exercices/exercice2.png",
        category: "Velocidad",
        title: "TRABAJO DE FRECUENCIA DE MOVIMIENTOS",
        sub: "Ejemplo de ejercicios de velocidad",
        text: `Trabajo de frecuencia de movimientos entre los listones (2 apoyos entre cada listón, de lado), seguido de aceleración, pase y marcha en retroceso; el jugador se da la vuelta, realiza un trabajo de frecuencia entre los listones (2 apoyos entre cada listón, de lado), seguido de aceleración, pase y marcha en retroceso.`,
      },
      {
        img: "Exercices/exercice3.png",
        category: "Resistencia",
        title: "CONSERVAR EL BALÓN – HACER PRESIÓN",
        sub: "Ejemplo de ejercicios de resistencia-potencia",
        text: `4 equipos de 3 jugadores, en 4 cuadrados definidos. En la parte superior, el entrenador anuncia un equipo que parte haciendo presión para recuperar el balón. Cada jugador del equipo anunciado se dirige a un cuadrado (un jugador por cuadrado, es decir, 1 contra 3)… Por ejemplo, 30 segundos de esfuerzo y luego un nuevo equipo va a hacer presión.`,
      },
      {
        img: "Exercices/exercice4.png",
        category: "Velocidad",
        title: "VELOCIDAD DE REACCIÓN Y DUELO",
        sub: "Ejemplo de ejercicios de velocidad",
        text: `Los jugadores (de frente o de espaldas) comienzan a la señal (anuncio de un color) y van a tocar el cono del color anunciado. Luego, regresan a tomar el balón y patear a la portería… el primero en llegar al balón patea o va a duelo.`,
      },
      ],
      EXERCICES2: [{
        category: "Calentamiento ",
        title: "EJERCICIOS DE PASES Y DE COORDINACIÓN",
        sub: "Ejemplo de ejercicios de calentamiento",
        text: `Trabajo de apoyos, de coordinación y de saltos en el calentamiento para preparar a los jugadores para la sesión y al mismo tiempo trabajar las diferentes cualidades físicas.`,
      },
      {
        category: "Velocidad",
        title: "TRABAJO DE FRECUENCIA DE MOVIMIENTOS",
        sub: "Ejemplo de ejercicios de velocidad",
        text: `Los jugadores comienzan con un trabajo de polimetría; luego, uno de los dos jugadores escoge un lado y acelera… el otro jugador debe conseguir tocarlo antes de que llegue a la línea de meta. `,
      },
      ]
    },
    OPINION_TITLE: `LO QUE PIENSAN DE LA GUÍA EN LÍNEA`,
    OPINION_MOBILE_TITLE: `Los entrenadores y jugadores confían en nosotros…`,
    END_PART: {
      TEXT: '¿ESTÁS INTERESADO EN LA GUÍA DE 800 EJERCICIOS?',
      BUTTON: 'Toda la información aquí',
    }
  },
  EXERCICES_APP: {
    FIRST: {
      TITLE: '300 ideas de ejercicios físicos',
      SUB: '¿Por qué le ofrecemos esta aplicación?',
      CONTENT_1: `El blog contiene muchos ejemplos de ejercicios físicos. <br />
      Sin embargo, están “dispersos” en todos los artículos (+ de 100 artículos).`,
      CONTENT_2: `Esta aplicación nos permite organizar mejor los ejercicios y le <br />
      permitirá a usted encontrar más rápidamente los ejercicios que busca. `,
      CONTENT_3: {
        TEXT: `Hemos organizado los ejercicios en 6 temas principales:`,
        LIST: [
          `Calentamiento`,
          `Velocidad`,
          `Resistencia`,
          `Coordinación`,
          `Fuerza`,
          `Jóvenes`,
        ],
      },
      END: `Esta aplicación está dirigida a los entrenadores de fútbol amateur que buscan ideas de ejercicios para sus sesiones de entrenamiento físico.`,
    },
    CATEGORIES: [
      'Calentamiento ',
      'Resistencia',
      'Velocidad',
      'Fuerza',
      'Coordinación',
      'Jóvenes',
    ],
    LAST: {
      TITLE: `Cuáles son las características de esta aplicación móvil:`,
      ITEMS: [
        `Totalmente gratis`,
        `Fácil de usar`,
        `Nuevos ejercicios añadidos regularmente`,
        `Sección de Contacto para cualquier pregunta o sugerencia`,
        `Añade sus ejercicios favoritos`,
        `Comparte la aplicación con sus amigos u otros entrenadores`,
        `Función de búsqueda para encontrar rápidamente los ejercicios que le interesan `,
      ]
    },
    TEXT_MOBILE: `Pour visualiser cette page, veuillez accéder depuis votre ordinateur`,
    BACK_HOME: `Back to home`,
  },
  IDEES: {
    TOP: {
      TITLE: '200 ideas de juegos reducidos para trabajar lo físico',
      SUBTITLE: 'Mientras se trabaja la técnica y la táctica',
    },
    QUESTIONS: {
      TITLE: '200 IDEAS DE JUEGOS REDUCIDOS',
      SUBTITLE: 'Adaptados a los jóvenes y los seniors',
      ITEMS: [
        "¿A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?",
        "¿Le gustaría conocer más ejercicios para trabajar la resistencia mientras trabaja la técnica y la táctica?",
        "¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?",
        "¿Le gustaría ofrecer ejercicios físicos con balón más divertidos y que les gusten más a sus jugadores?",
        "¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?",
      ],
    },
    GUIDE: {
      TITLE: `Proponemos una “guia” en línea con
      <span>200 ideas de juegos reducidos en forma de esquemas 3D</span>
      para la parte física mientras se trabaja la técnica y la táctica.`,
      CONTENT: {
        TEXT_1: `Esta guía está dirigida a entrenadores de equipos juveniles y
        senior que buscan ideas de ejercicios para trabajar el físico de
        forma integrada.`,
        TEXT_2: `Los juegos reducidos son oposiciones con balón y permiten
        trabajar los aspectos técnicos y tácticos.`,
        TEXT_3: `¡También te permitirían trabajar en ciertos aspectos del trabajo
        físico (tendrás más explicaciones en la guía sobre los efectos
        de los juegos reducidos sobre las cualidades físicas)!`,
        TEXT_4: `Esto es particularmente interesante en el fútbol amateur donde
        hay que trabajar en lo físico pero también en la táctica, la
        técnica o lo mental con un tiempo de entrenamiento muy
        limitado.`,
        CHECKS: [
          `Tienes un apartado de “explicación” sobre los juegos
          reducidos (ventajas, cómo diseñarlos por ti mismo, ...)`,
          `Y explicaciones sobre el trabajo de resistencia`,
          `Estos ejercicios son sin material o con material pequeño (que
            pueden tener todos los clubes, y todos con balón)`,
        ],
      },
    },
    COMMANDE: {
      TITLE: 'Después de realizar la compra, recibirás directamente un correo electrónico con el enlace a la guía en línea.',
      CONTENT: {
        TEXT_1: `Encontrará una "biblioteca" o un "directorio" de ejercicios para
        variar su entrenamiento. Para progresar, a veces hay que (ver
        a menudo) aportar variedad, novedad, para sorprender a los
        cuerpos (fuente de progreso) y evitar la monotonía (pérdida de
        motivación, sobreentrenamiento ...)`,
        TEXT_2: `Para ello, puedes variar las intensidades, los volúmenes, ...
        ¡pero también los ejercicios!`,
        TEXT_3: `Puede sugerir (o hacer) los mismos ejercicios, pero puedes
        sobretodo inspirarte en ellos y crear tus propios ejercicios.`,
        TEXT_4: `¡El objetivo está sobre todo ahí, darte ideas, ejemplos para que
        tengas una base en la que puedas apoyarte para diseñar tus
        propios ejercicios!`,
      }
    },
    INFOSHOP: {
      title: 'Le ofrecemos esta nueva "GUÍA 200 IDEAS DE JUEGOS REDUCIDOS" en línea',
      img: "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/2_6465d56d-6754-449c-8165-67a93bf61d96_900x.png?v=1621233892",
      link: "https://preparacionfisicafutbol-tienda.com/collections/ideas-de-ejercicio/products/200-ideas-de-juego-con-descuento",
    },
    GARANTIE: {
      TITLE: 'Garantía de satisfacción o le devolvemos su dinero',
      SUBTITLE: 'Si usted no está completamente satisfecho, ¡envíe un simple correo electrónico pidiendo la devolución de su dinero!',
    },
    PAIEMENT: {
      TITLE: 'Pago 100% seguro',
      SUBTITLE: 'El pago es totalmente seguro por las plataformas reconocidas como PayPal.',
    },
    CONTACT: {
      TEXT_1: 'Si tiene alguna duda sobre la guía, no dude en preguntarnos',
      TEXT_2: 'en el chat (abajo, a la derecha de la página) o en nuestra',
      LINK: 'página de contacto'
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      E_LEFT: [{
          img: "Idees/Left1.png",
          title: "JUEGOS REDUCIDOS 1 CONTRA 1",
          text: `
        <p><i class="fas fa-check"></i> En forma de mini-torneo (ascenso-descenso) con evolución de los juegos. </p>
        <p><i class="fas fa-check"></i> Los ganadores suben para terminar en el campo 1. Los perdedores bajan, siendo el último campo el 4 (o el 5 o el 6 según el número de jugadores presentes).</p>
        <p><i class="fas fa-check"></i> Campo 4: Retención del balón (gana el jugador que conserve el balón por más tiempo).</p>
        <p><i class="fas fa-check"></i> Campo 3: Balón detenido en la zona roja.</p>
        <p><i class="fas fa-check"></i> Campo 2: El jugador pasa entre los conos del contrario (rojos o azules).</p>
        <p><i class="fas fa-check"></i> Campo 1: Anotar en la pequeña portería contraria. La intensidad debe ser alta.</p>
        `,
        },
        {
          img: "Idees/Left2.png",
          title: "JUEGOS REDUCIDOS 4 CONTRA 1",
          text: `
        <p><i class="fas fa-check"></i> 5 cuadrados.</p>
        <p><i class="fas fa-check"></i> 4 contra 1 en cada cuadrado.</p>
        <p><i class="fas fa-check"></i> Entre los 12 jugadores amarillos que deben conservar el balón, 4 jugadores (flechas blancas) juegan en 2 cuadrados al mismo tiempo.</p>
        <p><i class="fas fa-check"></i> Estos 4 jugadores nunca deben recibir 2 balones al mismo tiempo.</p>
        <p><i class="fas fa-check"></i> Usted puede variar el tamaño de los cuadrados, dar instrucciones especiales, proponer otros obstáculos,…</p>
        `,
        },
      ],
      E_RIGHT: [{
          img: "Idees/Right1.png",
          title: "JUEGOS REDUCIDOS 5 CONTRA 2",
          text: `
        <p><i class="fas fa-check"></i> 3 zonas.</p>
        <p><i class="fas fa-check"></i> El equipo con mayor número de jugadores debe superar las 3 “etapas”  para anotar un punto. </p>
        <p><i class="fas fa-check"></i> El equipo con mayor número de jugadores debe realizar 8 pases antes de poder continuar a la zona siguiente.</p>
        <p><i class="fas fa-check"></i> En cada zona, 2 nuevos jugadores haciendo presión.</p>
        <p><i class="fas fa-check"></i> El equipo con mayor número de jugadores tiene, por ejemplo, 2 minutos para superar las 3 etapas y anotar un punto. </p>
        <p><i class="fas fa-check"></i> Cada vez que se pierde el balón, el equipo comienza desde el principio.</p>
        `,
        },
        {
          img: "Idees/Right2.png",
          title: "JUEGOS REDUCIDOS 4 CONTRA 4",
          text: `
        <p><i class="fas fa-check"></i>  3 equipos.</p>
        <p><i class="fas fa-check"></i> Un equipo que ataca (en rojo) que escoge al equipo contra el que va a atacar (contra los blancos o los amarillos).</p>
        <p><i class="fas fa-check"></i> Si anota, ataca al siguiente equipo. Si pierde el balón, pasa a defender y el otro equipo pasa a atacar.</p>
        `,
        },
      ],
    }
  },
  TREVE_ESTIVALE: {
    TOP: {
      TITLE: 'Para ayudarle en sus entrenamientos',
      SUBTITLE: `¡Le ofrecemos este programa para darle un marco, ideas y ejemplos en los que pueda basar su programación!`,
      BUTTON: 'Recibir el programa por correo electrónico de forma gratuita',
    },
    QUESTIONS: {
      TITLE: 'PROGRAMA DE VACACIONES DE VERANO EN EL FÚTBOL',
      SUBTITLE: 'EJERCICIOS PRESENTES EN FORMATO DE DIAGRAMAS 3D',
      ITEMS: [
        "¿Le gustaría tener un ejemplo, un modelo en el que basar su preparación de pretemporada?",
        "¿Le gustaría ahorrar tiempo en la creación de sus sesiones y programas de pretemporada (y no pasar horas buscando nuevos ejercicios en internet)?",
        "¿Le gustaría proponer calentamientos y ejercicios físicos más divertidos que sus jugadores disfruten?",
        "¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?",
      ],
    },
    PROGRAMMES: {
      TITLE: 'Los programas en línea están disponibles para los teléfonos celulares, tabletas y computadoras.',
      CONTENT: {
        TEXT_1: `Le ofrecemos un nuevo “programa de vacaciones de verano” en línea con numerosos ejercicios con balón presentados en formato 3D.`,
        TEXT_2: `Comprende una parte de “explicaciones” para la planificación y para cada cualidad física y una parte de “sesiones y ejercicios”.`,
        TEXT_3: `Estos ejercicios se realizan con equipo o poco equipo (que todos los clubes amateurs pueden tener).`,
        TEXT_4: `Está dirigido principalmente a entrenadores que buscan ejemplos de programas para las vacaciones de invierno en el fútbol.
        <br />¡<span>Más de 2000</span> entrenadores siguen ya este programa!`,
        TEXT_5: `<span>Le ofrecemos este nuevo programa.</span><br />
        Haga clic en el botón siguiente para recibirlo directamente en su buzón de correo electrónico.`,
      },
      BUTTON: 'Recibir el programa por correo electrónico de forma gratuita',
      EMAIL: `Después de registrarse, recibirá directamente un correo electrónico con el enlace de acceso al programa en línea (tenga en cuenta que el correo electrónico a veces llega en el spam).`
    },
    VOTRE: {
      TITLE: "Su programa de pretemporada",
      CONTENT: {
        TEXT_1: `Encontrará un “programa” de 6 semanas con 2, 3 o 4 entrenamientos por semana.`,
        TEXT_2: `Usted puede proponer (o hacer) el mismo programa y los mismos ejercicios, pero también puede inspirarse en ellos y crear su programa.`,
        TEXT_3: `El objetivo principal es darle ideas y ejemplos para que tenga una base sobre la cual ¡diseñar su propio programa!`,
      }
    },
    CONTACT: {
      TEXT_1: 'Si tiene alguna duda sobre la guía, no dude en preguntarnos',
      TEXT_2: 'en el chat (abajo, a la derecha de la página) o en nuestra',
      LINK: 'página de contacto'
    },
    EXERCICES: {
      TITLE: "Ejemplos de los ejercicios que encontrará en los programas",
      SUBTITLE: `Estos son algunos de los ejemplos de ejercicios físicos en formato 3D que encontrará en la guía en línea.`,
      E_LEFT: [{
          img: "Training/ELeft1.png",
          title: "EJERCICIO DE FUERZA ESPECÍFICA Y DE POTENCIA AERÓBICA",
          sub: "Ejemplo de ejercicio que mezcla fuerza y resistencia",
          text: `
          <p>Ejercicio de patadas: El jugador se dirige a un objetivo (aquí, azul y rojo) y luego vuelve a colocarse en posición para comenzar de nuevo. Lo más rápido posible.</p>
          <p>En 10-20, por ejemplo <br /> Seguido de: Juegos reducidos <br /> 2 contra 2 <br /> 1 minuto 30 de juego, 45 segundos de recuperación <br /> 3 secuencias.</p>
          `,
        },
        {
          img: "Training/ELeft2.png",
          title: "CONSERVACIÓN DEL BALÓN – HACER PRESIÓN",
          sub: "Ejemplo de ejercicio de resistencia-potencia",
          text: `
          <p>4 equipos de 3 jugadores en 4 cuadrados definidos.</p>
          <p>Al comienzo, el entrenador anuncia un equipo que parte haciendo presión para recuperar el balón. Cada jugador del equipo anunciado se dirige hacia un cuadrado (un jugador por cuadrado, es decir, 1 contra 3).</p>
          <p>30 segundos de ejercicio, por ejemplo, y un nuevo equipo pasa a hacer presión.</p>
          `,
        },
      ],
      E_RIGHT: [{
          img: "Training/ERight1.png",
          title: "TRABAJO DE RESISTENCIA, CAPACIDAD Y DESTREZA",
          sub: "Ejemplo de ejercicio que mezcla resistencia, capacidad y destreza",
          text: `
          <h5>Recorrido de “destreza”</h5>
          <p><i class="fas fa-check"></i> Ejercicio 1: derribar el cono</p>
          <p><i class="fas fa-check"></i> Ejercicio 2: acercarse lo más posible al cono (como “apuntar” en los bolos)</p>
          <p><i class="fas fa-check"></i> Ejercicio 3: tocar el larguero  </p>
          <p><i class="fas fa-check"></i> Ejercicio 4: coger el balón y meterlo dentro de un neumático grande (puede ser un aro o cualquier otra cosa)</p>
          <p><i class="fas fa-check"></i> Ejercicio 5: derribar el cono</p>
          <p><i class="fas fa-check"></i> Ejercicio 6: acercarse lo más posible al cono (como “apuntar” en los bolos)</p>
          <p><i class="fas fa-check"></i> Ejercicio 7: pasar el balón bajo la valla que se encuentra en el centro de la portería</p>
          <p class="last">Sin interrupción. Ritmo medio. <br /> Posibles instrucciones: trabajar el pie débil. <br /> Por ejemplo: 15 minutos</p>
          `,
        },
        {
          img: "Training/ERight2.png",
          title: "VELOCIDAD DE REACCIÓN Y DUELO",
          sub: "Ejemplo de ejercicio de resistencia-potencia",
          text: `
          <p>Los jugadores (de frente o de espaldas) comienzan a la señal (anuncio de un color) y van a tocar el cono del color anunciado. Luego, regresan para tomar el balón y patear a la portería.</p>
          <p>El primero en llegar al balón patea o va a duelo.</p>
          `,
        },
      ],
    }
  },
  TREVE_HIVERNALE: {
    TOP: {
      TITLE: 'Afin de vous aider dans vos entraînements',
      SUBTITLE: `Nous vous offrons ce programme pour vous donner une trame, des idées
      ou encore des exemples sur lesquels vous pourrez vous appuyer pour
      concevoir votre programmation!`,
      BUTTON: 'Recevoir le progamme par email gratuitement',
    },
    QUESTIONS: {
      TITLE: 'PROGRAMME TRÊVE HIVERNALE EN FOOTBALL',
      SUBTITLE: 'EXERCICES PRÉSENTÉS SOUS FORME DE SCHÉMAS 3D',
      ITEMS: [
        "Vous aimeriez avoir un exemple, un modèle sur lequel vous appuyer pour réaliser votre préparation pendant la trêve hivernale ?",
        "Vous aimeriez gagner du temps dans la création de vos séances et de votre programme pendant la trêve hivernale (et ne pas chercher des heures de nouveaux exercices sur internet) ?",
        "Vous aimeriez proposer des échauffements et des exercices physiques plus ludiques et plus appréciés par vos joueurs ?",
        "¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan sus ejercicios físicos?",
      ],
    },
    PROGRAMMES: {
      TITLE: 'Les programmes en ligne sont disponibles sur mobile, tablette et ordinateur.',
      CONTENT: {
        TEXT_1: `Nous proposons un nouveau “programme trêve hivernale” en ligne
        avec de nombreux exercices avec ballon présentés sous format
        schémas 3D.`,
        TEXT_2: `Vous avez une partie “explications” pour la planification et pour
        chaque qualité physique et une partie “séances et exercices”.`,
        TEXT_3: `Ces exercices sont sans matériel ou avec petit matériel (que tous
          les clubs amateurs peuvent).`,
        TEXT_4: `Ce programme s’adresse principalement aux coachs qui cherchent des
        exemples de programmes pour la trêve hivernale en football.
        <br /><span>+ de 700</span> coachs suivent déjà ce programme!`,
        TEXT_5: `<span>Nous vous offrons ce nouveau programme.</span><br />
        Cliquez sur le bouton ci-dessous pour le recevoir directement dans
        votre boîte mail.`,
      },
      BUTTON: 'Recevoir le progamme par email gratuitement',
      EMAIL: `Après vous être inscrit, vous allez recevoir directement un email
      avec le lien d’accès au programme en ligne (attention le mail
      arrive parfois dans les spams).`
    },
    VOTRE: {
      TITLE: "Votre programme spécial trêve hivernale",
      CONTENT: {
        TEXT_1: `Vous allez retrouver une "programmation" sur 6 semaines avec 2
        entrainements par semaine.`,
        TEXT_2: `Vous pouvez proposer (ou faire) la même programmation et les
        mêmes exercices mais vous pouvez surtout vous en inspirer et
        créer votre programmation.`,
        TEXT_3: `L'objectif est surtout là, vous donner des idées, des exemples
        afin que vous ayez une base sur laquelle vous appuyer pour
        concevoir votre programmation!`,
      }
    },
    CONTACT: {
      TEXT_1: 'Si tiene alguna duda sobre la guía, no dude en preguntarnos',
      TEXT_2: 'en el chat (abajo, a la derecha de la página) o en nuestra',
      LINK: 'página de contacto'
    },
    EXERCICES: {
      TITLE: "Exemples d'exercices retrouvés dans les programmes",
      SUBTITLE: `Voici des exemples d'exercices physiques sous format 3D que vous
      retrouverez dans le guide en ligne.`,
      E_LEFT: [{
          img: "Training/HLeft1.png",
          title: "EXERCICE DE FORCE SPÉCIFIQUE ET DE PUISSANCE AÉROBIE",
          sub: "Exemple d'exercice mixant force et endurance",
          text: `
          <p>Circuit training pendant 3 minutes puis travail de course pendant 7 minutes (par exemple)</p>
          <p>Sous forme 15-15 (ou 10-20..)</p>
          `,
        },
        {
          img: "Training/left2.png",
          title: "VITESSE DE RÉACTION",
          sub: "Exemple d'exercice de vitesse de réaction",
          text: `
          <p>Le joueur doit aller toucher avec le pied le cône de couleur que lui annonce son partenaire.</p>
          <p>"vert" et le joueur va vers le cône vert puis se replace rapidement au centre. Dès que le joueur est replacé au centre le partenaire lui annonce la nouvelle couleur... ne pas lui laisser de temps de repos.</p>
          <p>Le joueur doit prendre les informations (les couleurs) avant de commencer pour pouvoir enchaîner très vite.</p>
          <p>Travail par deux (un qui fait l'exercice, l'autre qui annonce les couleurs).</p>
          <p>Effort court (5-6 secondes) et récupération longue ( 1 minute par exemple).</p>
          `,
        },
      ],
      E_RIGHT: [{
          img: "Training/HRight1.png",
          title: "TRAVAIL DE FORCE ET D'ENDURANCE",
          sub: "Exemple d'exercice mixant endurance et force",
          text: `
          <p><i class="fas fa-check"></i> Jeux réduits 1 vs 1 - 5 matchs</p>
          <p><i class="fas fa-check"></i> Ici, les joueurs vont faire des duels (1mn de jeu, 30 secondes de repos).</p>
          <p><i class="fas fa-check"></i> Objectif, aller déposer le ballon dans le camp de son adversaire (zone rouge).</p>
          <p><i class="fas fa-check"></i> L'intensité doit être grande.</p>
          <p><i class="fas fa-check"></i> Mettez des ballons autour des petits terrains.</p>
          <p><i class="fas fa-check"></i> Dimension des terrains : 5m x 5m environ.</p>
          <p><i class="fas fa-check"></i> Possibilité de faire sous forme de mini-tournoi (montée-descente).</p>
          `,
        },
        {
          img: "Training/right2.png",
          title: "VITESSE DE RÉACTION ET DUEL",
          sub: "Exemple d'exercice de vitesse",
          text: `
          <p>Les joueurs (de face ou de dos) démarrent au signal (annonce d'une couleur) et vont toucher les cônes des couleurs annoncées.</p>
          <p>L'entraîneur annonce 2 ou 3 couleurs.</p>
          <p>Les joueurs doivent toucher les cônes le plus vite possible puis aller au duel et frapper.</p>
          `,
        },
      ],
    }
  },
  GUIDE_JEUNES: {
    TOP: {
      TITLE: 'Para ayudarle en sus entrenamientos',
      SUBTITLE: `Le ofrecemos esta guía que esperamos le proporcione conocimientos, ideas y ejemplos en los cuales puede basarse para ¡crear su propia programación! `,
      BUTTON: 'Recibir el programa por correo electrónico de forma gratuita',
    },
    CHECKS: {
      TITLE: 'GUÍA “PREPARACIÓN FÍSICA EN LOS JÓVENES FUTBOLISTAS”',
      ITEMS: [
        `No siempre es fácil encontrar información sobre los jóvenes deportistas y sobre el conocimiento del funcionamiento del niño para hacer ejercicio. `,
        `Los estudios e investigaciones son mucho menos numerosos que para los adultos. `,
        `Para ayudarle, hemos reunido la información que consideramos esencial conocer para ofrecer a sus jóvenes contenidos que les permitan progresar y desarrollarse armoniosamente. `,
      ],
    },
    LE_GUIDE: {
      TITLE: 'La guía en línea está disponible para teléfonos celulares, tabletas y computadoras. ',
      SUBTITLE: `Muchos entrenadores nos han pedido que les ayudemos en la preparación física de los jóvenes futbolistas. <br />
      Hemos intentado responder a esta necesidad creando para usted esta pequeña guía. `,
      CONTENT: {
        TEXT_1: `Le ofrecemos una “guía” en línea sobre la preparación física de los jóvenes futbolistas. <br />
        Tendrá acceso a un módulo de información general sobre los jóvenes y módulos de información por categorías con ejemplos de ejercicios a proponer. `,
        TEXT_2: `Esta guía está dirigida principalmente a los entrenadores que buscan información sobre `,
        TEXT_3: `los jóvenes, así como ideas de ejercicios para sus sesiones de entrenamiento físico.`,
        TEXT_4_1: `¡Más de 3.000`,
        TEXT_4_2: ` entrenadores ya siguen esta guía!`,
        TEXT_5: `También descubrirá los diferentes métodos (y ejercicios) de entrenamiento posibles de: `,
        TEXT_6_1: "Le ofrecemos este nuevo programa.",
        TEXT_6_2: `Haga clic en el siguiente botón para recibirlo directamente en su buzón de correo electrónico.`,
        TEXT_7: `Después de registrarse, recibirá directamente un correo electrónico con el enlace de acceso al programa en línea (tenga en cuenta que el correo electrónico a veces llega como spam).`,
        CHECKS: [
          'Velocidad',
          'Fuerza',
          'Resistencia',
          'Coordinación',
          'Flexibilidad',
          'Calentamiento',
          'Prevención de lesiones',
        ],
        BUTTON: 'Recibir el programa por correo electrónico de forma gratuita',
      }
    },
    VOTRE: {
      TITLE: "SU GUÍA",
      CONTENT: {
        TEXT_1: ` Asimismo, encontrará una pequeña “biblioteca” o “repertorio” de ejercicios para variar sus entrenamientos. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad con el fin de sorprender al cuerpo (fuente de energía) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento...)`,
        TEXT_2: `Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios!`,
        TEXT_3: `Usted puede proponer (o realizar) los mismos ejercicios, pero puede inspirarse en ellos y crear los suyos propios. `,
        TEXT_4: `El objetivo principal es darle ideas y ejemplos para que tenga una base sobre la cual ¡diseñar sus propios ejercicios! `
      }
    },
    CONTACT: {
      TEXT_1: 'Si tiene alguna duda sobre la guía, no dude en preguntarnos',
      TEXT_2: 'en el chat (abajo, a la derecha de la página) o en nuestra',
      LINK: 'página de contacto'
    },
    EXERCICES: {
      TITLE: "Ejemplos de ejercicios que encontrará en la guía",
      SUBTITLE: `A continuación le presentamos algunos ejemplos de los ejercicios físicos en formato 3D que encontrará en la guía en línea para darle algunas ideas.`,
      E_LEFT: [{
          img: "Guide/GuideLeft1.png",
          title: "EJERCICIOS DE PASES Y COORDINACIÓN",
          sub: "Ejemplo de un ejercicio de calentamiento",
          text: `
          <p>El jugador realiza un pase, seguido de un trabajo de apoyos de pies y coordinación. Luego, toma el lugar del compañero a quien le hizo el pase. Un ejercicio diferente cada vez. </p>
          `,
        },
        {
          img: "Guide/GuideLeft2.png",
          title: "FRECUENCIA + REACCIÓN",
          sub: "Ejemplo de ejercicio de velocidad ",
          text: `
          <p>Los jugadores realizan un trabajo de frecuencia en la escalera rítmica (2 apoyos de pies por cuadrado de la escalera, muy rápidos), al llegar a los conos amarillos, uno de los jugadores (el jugador azul en la imagen) elige la dirección de la carrera rápida (a derecha o a izquierda). El otro jugador debe tocarlo antes de que cruce la línea de meta (los 2 conos naranjas).</p>
          `,
        },
      ],
      E_RIGHT: [{
          img: "Guide/GuideRight1.png",
          title: "TRABAJO DE FRECUENCIA DE MOVIMIENTO",
          sub: "Ejemplo de ejercicio de velocidad",
          text: `
          <p>Trabajo de frecuencia de movimiento entre los listones (2 apoyos de pies entre cada listón, de lado), seguido de aceleración y pase, marcha en retroceso, el jugador se da la vuelta, realiza un trabajo de frecuencia entre los listones (2 apoyos de pies entre cada listón, de lado); luego, aceleración, pase y marcha en retroceso.</p>
          `,
        },
        {
          img: "Guide/GuideRight2.png",
          title: "VELOCIDAD DE REACCIÓN Y DUELO ",
          sub: "Ejemplo de ejercicio de velocidad",
          text: `
          <p>Los jugadores (de frente o de espaldas) comienzan a la señal (anuncio de un color) y se dirigen a tocar el cono del color anunciado. Luego, regresan a tomar el balón y patear a la portería. El primero en llegar al balón patea o se enfrentan en duelo. </p>
          `,
        },
      ],
    }
  },
  GUIDE_M: {
    TOP: {
      TITLE: 'PARA AYUDARLE EN SU PRÁCTICA DE COACHING',
      SUBTITLE: `Le proponemos esta GUÍA de “GERENCIA” <br />
      que incluye 5 módulos (y un extra).`,
    },
    LINK_VIDEO: 'https://www.youtube.com/embed/7GtJ1-jazcE',
    CHECKS: {
      TITLE: 'GUIA DE GERENCIA',
      ITEMS: [
        `¿Usted es entrenador y le gustaría tener soluciones para resolver conflictos (entre los jugadores, con sus jugadores, con los padres…)?`,
        `¿Le gustaría saber cómo manejar a los jugadores “difíciles”, los de carácter fuerte?`,
        `¿Le gustaría desarrollar su liderazgo o trabajar en su desarrollo personal?`,
        `¿Le gustaría mejorar sus habilidades de organización o de animación de grupos?`,
      ],
    },
    LA_GUIDE: {
      TITLE: 'LA GUÍA EN LÍNEA ESTÁ DISPONIBLE PARA TELÉFONOS CELULARES, TABLETAS Y COMPUTADORAS.',
      CONTENT: {
        COL_1_1: {
          TITLE: 'Para ayudarle a progresar',
          TEXT: `La idea de esta guía es ayudarle a progresar como entrenador en la gestión de grupos, las relaciones con los jugadores, la organización o la animación de grupos. `
        },
        COL_1_2: {
          TITLE: 'Para darle algunas claves',
          TEXT: `Hemos tratado de darle algunas claves de la gestión de su grupo, de darle “perlas” para crear su propio método. `,
        },
        TEXT_1: `Los módulos GERENCIA, PREPARACIÓN MENTAL, Y GESTIÓN DE SESIONES DE ENTRENAMIENTO están diseñados para cumplir estos objetivos: `,
        COL_2_1: {
          TITLE: 'Desarrollar sus habilidades',
          TEXT: `Esta guía está diseñada para ayudarle a desarrollar sus habilidades de manejar, potenciar y organizar sus grupos. Para ello, creemos que es esencial “mejorarse” a sí mismos.`
        },
        COL_2_2: {
          TITLE: 'Asesorarle ',
          TEXT: `Hemos tratado de darle consejos y formas para que pueda mejorar personalmente.`,
        },
        TEXT_2: `Los módulos LIDERAZGO y DESARROLLO PERSONAL-PLENA CONSCIENCIA están diseñados para cumplir estos objetivos.`,
      }
    },
    INFOSHOP: {
      title: 'Le ofrecemos esta nueva “GUÍA de GERENCIA” en línea',
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/83_3_900x.png?v=1621232613",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/formacion/products/guia-de-gestion",
    },
    MODULES: {
      TITLE: 'CONTENIDO DE SU GUÍA EN LÍNEA',
      SUBTITLE: "Encontrará 5 módulos con más de ¡100 videos de consejos de expertos en gestión y coaching! ",
      INITIAL: `<h4>MÓDULO 1 GERENCIA</h4>
      <p class="gray">Descubrirá: </p>
      <p>
        <i class="fas fa-check-circle"></i> ¿Cómo afrontar las críticas?
      </p>
      <p>
        <i class="fas fa-check-circle"></i> ¿Cómo manejar los conflictos con los jugadores, entre los jugadores, con los padres?
      </p>
      <p>
        <i class="fas fa-check-circle"></i> ¿Cómo manejar los egos y los “caracteres fuertes”? ¿Cómo involucrar a los jugadores en los entrenamientos?
      <p>
        <i class="fas fa-check-circle"></i> ¿Cómo afrontar las derrotas, cómo manejar a los suplentes, a los líderes...?
      </p>
      <p class="gray">
        ¡También descubrirá un método de comunicación que permite resolver muchos problemas!
      </p>`,
      ITEMS: [{
          id: 1,
          content: `<h4>MÓDULO 1 GERENCIA</h4>
          <p class="gray">Descubrirá: </p>
          <p>
            <i class="fas fa-check-circle"></i> ¿Cómo afrontar las críticas?
          </p>
          <p>
            <i class="fas fa-check-circle"></i> ¿Cómo manejar los conflictos con los jugadores, entre los jugadores, con los padres?
          </p>
          <p>
            <i class="fas fa-check-circle"></i> ¿Cómo manejar los egos y los “caracteres fuertes”? ¿Cómo involucrar a los jugadores en los entrenamientos?
          <p>
            <i class="fas fa-check-circle"></i> ¿Cómo afrontar las derrotas, cómo manejar a los suplentes, a los líderes...?
          </p>
          <p class="gray">
            ¡También descubrirá un método de comunicación que permite resolver muchos problemas!
          </p>`,
        },
        {
          id: 2,
          content: `<h4>MODULE 2 LEADERSHIP</h4>
                  <p class="gray">Vous découvrirez par exemple:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment devenir un leader?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment s'exprimer devant un groupe?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment faire son pitsh?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment développer son charisme? ou encore comment se faire respecter?
                  </p>`,
        },
        {
          id: 3,
          content: `<h4>MODULE 3 PRÉPARATION MENTALE</h4>
                  <p class="gray">Vous découvrirez par exemple:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment gérer les
                    critiques?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Des méthodes sur la gestion des émotions
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> La gestion du stress
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> La confiance en soi
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> La cohésion de groupe
                  </p><p>
                    <i class="fas fa-check-circle"></i> La motivation ou encore la concentration
                  </p>`,
        },
        {
          id: 4,
          content: `<h4>MODULE 4 DÉVELOPPEMENT PERSONNEL - MINDSET</h4>
                  <p class="gray">Vous découvrirez par exemple:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment construire 
                    son succès?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment développer 
                    son auto-discipline?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment agrandir 
                    sa zone de confort?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment devenir acteur de sa vie, 
                    comment se fixer des objectifs ou encore comment devenir plus heureux?
                  </p>`,
        },
        {
          id: 5,
          content: `<h4>MODULE 5 GESTION DES SÉANCES D'ENTRAÎNEMENTS</h4>
                  <p class="gray">Vous découvrirez par exemple:</p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment présenter 
                    les séances d'entraînements aux joueurs?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment calibrer 
                    les séances d'entraînements?
                  </p>
                  <p>
                    <i class="fas fa-check-circle"></i> Comment animer 
                    les entraînements?
                  </p>`,
        },
      ],
    },
    GARANTIE: {
      TITLE: 'Garantía de satisfacción o le devolvemos su dinero',
      SUBTITLE: 'Si usted no está completamente satisfecho, ¡envíe un simple correo electrónico pidiendo la devolución de su dinero!',
    },
    PAIEMENT: {
      TITLE: 'Pago 100% seguro',
      SUBTITLE: 'El pago es totalmente seguro por las plataformas reconocidas como PayPal.',
    },
    CONTACT: {
      TEXT_1: 'Si tiene alguna duda sobre la guía, no dude en preguntarnos',
      TEXT_2: 'en el chat (abajo, a la derecha de la página) o en nuestra',
      LINK: 'página de contacto'
    },
  },
  OPINIONS: {
    FIRST_SLIDE: [{
        text: `
        "Yo comenzaba a proponer siempre la misma cosa en mis
        entrenamientos. Deseaba probar esta temporada de entrenamiento.
        Y he descubierto nuevos ejercicios, ahora invento nuevos ejercicios en
        base a los que están. Está bien explicado y no es muy caro. Lo he
        recomendado a uno de mis amigos que es entrenador también ;)`,
        person: 'Laurent D.'
      },
      {
        text: `
        No tenía una base de ejercicios muy grande y esto me permite ahora
        crear mis propios ejercicios... Cuantos más ejercicios conoces más te
        puedes imaginar nuevos... las explicaciones sobre las cualidades físicas
        también me ayudaron a entender y esto mejora la calidad de mis
        sesiones. Estoy satisfecho con la guía ;)
        Gracias a la preparación física del fútbol.`,
        person: 'Chris T.'
      },
      {
        text: `
        Hace una semana que estoy inscrito a la temporada de entrenamiento
        físico y no he leído ni probado todo todavía, entonces es difícil juzgar
        pero las sesiones físicas están bien presentadas, los ejercicios en
        diagramas son bonitos y fáciles de comprender.
        Yo pienso que va a ser muy interesante. En todo caso no es caro y
        pienso que mis sesiones de entrenamiento serán de mejor calidad.
        Gracias Preparación Física Fútbol!`,
        person: 'Rabah'
      }
    ],
    SECOND_SLIDE: [{
        text: `
        Hace poco he comenzado las sesiones de entrenamiento y a los
        jugadores les ha gustado. Es mucho con balón y lúdico. Los ejercicios
        son simples a comprender. No sabía cómo planificar el trabajo físico,
        ahora solo sigo lo que propones. Además, de aprender al mismo tiempo.
        Este precio esta muy bueno. Gracias.`,
        person: 'Anne'
      },
      {
        text: `
        Tengo U9s este año y no tenía demasiadas ideas sobre ejercicios de
        coordinación para proponer. Me han recomendado esta guía y estoy
        muy satisfecho. Los ejercicios de velocidad también son buenos
        (reacción con conos de colores). Los pequeños son felices, eso es lo
        más importante.`,
        person: 'Yohan J.'
      },
      {
        text: `
        Utilizo esta guía para crear mis sesiones y es muy práctica. Tengo una
        hora de viaje en tren todos los días, así que puedo consultar la guía en
        línea y hacer mis sesiones. Es un gran ahorro de tiempo. ¡Hay muchos
        ejercicios de pelota (que es lo que estoy buscando)! Recomendaré esta
        guía a mis colegas del club.
        Gracias`,
        person: 'Amine B.'
      }
    ],
    POSITION: 'Entrenador',
  },
  SUBMENU_JEUNES: {
    TITLE: 'Programas para entrenadores de jóvenes',
    TITLE_MOBILE: 'Entrenadores de jóvenes',
    ITEMS_LEFT: [{
        text: "Programa de temporada completa (sub 8 a sub 17)",
        next: "SaisonJeunes",
      },
      {
        text: "Programa personalizado",
        next: "",
      },
      {
        text: "Los paquetes",
        next: "Packs",
        param: "jovenes",
      },
    ],
    ITEMS_RIGHT: [{
        text: `2 ciclos de formación física especial "coordinación" Sub 8 – Sub 12`,
        next: "Coordination",
      },
      {
        text: `2 ciclos de entrenamiento físico especial "velocidad" Sub 8 – Sub 12`,
        next: "VitesseJeunesU8",
      },
      {
        text: `2 ciclos de entrenamiento físico especial "velocidad" Sub 12 – Sub 14`,
        next: "VitesseJeunesU12",
      },
      {
        text: `2 ciclos de entrenamiento físico especial "velocidad" Sub 14 – Sub 18`,
        next: "VitesseJeunesU14",
      },
      {
        text: `2 ciclos de entrenamiento físico especial "Resistencia de potencia" Sub 15 – Sub 18`,
        next: "PuissanceJeunes",
      },
      {
        text: `Un ciclo especial de 4 semanas "periodización táctica" Sub15 - Sub18`,
        next: "Periodisation",
      },
      {
        text: `2 ciclos de entrenamiento físico para porteros especial "velocidad" Sub15 - Sub18`,
        next: "GardienVitesseU15",
      },
      {
        text: `2 ciclos de entrenamiento físico en fútbol sala especial "velocidad" Sub15 - Sub18`,
        next: "FutsalVitesseU15",
      },
      {
        text: `2 ciclos de entrenamiento físico en fútbol sala especial "Resistencia de potencia" Sub15 - Sub18`,
        next: "FutsalPuissanceU15",
      },
    ],
  },
  SUBMENU_SENIOR: {
    TITLE: 'Programas para entrenadores de seniors',
    TITLE_MOBILE: 'Entrenadores de Seniors',
    ITEMS_LEFT: [{
        text: "Programa de temporada completa Seniors",
        next: "SaisonSenior",
      },
      {
        text: "Vacaciones de verano",
        next: "TreveEstivale",
      },
      // {
      //   text: "Vacaciones de invierno",
      //   next: "TreveHivernale",
      // },
      // {
      //   text: "Pretemporada de Porteros y fútbol sala",
      //   next: "Programmes",
      // },
      {
        text: "Programa personalizado",
        next: "",
      },
      {
        text: "Los paquetes",
        next: "Packs",
        param: "senior",
      }
    ],
    ITEMS_RIGHT: [{
        text: `2 ciclos de entrenamiento físico especial "velocidad"`,
        next: "SpecialVitesse",
      },
      {
        text: `2 ciclos de entrenamiento físico especial "Resistencia de potencia"`,
        next: "Puissance",
      },
      {
        text: `2 ciclos de entrenamiento físico "100% con balón"`,
        next: "Avec",
      },
      {
        text: `Un ciclo especial de 4 semanas "periodización táctica"`,
        next: "Periodisation",
      },
      {
        text: `2 ciclos de entrenamiento físico para porteros especial "velocidad"`,
        next: "GardienVitesse",
      },
      {
        text: `2 ciclos de entrenamiento físico en fútbol sala especial "velocidad"`,
        next: "FutsalVitesse",
      },
      {
        text: `2 ciclos de entrenamiento físico en fútbol sala especial "Resistencia de potencia"`,
        next: "FutsalPuissance",
      }
    ],
  },
  CONTACTO: {
    TITLE: '¿Tienes alguna pregunta?',
    SUBTITLE: "No dudes en escribirnos y dinos cómo podemos ayudarte.",
    PLACEHOLDERS: {
      NAME: "Nombre",
      EMAIL: "Email",
      MESSAGE: "Mensaje",
    },
    BUTTON: "Enviar",
    ALERT: 'Todos los campos son obligatorios'
  },
  MEMBERS: {
    INFOSEC1: {
      img: "Grupo 1012@2x.png",
      title: "¿Es usted miembro de uno de nuestros programas, guías o cursos?",
      text: `<h3>
                Para conectarse a la plataforma de “miembros especiales”, haga clic en el siguiente enlace:
              </h3>`,
      button: "Acceso a la plataforma en línea",
    },
    INFOSEC1_MOBILE: {
      img: "Grupo 1012@2x.png",
      title: "¿Es usted miembro de uno de nuestros programas, guías o cursos?",
      text: `<h3>
                Para conectarse a la plataforma de “miembros especiales”, haga clic en el siguiente enlace:
              </h3>`,
      button: "Acceso a la plataforma en línea",
    },
    INFOSEC2: {
      img: "Llave.png",
      title: "¿Perdió su contraseña?",
      text: `<h3>
              Puede reiniciarla haciendo clic en el siguiente enlace:
              </h3>`,
      button: "Recuperar la contraseña",
    },
    INFOSEC2_MOBILE: {
      img: "Llave.png",
      text: `<h3>
              ¿Perdió su contraseña? Puede reiniciarla haciendo clic en el siguiente enlace:
              </h3>`,
      button: "Recuperar la contraseña",
    },
    LINKS: [
      'https://miembros.preparacionfisicafutbol.com/formation/index/',
      'https://miembros.preparacionfisicafutbol.com/reset/',
    ],
    PROBLEME: {
      TITLE: '¿Tiene otro problema?',
      CONTENT: {
        TEXT_1: 'Escríbanos al chat o haga clic',
        TEXT_2: 'AQUÍ',
        TEXT_3: 'le responderemos lo más pronto posible.',
      },
      CONTENT_MOBILE: '¿Tiene otro problema? Escríbanos al chat o haga clic AQUÍ, le responderemos lo más pronto posible.'
    }
  },
  EMAILS: {
    TITLE: 'Los correos electrónicos privados de thibaud',
    CONTENT: `Únase a más de <strong>40000 entrenadores amateurs</strong> y
    encuentre semanalmente
    <strong>ideas de ejercicios físicos, información sobre la preparación
      física</strong>
    en el fútbol amateur y <strong>ofertas especiales</strong>
    en los programas y guías en línea.`,
    PLACEHOLDER: 'Su dirección de correo electrónico',
    BUTTON: 'Quiero recibir los correos electrónicos privados',
    LOCK: 'Los correos electrónicos son gratuitos, sus datos seguirán privados y podrá cancelar su suscripción en cualquier momento.'
  },
  SHOP: {
    TITLE: `Si desea solicitar uno de nuestros programas, guías o formación online, será redirigido a nuestra "tienda" o tienda online.`,
    SUBTITLE: '¿Por qué esta tienda online?',
    CONTENT: {
      TEXT_1: `Nuestro objetivo es ofrecerle, a través de esta plataforma, un
      <strong>fácil acceso</strong> a todos los programas en línea y proporcionarle un medio de pago simple, rápido y
      <strong>altamente seguro.</strong>`,
      TEXT_2: `Esto también nos permite ofrecerle más fácilmente 
      <strong>paquetes</strong> de 
      <strong>ofertas especiales</strong> o 
      <strong>material de preparación física.</strong>`,
      TEXT_3: `Queremos que su experiencia en nuestro sitio sea lo más agradable posible y esperamos que quede satisfecho ;)`,
      TEXT_4: `<i>Por lo tanto, cuando haga clic en "pedir" en un programa, lo llevará a la tienda en línea. Una vez en la tienda, puede realizar su pedido y luego, si desea regresar al sitio, encontrará un enlace en la página de inicio de la tienda para regresar aquí.</i>`,
    },
    BUTTON: 'IR A LA TIENDA EN LINEA',
    LINK: 'https://preparacionfisicafutbol-tienda.com',
  },
  PACKS: {
    INFOEXERCICES: {
      id: 'ejercicios',
      title: "PAQUETE DE EJERCICIO FÍSICO",
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/8_c6e8245c-c3fb-4824-b082-6f7dafaa79f6_900x.png?v=1621260751",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/ideas-de-ejercicio/products/paquete-de-ejercicio-fisico",
    },
    INFOJEUNES: {
      title: "PAQUETES DE ENTRENAMIENTO FÍSICO PARA ENTRENADORES JÓVENES",
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/8_900x.png?v=1621180794",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-de-entrenadores-para-jovenes/products/paquetes-de-entrenamiento-fisico-para-entrenadores-jovenes",
    },
    INFOSENIOR: {
      title: "PAQUETES DE ENTRENAMIENTO FÍSICO PARA ENTRENADORES SENIORS",
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/CopiadeCopiadeCopiadeCopiadeejer-1_900x.png?v=1624313948",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-senior/products/paquetes-de-entrenamiento-fisico-para-entrenadores-seniors",
    },
    INFOFORMATION: {
      id: 'formaciones',
      title: "PAQUETES ''FORMACIONES''",
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/89_2_900x.png?v=1621232826",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/formacion/products/paquetes-de-entrenamiento",
    },
  },
  BLOG: {
    RESULT: {
      TEXT_1: 'Se encontraron',
      TEXT_2: 'resultados',
    },
    BLOG_TOP: {
      TITLE: "“El verdadero poder es el conocimiento”",
      AUTHOR: 'F.Bacon',
      PLACEHOLDER: 'Buscar...'
    },
    BLOG_ICONS: {
      TITLE: 'Las categorías',
      ICONS: [{
          img: "Trazado 2375.png",
          name: "Fuerza",
          category: "Fuerza",
        },
        {
          img: "noun_speed_1109796.png",
          name: "Velocidad",
          category: "Velocidad",
        },
        {
          img: "Grupo 819.png",
          name: "Resistencia",
          category: "Resistencia",
        },
        {
          img: "noun_run_562324.png",
          name: "Coordinación, flexibilidad",
          category: "Coordinación y Flexibilidad",
        },
        {
          img: "Grupo 862.png",
          name: "Prevención de lesiones",
          category: "Prevención de Heridas",
        },
        {
          img: "Grupo 820.png",
          name: "Otros equipos",
          category: "Material y Otros",
        },
        {
          img: "Grupo 816.png",
          name: "Planificación",
          category: "Planificación",
        },
        {
          img: "Grupo 817.png",
          name: "Preparación mental",
          category: "Preparación Mental",
        },
        {
          img: "noun_Fruits_3480584.png",
          name: "Nutrición",
          category: "Nutrición",
        },
        {
          img: "Trazado 2397.png",
          name: "Gestión",
          category: "Gestión",
        },
        {
          img: "healthy-boy.png",
          name: "Jóvenes",
          category: "Jóvenes",
        },
        {
          img: "Grupo 861.png",
          name: "Fútbol sala",
          category: "Fútbol Sala",
        },
        {
          img: "noun_glove_1827610.png",
          name: "Portero",
          category: "Portero",
        },
      ],
    },
    NEWS: {
      TITLE: 'IDEAS DE EJERCICIOS',
      VIDEOS: [{
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video1.mp4?alt=media&token=8b524077-f6f9-481a-9141-f2a1df25953d",
          caption: "Velocidad de reaccion"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video2.mp4?alt=media&token=b671a94a-8fdf-4f84-a272-2fbfd0685700",
          caption: "Apoyos - vivacidad"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video3.mp4?alt=media&token=1c1cfc43-bb13-46e8-b53d-3d5095dab465",
          caption: "Apoyos - vivacidad"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video4.mp4?alt=media&token=053828b4-2563-4031-a0f0-9cd145ed182d",
          caption: "Vivacidad"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video5.mp4?alt=media&token=10731864-6e3e-48b6-bdd8-1d50480616f4",
          caption: "Calentamiento"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video6.mp4?alt=media&token=2bc8ab3f-d549-4a5f-9174-c25cd5ef8e41",
          caption: "Calentamiento"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video7.mp4?alt=media&token=6c8ea541-a081-4712-b5b0-b0bc21805e6a",
          caption: "Calentamiento"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video8.mp4?alt=media&token=a9dec042-247f-49fb-9905-9136b4da3097",
          caption: "Velocidad de reaccion"
        },
        {
          url: "https://firebasestorage.googleapis.com/v0/b/test-unit-example.appspot.com/o/Video9.mp4?alt=media&token=945b3ca7-c123-490c-858c-519e0b3c764d",
          caption: "Calentamiento"
        },
      ]
    },
    BLOG_CATE: {
      RETURN: 'Regresar al blog',
      NOT_FOUND: "No se encontraron resultados en esta categoría",
    }
  },
  ARTICLES_TITLE: 'Descubra nuestros últimos artículos…',
  ARTICLE_LINK: 'Leer más',
  APPSEC_MSG: 'Pronto disponible',
  FAQ: {
    TITLE: '¿Tiene alguna pregunta?',
    SUBTITLE: '¡Puede que tengamos la respuesta!',
    PLACEHOLDER: 'Buscar...',
    ITEMS: [{
        param: "firstQ",
        text: "Las cualidades físicas en el fútbol",
      },
      {
        param: "fifthQ",
        text: "Gestión",
      },
      {
        param: "secondQ",
        text: "La prevención de lesiones",
      },
      {
        param: "sixQ",
        text: "Otro",
      },
      {
        param: "thirdQ",
        text: "Jóvenes",
      },
      {
        param: "seventhQ",
        text: "Lo que ofrecemos para ayudarle",
      },
      {
        param: "fourthQ",
        text: "Preparación mental",
      },
      {
        param: "eighthQ",
        text: "Lo que no ofrecemos",
      },
    ]
  },
  APROPOS: {
    TITLE: 'Sobre nosotros',
    SUBTITLE: 'Para hacer esto, he implementado varios medios:',
    CONTENT_1: [
      `Hace unos años, durante entrenamientos con varios preparadores físicos y entrenadores amateurs, nos dimos cuenta de que faltaba información sobre la preparación física en el fútbol amateur.`,
      `¡Se lanzó la idea de crear un blog para compartir e intercambiar conocimientos sobre preparación física! <br />
      Rápidamente cientos de entrenadores nos hicieron parte de su situación y sus necesidades (y de si era posible ayudarlos).`,
      `Por lo tanto, mi principal objetivo se volvió satisfacer sus necesidades en la mayor medida de lo posible.`,
    ],
    CONTENT_2: [
      `Por ejemplo, dar acceso a un blog con información completamente gratuita sobre muchos temas relacionados con la preparación física en el fútbol, pero también sobre gestión, preparación mental y nutrición.`,
      `Los entrenadores tienen la opción de recibir "correos electrónicos privados" para encontrar ideas de ejercicios semanales e información sobre la forma física en el fútbol amateur.`,
      `Tienen acceso a guías y programas gratuitos sobre preparación física para jóvenes futbolistas y sobre la pretemporada.`,
      `Por último, numerosas guías, programas y cursos de formación sobre preparación física en el fútbol.`,
      `Intento entrenarme continuamente para poder cubrir todas sus necesidades. Si no logras encontrar lo que está buscando en preparación física de fútbol amateur en este sitio, puedes contactarme por correo electrónico e intentaré ayudarte ;)`,
      `Deportivamente`,
    ],
    END: 'Preparador físico',
  },
  MENTIONS: {
    LINK: {
      TEXT: 'www.preparacionfisicafutbol.com',
      LINK: 'https://www.preparacionfisicafutbol.com',
    },
    CONTENT: [
      {
        TITLE: 'AVISO LEGAL',
        TEXT: `Por favor, lea atentamente los diferentes términos de uso de este sitio antes de navegar.
        sus páginas. Al conectarse a este sitio, acepta completamente estos términos y condiciones. También,
        de acuerdo con el artículo n ° 6 de la Ley n ° 2004-575 del 21 de junio de 2004 de confianza en la economía
        digital, los responsables de este sitio web www.preparacionfisicafutbol.com son:`,
      },
      {
        TITLE: `EDITOR DEL SITIO`,
        TEXT: [
          `Conte Thibaud`,
          `Número de SIRET : 80922462900034`,
          `95 chemin des combes – 07270 Le crestet`,
          `Email : prepafisicafutbol10@gmail.com`,
          `Site Web : https://www.preparacionfisicafutbol.com/`,
        ]
      },
      {
        TITLE: `HOSTING`,
        TEXT: [
          `Compañia de HOSTING: 1and1`,
          `7 place de la gare 57201 Sarreguemines`,
        ]
      },
      {
        TITLE: `DESARROLLO`,
      },
      {
        TITLE: `OBJETO DEL SERVICIO`,
        TEXT:[
          `www.preparacionfisicafutbol.com es un sitio de información y entrenamiento
          sobre la preparación física en el fútbol.`,
          `Sin embargo, se debe advertir al lector que los resultados obtenidos (ya sean
          positivos o negativos) dependen únicamente del uso que se haga de la información
          y que el autor no se hace responsable de estos resultados y declina toda
          responsabilidad. con respecto a las posibles consecuencias del uso del sitio. El
          lector también debe ser consciente de que las opiniones, los comentarios sobre la
          experiencia o los consejos que se brindan no son recomendaciones.`
        ]
      },
      {
        TITLE: `CONDICIONES DE USO`,
        TEXT: [
          `Este sitio está disponible en diferentes lenguajes web (HTML5, Javascript, CSS3, JQuery, PHP, etc.) 
        para una mayor comodidad del usuario y gráficos más agradables, le recomendamos que utilice navegadores 
        modernos como Internet Explorer, Safari, Firefox, Google. Chrome, etc… Los avisos legales 
        fueron generados en el sitio web Generador de avisos legales, ofrecido por Welye. 
        Preparación física fútbol implementa todos los medios a su alcance para asegurar una 
        información confiable y una actualización confiable de sus sitios web. 
        Sin embargo, pueden ocurrir errores u omisiones. Por lo tanto, el usuario de Internet debe garantizar 
        la exactitud de la información e informar de cualquier cambio en el sitio que considere útil.`,
          `No se responsabiliza en modo alguno del uso que se haga de esta información, ni de los daños directos 
        o indirectos que pudieran derivarse de ella.`
        ],
      },
      {
        TITLE: `COOKIES `,
        TEXT:
          `El sitio www.preparacionfisicafutbol.com
          puede pedirle que acepte cookies con fines estadísticos y de visualización. 
          Una cookie es información que el servidor del sitio que está visitando 
          coloca en su disco duro. Contiene varios datos que se almacenan en su 
          computadora en un archivo de texto simple al que accede un servidor 
          para leer y guardar información. No se puede acceder a algunas partes 
          de este sitio sin aceptar cookies.`,
      },
      {
        TITLE: `ENLACES DE HIPERTEXTO`,
        TEXT: [
          `Los sitios web pueden proporcionar enlaces a otros sitios web 
        u otros recursos disponibles en Internet. Preparación física fútbol no 
        tiene forma de controlar los sitios en conexión con sus sitios web. 
        No responde por la disponibilidad de dichos sitios y fuentes externas, 
        ni lo garantiza. No se hace responsable de los daños, de cualquier naturaleza, 
        que resulten del contenido de estos sitios o de fuentes externas, y 
        en particular de la información, productos o servicios que ofrecen, 
        o del uso que se pueda hacer de estos elementos. Los riesgos asociados 
        a este uso recaen íntegramente en el usuario de Internet, quien debe cumplir 
        con sus condiciones de uso. `,
        `Los usuarios, suscriptores y visitantes de los 
        sitios de Internet no pueden establecer un hipervínculo en dirección a este 
        sitio sin la autorización expresa y previa de Preparación física fútbol. 
        Preparación física fútbol se reserva el derecho de aceptar o rechazar un 
        hipervínculo sin tener que justificar su decisión.`
        ]
      },
      {
        TITLE: `SERVICIOS PRESTADOS`,
        TEXT:
          `Todas las actividades e información de la compañía se presentan en nuestro sitio. 
          Preparación física fútbol se esfuerza por proporcionar en el sitio
          información lo más precisa posible. La información en el sitio
          no son exhaustivas y las fotos no son contractuales. Se proporcionan 
          a reserva de que se hayan realizado modificaciones desde que se pusieron en línea. 
          Además, toda la información indicada en el sitio
          se proporciona solo a título informativo y están sujetos a cambios o 
          modificaciones sin previo aviso.`
      },
      {
        TITLE: `LIMITACIONES CONTRACTUALES DE DATOS`,
        TEXT: [
          `La información de este sitio es lo más precisa posible y el sitio se 
        actualiza en diferentes épocas del año, pero puede contener inexactitudes u 
        omisiones. Si nota una brecha, un error o lo que parece ser un mal funcionamiento, 
        infórmelo por correo electrónico a prepafisicafutbol10@gmail.com, 
        describiendo el problema con la mayor precisión posible (página con el problema, 
        tipo de computadora y navegador utilizado, etc).`,

        `Todo el contenido descargado 
        se realiza por cuenta y riesgo del usuario y bajo su exclusiva responsabilidad. 
        En consecuencia, no se hace responsable de los daños sufridos por el ordenador 
        del usuario o de cualquier pérdida de datos resultante de la descarga. 
        Además, el usuario del sitio se compromete a acceder al sitio utilizando 
        equipos recientes, que no contengan virus y con un navegador actualizado de 
        última generación.Los enlaces de hipertexto establecidos en el marco de este 
        sitio de Internet en la dirección de otros recursos presente en la red de 
        Internet no puede comprometer la responsabilidad de Preparación Física Fútbol.`
        ]
      },
      {
        TITLE: `PROPIEDAD INTELECTUAL`,
        TEXT: [
        `Todo el contenido de este en el sitio `,
        ` incluyendo, sin limitación, gráficos, imágenes, textos, videos, 
        animaciones, sonidos, logotipos, gifs e íconos así como su formato 
        son propiedad exclusiva de la empresa a excepción de las marcas, 
        logotipos o contenidos pertenecientes a otras empresas colaboradoras o autores. 
        Queda terminantemente prohibida cualquier reproducción, distribución, 
        modificación, adaptación, retransmisión o publicación, incluso parcial, 
        de estos diversos elementos sin el consentimiento expreso por escrito de 
        Preparacíon Física Fútbol. Esta representación o reproducción, por cualquier 
        medio, constituye una infracción sancionada por los artículos L.335-2 y 
        siguientes del Código de propiedad intelectual. El incumplimiento de esta 
        prohibición constituye una infracción que puede implicar la responsabilidad 
        civil y penal del infractor. Además, los propietarios del Contenido copiado 
        podrían emprender acciones legales en su contra.`,
      ]
      },
      {
        TITLE: `Declaración a la CNIL`,
        TEXT: `
        De conformidad con la Ley 78-17 del 6 de enero de 1978 
        (modificada por la Ley 2004-801 del 6 de agosto de 2004 sobre la 
        protección de las personas con respecto al procesamiento de datos personales) 
        relacionada con las tecnologías de la información, archivos y libertades, 
        este sitio no ha sido declarado a la Comisión Nacional de Informática y 
        Libertades (www.cnil.fr). `
      },
      {
        TITLE: `CONFLICTOS`,
        TEXT: [
          `Las presentes condiciones del sitio web `,
          ` se rigen por la ley francesa y cualquier controversia o litigio que 
          pueda surgir de la interpretación o ejecución de las mismas será 
          competencia exclusiva de los tribunales de los que depende la sede 
          social de la empresa. El idioma de referencia para la resolución de 
          controversias es el francés.`,
        ],
      },
      {
        TITLE: `DATOS PERSONALES`,
        TEXT: [
          `En general, no está obligado a proporcionarnos sus datos
          personales cuando visita nuestro sitio web.`,

          `Sin embargo, este
          principio tiene ciertas excepciones. De hecho, para ciertos
          servicios ofrecidos por nuestro sitio, es posible que deba
          proporcionarnos ciertos datos como: su nombre, su dirección de
          correo electrónico. Este es el caso cuando completa el
          formulario que se le ofrece en línea, en la sección "contacto" o
          para el "boletín". En cualquier caso, puede negarse a facilitar sus
          datos personales. En este caso, no podrá utilizar los servicios del
          sitio, en particular el de solicitar información sobre nuestra
          empresa, o de recibir boletines.`,

          `Puede darse de baja de nuestro boletín en cualquier momento
          haciendo clic en el enlace "darse de baja" en cada correo
          electrónico que le enviamos.`,

          `También puede solicitarnos que modifiquemos o eliminemos
          sus datos personales. Si no respetamos este compromiso, puede
          presentar una denuncia ante la CNIL.`,
        ]
      },
      {
        TITLE: `CANCELACIÓN Y REEMBOLSO`,
        TEXT: [
          `Nuestra principal ambición es ayudarlo tanto como sea posible
          y, por lo tanto, satisfacerlo. Si no es así, le daremos un reembolso
          completo sin límite de tiempo para productos digitales o
          materiales de preparación física. 
          De acuerdo con las disposiciones legales vigentes, dispone de
          un plazo ilimitado desde la recepción de sus productos para
          ejercer su derecho de desistimiento sin necesidad de justificar
          motivos ni abonar penalización.
          El derecho de desistimiento se puede ejercer en línea en
          nuestro correo electrónico prepafisicafutbol10@gmail.com,
          expresando el deseo de desistir.`,
          `Si considera que el problema o la disputa no se ha resuelto,
        puede comunicarse con un mediador en esta dirección: 
        https://www.mediationconso-ame.com/ ou 
        https://www.cnpm-mediation-consommation.eu/`
        ],
      },
      {
        TITLE: `TÉRMINOS Y CONDICIONES`,
        TEXT: `Estas Condiciones Generales tienen como finalidad advertirle de forma clara
        y transparente de las condiciones de venta, para cumplir con la ley y sobre
        todo para asegurarle que está satisfecho con sus compras.`,
      },
      {
        TITLE: `ARTÍCULO 1 – Campo de aplicación`,
        TEXT: [
          ` Estas condiciones generales de venta se aplican a todas las compras
          realizadas en el sitio web `,
          `Se refiere a los productos y servicios comercializados por el sitio: producto y
          material digital.Cualquiera que realice una compra en el sitio se considera un "cliente". `
        ]
      },
      {
        TITLE: `ARTÍCULO 2 – Pedidos y entrega`,
        TEXT: [
            `Puede realizar sus pedidos en Internet a través de la "tienda"`,
          `Para productos digitales`,
          `Una vez que su pedido haya sido ingresado en el sitio, se le enviará un correo
          electrónico de confirmación y se registrará su pedido. Una vez recibido este
          pedido, recibirá la información necesaria para descargar el producto o recibirá
          directamente el producto en su buzón.
          Acepta proporcionar una dirección de correo electrónico válida para recibir su
          producto. Los productos de información electrónica solicitados por el cliente
          se pueden utilizar en su computadora (también puede imprimirlos a su cargo).`,
          `Para equipos de preparación física`,
          `Nuestros tiempos de envío varían de 10 a 21 días dependiendo de los
          artículos pedidos. Los tiempos de envío dependen de la disponibilidad
          de los productos solicitados (expresados en días laborables, excepto
          sábados, domingos y festivos) y se entienden a partir de la validación de
          su pedido.`,
          `Los productos ofertados están disponibles hasta agotar existencias`,
          `En caso de retraso en el envío, se enviará un correo electrónico para
          informarle de una nueva fecha de entrega. Tenga en cuenta de todos
          modos, Preparación Física Fútbol no puede ser
          responsable de la entrega tardía debido exclusivamente a la
          indisponibilidad del cliente después de varias propuestas de cita por
          parte del transportista.`,
          `Preparación Física Fútbol estará exento de toda
          responsabilidad solo en caso de fuerza mayor definida por la
          jurisprudencia de los tribunales franceses como un evento de carácter
          imprevisible durante la celebración del contrato e irresistible en su
          ejecución. En cualquier caso, de acuerdo con las disposiciones legales,
              en caso de retraso en la entrega, tiene la opción de rescindir el contrato
          en los términos y condiciones definidos en el artículo L 216-1 del Código
          del Consumidor.`,
          `Por supuesto, puede contactarnos en caso de un problema (ver
          contacto en el sitio).`,
          `Entrega y responsabilidad`,
          `En caso de incumplimiento al recibirlo, deberá notificar al transportista y
          a Preparación Física Fútbol cualquier reserva sobre
           el producto entregado (por ejemplo: paquete dañado, ya abierto ...).`,
          `Tiene 3 días para realizar cualquier reserva con el transportista en caso
          de falta o daño. Si la entrega está incompleta o un producto está
          dañado, tienes 2 posibilidades:`,
          `1) Acepta la entrega, expresando imperativamente las reservas en el bono del transportista.`,
          `2) Rechaza el pedido en su totalidad e ingresa el motivo en el comprobante del transportista.`,
          `Cuando el cliente reciba personalmente la entrega de los objetos
          transportados y cuando el transportista no justifique haberle dado la
          posibilidad de comprobar efectivamente su buen estado, el plazo de 3
          días mencionado en el artículo L. 133-3 del Código de Comercio que
          extingue cualquier acción. contra el transportista se amplía a 10 días.`,
          `Para entregas fuera de Francia continental, el cliente acepta pagar
          todos los impuestos adeudados por la importación de productos,
          derechos de aduana, impuesto sobre el valor agregado y todos los
          demás impuestos adeudados según las leyes del país de recepción del
          pedido. Preparación Física Fútbol libera de facto cualquier responsabilidad legal si el
          pago de impuestos no fue realizado por el cliente.`
        ]
      },
      {
        TITLE: `ARTÍCULO 3 – Disponibilidad de artículos`,
        TEXT: `Siempre que nuestras ofertas y sus precios estén visibles en el sitio, son válidas.`,
      },
      {
        TITLE: `ARTÍCULO 4 – Precio`,
        TEXT: [
          `Los precios indicados en euros incluyen todos los impuestos y, por
          tanto, son netos. El precio se paga en su totalidad y en un solo pago al
          realizar el pedido, a menos que se especifique lo contrario. Todos los
          pedidos se facturan en euros y se pagan en euros.
          Preparación Física Fútbol se reserva el derecho de
          cambiar los precios de sus productos en cualquier momento.`,

          `En el caso de productos digitales, no se cobrarán gastos de envío en la
          medida en que el cliente sea responsable por sí mismo y por su cuenta
          de descargar el producto desde el sitio web a su ordenador.`,

          `Para los equipos de preparación física, los gastos de envío se anotan
          antes del pedido (en la cesta) y dependen del lugar de residencia del
          cliente.`
        ],
      },
      {
        TITLE: `ARTÍCULO 5 – Pago`,
        TEXT: `Los pagos se realizan con tarjeta de crédito a través de nuestro
        proveedor Stripe o mediante una cuenta de Paypal o con tarjeta de
        crédito a través de PayPal, el cliente está sujeto a los términos de uso
        de PayPal. Incluso si seleccionamos cuidadosamente a nuestros
        proveedores de servicios, el sitio no se hace responsable de ninguna
        falla relacionada con el sistema de pago.`,
      },
      {
        TITLE: `ARTÍCULO 6 – Reembolso de productos`,
        TEXT: `Nuestra principal ambición es ayudar a mis lectores tanto como sea
        posible y, por lo tanto, satisfacerlos. Si no es así, le daremos un
        reembolso completo sin límite de tiempo para productos digitales o
        materiales de preparación física.`,
      },
      {
        TITLE: `ARTÍCULO 7 – Ley aplicable`,
        TEXT: `Todas estas condiciones generales de venta, así como todas las
        operaciones de compraventa descritas en ellas, están sujetas a la
        legislación francesa.`,
      },
      {
        TITLE: `ARTÍCULO 8 – Aceptación del cliente`,
        TEXT: `Las presentes condiciones generales de venta así como los precios
        están expresamente aprobados y aceptados por el cliente, quien declara
        y reconoce conocerlos a la perfección. Por tanto, renuncia al derecho a
        invocar cualquier documento contradictorio y, en particular, a sus
        propias condiciones generales de compra. El acto de compra implica la
        aceptación de estas condiciones generales de venta.`,
      },
      {
        TITLE: `ARTÍCULO 9 – Derecho de uso y reproducción`,
        TEXT: [
          `Todos los textos e ilustraciones contenidos en los productos y servicios
          en cuestión están protegidos por la ley de derechos de autor y bases de
          datos, de acuerdo con el Código de Propiedad Intelectual.`,
          `El cliente se abstiene de cualquier uso de los productos y servicios con
        fines distintos a los meramente documentales, así como se abstiene de
        publicar, distribuir o vender, de cualquier forma, los contenidos a los que
        accede. El uso, reproducción, reventa o simple comunicación está
        estrictamente prohibido sin el consentimiento por escrito de este sitio y
        sus representantes y será sistemáticamente objeto de procedimientos
        legales.`,
          `Cualquier otro uso no previsto por el Código de Propiedad Intelectual
         está sujeto a autorización previa por escrito. `
        ],
      },
      {
        TITLE: `ARTÍCULO 10 – Responsabilidad`,
        TEXT: [
          `Nuestra responsabilidad no puede ser cuestionada, tanto frente a
          terceros como con el cliente, por las consecuencias del uso de los
          resultados de la investigación por parte del cliente o de las omisiones
          derivadas de un uso fallido, defectuoso, parcial o incorrecto, o del uso
          indebido del respuestas y textos consultados.`,
          `En consecuencia, no podemos ser considerados civilmente
          responsables ante el cliente o terceros por cualquier daño directo o
          indirecto que resulte del uso de la información y, en particular, que
          resulte de información inexacta o incompleta.`,
          `Bajo ninguna circunstancia podemos ser considerados responsables de
          ningún daño de ningún tipo, incluida la pérdida operativa, la pérdida de
          datos o cualquier otra pérdida financiera que resulte del uso o la
          imposibilidad de usar los productos y servicios aquí mencionados.
          Además, ninguna asistencia proporcionada de forma gratuita en el uso
          de los productos y servicios puede crear una garantía adicional con
          respecto a estas condiciones.`
        ],
      },
      {
        TITLE: `ARTÍCULO 11 - Derecho de desistimiento `,
        TEXT: [
          `De acuerdo con las disposiciones legales vigentes, dispone de un plazo
          ilimitado desde la recepción de sus productos para ejercer su derecho
          de desistimiento sin necesidad de justificar motivos ni abonar
          penalización.`,
          `El derecho de desistimiento se puede ejercer en línea en nuestro correo
          electrónico prepafisicafutbol10@gmail.com, expresando el deseo de
          desistir.`,
          `En caso de depreciación del producto resultante de manipulaciones
          distintas de las necesarias para establecer la naturaleza, características
          y buen funcionamiento del producto, usted puede ser considerado
          responsable.`,
          `Si considera que el problema o la disputa no se ha resuelto, puede
           comunicarse con un mediador en esta dirección:`,
          `https://www.mediationconso-ame.com/ ou `,
          `https://www.cnpm-mediation-consommation.eu/`
        ],
      },
    ],
    SITE_WEB: 'Sitio web:',
  },
  QUESTION: {
    BUTTON: "Más información",
    FIRST: {
      TITLE: '1. LAS CUALIDADES FÍSICAS EN EL FÚTBOL',
      SQUARE: {
        TITLE: `Recordatorio: ENCONTRARÁ INFORMACIÓN SOBRE TODAS LAS CUALIDADES FÍSICAS EN EL BLOG`,
        ITEMS: {
          TEXT_1: `Encontrará mucha información sobre el
          <strong>trabajo de velocidad</strong> en el fútbol aquí >>>`,
          TEXT_2: `Encontrará mucha información sobre el
          <strong>trabajo de resistencia en el fútbol</strong> aquí >>>`,
          TEXT_3: `Encontrará mucha información sobre el
          <strong>trabajo de fuerza en el fútbol</strong> aquí >>>`,
          TEXT_4: `Encontrará mucha información sobre el
          <strong
            >trabajo de coordinación y de flexibilidad en el fútbol</strong
          >
          aquí >>>`,
          TEXT_5: `Encontrará mucha información sobre el
          <strong>trabajo de lo físico con jóvenes futbolistas</strong>
          aquí >>>`,
        },
        LINKS: [
          'Velocidad',
          'Resistencia',
          'Fuerza',
          'Coordinación y Flexibilidad',
          'Jóvenes',
        ],
        BUTTON: "Más información",
      },
      LIST: [{
          id: 0,
          title: "¿Qué cualidades físicas deberías trabajar en la pretemporada en el fútbol amateur?",
          description: `
            <p>
              Las cualidades de resistencia (capacidad y potencia), de fuerza y de velocidad deben trabajarse antes del inicio del campeonato. 
            </p>
            <div class="content">
              <p>
                Le ofrecemos un ejemplo de programa de 6 semanas para la pretemporada >>>
              </p>
            </div>`,
          link: "TreveEstivale",
        },
        {
          id: 1,
          title: "¿Qué cualidades físicas se deben trabajar en la última sesión de entrenamiento antes de un partido en el fútbol amateur?",
          description: `
            <p>
              La prioridad de la última sesión de entrenamiento antes de un partido debe ser lógicamente la preparación del mismo.
            </p>
            <p>
              Ya no se trata de querer mejorar o desarrollar las cualidades físicas, sino de preparar el partido.
            </p>
            <p>
              Algunos entrenadores harán mucho trabajo táctico, otros trabajo técnico…
            </p>
            <p>
              Pero ¿qué pasa con el trabajo físico para este último entrenamiento antes del partido?
            </p>
            <div class="content">
              <p>
                Respuesta aquí:
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2019',
            id: 'que-trabajo-fisico-hacer-antes-de-un-partido',
          },
        },
        {
          id: 2,
          title: "¿Cuáles son las principales pruebas para evaluar la resistencia en el fútbol?",
          description: `
            <h5>Las pruebas “continuas”</h5>
            <p>
              <strong>El Test de “Cooper” </strong> es una prueba de campo que puede realizarse alrededor de una pista de atletismo. Consiste en recorrer la mayor distancia posible en 12 minutos. Puede dar una indicación de los niveles de resistencia y la condición física de los jugadores. El semi-Cooper (en 6 minutos) puede dar una indicación de la VAM continua de los jugadores.
            </p>
            <p>
              <strong>El Test “VAMEVAL” </strong> consiste en correr a una velocidad de carrera determinada por una pista de audio. <br />
              a pista de audio emite “pitidos” a intervalos regulares. <br />
              En cada pitido, el atleta debe estar a nivel de uno de los conos colocados en la pista (cada 20 metros). <br />
              La velocidad aumenta en 0,5 km/h cada minuto, lo que corresponde al paso a otra fase. <br />
              La primera fase comienza a una velocidad de 8 km/h. <br />
              El test termina cuando el jugador tenga un retraso de más  de 3 metros en 2 conos consecutivos. Este test se utiliza para determinar la VAM continua de los jugadores.
            </p>
            <p>
              <strong>El test “luc leger” </strong> consiste en correr ida y vuelta hasta el agotamiento entre dos líneas espaciadas por 20 metros, a un ritmo que aumenta en 0,5 km/h cada minuto.
              <br />
              Permite determinar la VAM continua de los jugadores.
            </p>
            <h5>Los tests “intermitentes”</h5>
            <p>
              En el <strong>test “Gacon 45/15”</strong>, los jugadores deben realizar una sucesión de carreras de 45 segundos intercaladas con 15 segundos de recuperación. El test comienza a 8 Km/h o 10 Km/h (según el nivel). <br />
              La velocidad aumenta en 0,5 Km/h cada minuto. <br />
              Permite determinar la VAM intermitente de los jugadores.
            </p>
            <div class="content">
              <p>
                El test que recomendamos :
                <strong>el test 30-15 ift</strong>
                <br />
                Permite determinar la VAM intermitente de los jugadores. <br />
                Más explicaciones aquí:
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2019',
            id: 'test-VAM-futbol',
          },
        },
        {
          id: 3,
          title: "¿Qué tipo de calentamiento debe hacerse antes de un entrenamiento o de un partido de fútbol?",
          description: `
            <p>
              El calentamiento es una fase importante en la preparación de una competición. Incluye diferentes aspectos psicológicos, técnicos y fisiológicos.
            </p>
            <p>
              Los dos roles fisiológicos del calentamiento son la prevención de lesiones y la preparación para el rendimiento.
            </p>
            <div class="content">
              <p>
                Le compartimos más información en este artículo.
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2019',
            id: '10-ejercicios-de-calentamiento-con-balon-en-futbol',
          },
        },
        {
          id: 4,
          title: "¿El trabajo físico puede hacerse con un balón?",
          description: `
            <div class="content">
              <p>
                Le damos todas las explicaciones en la formación en línea
              </p>
            </div>`,
          link: "Formation",
        },
        {
          id: 5,
          title: "¿Ofrecen ejemplos de sesiones de entrenamientos físicos?",
          links: [{
              description: `
            <div class="content">
              <p>
                Encontrará ejemplos de sesiones en algunos artículos del blog, pero también en los programas ofrecidos (vacaciones de verano y de invierno), tanto en las sesiones como a lo largo de una temporada. 
              </p>
            </div>`,
              link: "SaisonSenior",
            },
            {
              description: `
            <div class="content">
              <p>
                Y los ciclos de entrenamiento físico
              </p>
            </div>`,
              link: "CoachSenior",
            },
          ],
        },
        {
          id: 6,
          title: "¿Qué cualidades físicas son esenciales para el desarrollo de un portero?",
          description: `
            <p>
              Es importante trabajar todas las cualidades físicas en un portero.
            </p>
            <div class="content">
              <p>
                Ofrecemos ejemplos de sesiones de velocidad (reacción, explosividad, movimiento...) en los ciclos de entrenamientos físicos.
              </p>
            </div>`,
          link: "CoachSenior",
        },
        {
          id: 7,
          title: "¿Qué cualidades físicas son esenciales para desarrollar en el fútbol sala?",
          description: `
            <div class="content">
              <p>
                Es importante trabajar todas las cualidades físicas en el fútbol sala.  <br />
                Ofrecemos ejemplos de sesiones de velocidad y resistencia en los ciclos de entrenamientos físicos
              </p>
            </div>`,
          link: "CoachSenior",
        },
        {
          id: 8,
          title: "¿Qué ejercicios hacer para trabajar las cualidades físicas como la velocidad, la resistencia o la fuerza?",
          description: `
            <p>
              Ofrecemos muchos ejemplos de ejercicios en algunos artículos del blog, en la aplicación gratuita o en nuestras guías y programas.
            </p>
            <p>
              Debería encontrar lo que busca en el sitio ;)
            </p>`,
        },
        {
          id: 9,
          title: "¿Cómo distribuir el trabajo físico en la semana y en la sesión?",
          description: `
            <div class="content">
              <p>
                Le damos más explicaciones aquí
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2019',
            id: 'como-distribuir-el-trabajo-fisico-en-la-semana',
          },
        },
      ],
    },
    SECOND: {
      TITLE: '2. La prevención de lesiones',
      LIST: [{
          id: 0,
          title: "¿Qué trabajo físico realizar para evitar lesiones?",
          links: [{
              description: `            
                <p>
                  Le sugerimos que haga también una revisión con un osteópata, por ejemplo, o un profesional de la salud  durante las vacaciones de verano. 
                </p>
                <p>
                  Prepararse bien antes del inicio de los entrenamientos también puede ayudar a prevenir las lesiones (que son numerosas durante el período de reinicio).
                </p>
                <div class="content">
                  <p>
                    Antes de cada entrenamiento y partido, un buen calentamiento reduciría la probabilidad de sufrir una lesión muscular o articular. Le ofrecemos más información aquí
                  </p>
                </div>`,
              link: "Article",
              article: {
                year: '2019',
                id: 'Contracturas-elongaciones-desgarres',
              },
            },
            {
              description: `
                <p>
                  Ensuite, il est important d’avoir une bonne hygiène de vie et de
                  bien récupérer après les entraînements et les matchs.
                </p>
                <p>
                  Une bonne récupération va permettre d'être moins fatigué et plus
                  frais pour les futurs entraînements et matchs.
                </p>
                <p>
                  Si l'on est frais et pas fatigué, le
                  <span class="red">risque de blessure est plus faible</span> .
                </p>
                <p>
                  Les entraînements doivent être adaptés au public et progressifs.
                </p>
                <p>
                  Pour le travail de prévention spécifique en football, le
                  renforcement musculaire, le travail de gainage ou encore la
                  proprioception auront une place de choix dans vos programmes.
                </p>`,
            },
          ],
        },
        {
          id: 1,
          title: "¿Qué programa físico realizar después de una lesión?",
          description: `
          <p>
              Esto dependerá del tipo de lesión, de la articulación o del músculo afectado y de otros parámetros específicos a la persona lesionada (edad, nivel,…).
            </p>
            <p>
              Por lo tanto, la reeducación y la reacondicionamiento físico serán personalizadas.
            </p>
            <p>
              Por este motivo, no le ofrecemos programas de reacondicionamiento físico y recomendamos consultar a un fisioterapeuta y/o preparador físico para ayudarle en estas fases antes de su regreso al campo.
            </p>
            <div class="content">
              <p>
                Sin embargo, le ofrecemos algunos consejos para lesiones “no demasiado graves” (como una lesión muscular en los tendones o un esguince de tobillo) en el blog. <br />
                Más información aquí >>>
              </p>
            </div>`,
          link: "BlogCategory",
          paramId: "Prevención de Heridas",
        },
        {
          id: 2,
          title: "¿Cómo se hace un vendaje de tobillo antes de un entrenamiento o un partido?",
          description: `
            <div class="content">
              <p>
                Compartimos un video de Anais (osteópata) en nuestra página Facebook que podría ayudarle >>>
              </p>
            </div>`,
          external: "https://www.facebook.com/preparationphysiquefootball/posts/1178668345659478",
        },
        {
          id: 3,
          title: "¿Cuáles son las principales causas de lesiones en el fútbol?",
          description: `
            <p>
              Existen algunos factores que propiciarían la aparición de lesiones. Se los presentamos: 
            </p>
            <h5>Factores individuales</h5>
            <p>
              - Pérdida de elasticidad muscular con la edad <br />
              - Déficit de elasticidad muscular (limita las amplitudes articulares necesarias para la práctica deportiva) <br />
              - Convalecencia, gripe, enfermedades infecciosas <br />
              - Factores psicológicos (alta emotividad, estrés)
            </p>
            <h5>Factores higiénicos y dietéticos</h5>
            <p>
              - Insuficiente hidratación <br />
              - Falta de sueño <br />
              - Dieta desequilibrada <br />
              - Dopaje: anabolizantes (favorecen la aparición de calambres, aumentan el volumen muscular sin aumentar la resistencia de los tendones), corticoides (disminuyen el umbral del dolor, debilitan los tejidos) <br />
              - Mala higiene dental (bacterias de las caries que se infiltran en la sangre y crean infecciones) <br />
              - Fumar <br />
            </p>
            <h5>Factores relacionados en el entrenamiento</h5>
            <p>
              - <span class="red">Falta o insuficiencia de calentamiento</span>
              <br />
              - Entrenamiento excesivo o mal programado <br />
              -
              <span class="red"
                >Entrenamiento agonista-antagonista no armónico</span
              >
              <br />
              - <span class="red">Déficit de fuerza excéntrica</span> <br />
              - Falta de recuperación <br />
            </p>
            <h5>Factores ambientales</h5>
            <p>
              - Humedad <br />
              - Frío, viento <br />
              - Suelo resbaladizo <br />
            </p>`,
        },
        {
          id: 4,
          title: "¿Cuáles son las principales lesiones en el fútbol?",
          description: `
            
            <p>
              <span class="red"
                >El fútbol es un deporte de alto riesgo en cuanto a lesiones.
              </span>
            </p>
            <p>
              En un estudio realizado por la UEFA durante la temporada 2012-2013 y en el que participaron 22 de los principales clubes europeos, ¡se registraron 892 lesiones!
            </p>
            <h5 class="blue">¿Cuáles son estas lesiones?</h5>
            <p>
              Según un estudio de la UEFA, los desgarros, esguinces y contracturas representan el 38% de las lesiones de los futbolistas y el muslo es el miembro que menos se salva de estas lesiones (el 80% sólo en los tendones).
            </p>
            <p>
              Entendemos la importancia de un trabajo de prevención de los tendones (una debilidad en los tendones también influiría en las lesiones de rodilla...).
            </p>
            <p>
              El 25,4% de las lesiones afectan a las articulaciones de la rodilla o al tobillo (principalmente esguinces) y el 14% de las lesiones de los futbolistas afectan a la cadera y a la ingle (pubalgia).
            </p>
            <h5 class="blue">¿Cómo se producen estas lesiones?</h5>
            <p>
              Esta es una tabla interesante sobre las principales acciones que conducen a las lesiones (Hawkins et al, 2001).
            </p>
            <img
              src="https://www.preparationphysiquefootball.com/images/blog/tabla_lesiones.png"
              class="img-fluid"
              alt="table"
            />
            <p>
              Se observa que las lesiones se producen muy a menudo “por sí solas” (corriendo, cambiando de dirección, pateando, saltando,…).
            </p>
            <p>
              Casi la mitad de las lesiones en un partido ocurren en los últimos 30 minutos.
            </p>`,
        },
      ],
    },
    THIRD: {
      TITLE: '3. Los jóvenes',
      LIST: [{
          id: 0,
          title: "¿Por qué trabajar lo físico en los jóvenes?",
          description: `
            <p>
              El trabajo físico en los jóvenes tiene 3 objetivos principales:
            </p>
            <p>
              - Mejorar los rendimientos <br />
              - Prevenir las lesiones <br />
              - Hacer trabajos preparatorios <br />
            </p>
            <p>
              Pero también permite:
            </p>
            <p>
              Desarrollar lo mental, el lado “rudo” del trabajo físico… para pasar de nivel, “sentirse bien al hacerse daño” parece ser obligatorio.  <br />
              Establecer hábitos y actitudes de entrenamientos. <br />
              Tender a la autonomía y adquirir ciertos valores esenciales: rigor, disciplina, trabajo… <br />
              Hacer que el joven sea “proactivo”, actor de su práctica y no sólo “consumidor”... <br />
              Marcar la diferencia entre “ir al entrenamiento” e “ir a entrenarse"… <br />
            </p>`,
        },
        {
          id: 1,
          title: "¿Qué cualidades físicas deben trabajarse primero con los jóvenes futbolistas?",
          description: `
            <p>
            Para cada cualidad física, existen períodos clave llamados
            <span class="red">“edad de oro”</span> .
            </p>
            <p>
              Si se trabaja bien una cualidad física durante este período, ¡su desarrollo debería ser óptimo! (el joven debería progresar mucho en esta cualidad física).
            </p>
            <br />
            <p>
              Tomar en cuenta las “edades de oro” del desarrollo de las cualidades físicas podría ser una ayuda preciosa para orientar bien las cualidades físicas a desarrollar prioritariamente según la edad de los jugadores (edad biológica).
            </p>
            <p>
              Por ejemplo, de los sub6 a los sub12, el trabajo de coordinación es esencial porque es la edad de oro para el desarrollo de esta cualidad.
            </p>
            <p>
              Para desarrollar la fuerza, un año después del pico de crecimiento sería el período óptimo.
            </p>
            <div class="content">
              <p>
                Le ofrecemos más explicaciones sobre este tema en este artículo:
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2020',
            id: 'jovenes-principios-y-fases-que-deben-respetarse',
          },
        },
        {
          id: 2,
          title: "¿Cómo desarrollar física y mentalmente a los jóvenes futbolistas?",
          links: [{
              description: `
                <p>
                  Hemos visto que hay edades de oro para el desarrollo de cada una de las cualidades físicas.
                </p>
                <p>También existe un “plan de referencia”.</p>
                <p>
                  Los entrenadores e investigadores han establecido “un plan de referencia” que indica los pasos a seguir durante la infancia y la adolescencia para un desarrollo óptimo.
                </p>
                <div class="content">
                  <p>
                    Le ofrecemos más información en estos artículos:
                  </p>
                </div>`,
              link: "Article",
              article: {
                year: '2020',
                id: 'jovenes-principios-y-fases-que-deben-respetarse',
              },
            },
          ],
        },
        {
          id: 3,
          title: "¿Deben realizar estiramientos los jóvenes futbolistas?",
          description: `
            <div class="content">
              <p>
                Le ofrecemos más información en este artículo:
              </p>
            </div>`,
          link: "Article",
          article: {
            year: '2020',
            id: 'jovenes-principios-y-fases-que-deben-respetarse',
          },
        },
        {
          id: 4,
          title: "¿Los jóvenes futbolistas deben trabajar lo físico?",
          description: `
            <div class="content">
              <p>
                Usted encontrará más explicaciones en la guía en línea (ofrecida) sobre la preparación física en los jóvenes futbolistas >>>
              </p>
            </div>`,
          link: "GuideJeunes",
        },
        {
          id: 5,
          title: "¿Los jóvenes pueden hacer ejercicios intensos?",
          description: `
            <div class="content">
              <p>
                Usted encontrará más explicaciones en la guía en línea (ofrecida) sobre la preparación física en los jóvenes futbolistas >>>
              </p>
            </div>`,
          link: "GuideJeunes",
        },
        {
          id: 6,
          title: "¿Qué trabajo físico proponer a los jugadores sub8-sub9?",
          description: `
            <div class="content">
              <p>
                El trabajo de coordinación-motricidad y de velocidad es esencial para estas categorías. <br />
                Le ofrecemos ejemplos de ciclos y ejercicios para trabajar estas cualidades físicas >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 7,
          title: "¿Qué trabajo físico proponer a los sub10-sub11?",
          description: `
            <div class="content">
              <p>
                El trabajo de coordinación-motricidad y de velocidad es esencial para estas categorías. <br />
                Le ofrecemos ejemplos de ciclos y ejercicios para trabajar estas cualidades físicas >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 8,
          title: "¿Qué trabajo físico proponer a los sub12-sub13?",
          description: `
            <div class="content">
              <p>
                El trabajo de velocidad es esencial para estas categorías. Le proponemos ejemplos de ciclos y ejercicios para trabajar esta cualidad física >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 9,
          title: "¿Qué trabajo físico proponer a los sub14-sub15?",
          description: `
            <div class="content">
              <p>
                El trabajo de resistencia y de velocidad es esencial para estas categorías. Le ofrecemos ejemplos de ciclos y ejercicios para trabajar estas cualidades físicas >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
        {
          id: 10,
          title: "¿Qué trabajo físico proponer a los sub16-sub17?",
          description: `
            <div class="content">
              <p>
                El trabajo de resistencia y velocidad es esencial para estas categorías. Le ofrecemos ejemplos de ciclos y ejercicios para trabajar estas cualidades físicas >>>
              </p>
            </div>`,
          link: "CoachJeunes",
        },
      ],
    },
    FOURTH: {
      TITLE: '4. La preparación mental en el fútbol',
      LIST: [{
          id: 0,
          title: "¿Qué es la preparación mental?",
          description: `
            <p>
              Según J. Fournier, la preparación mental se define como la “preparación para la competición mediante el aprendizaje de habilidades mentales y cognitivas, cuyo objetivo principal es optimizar el rendimiento personal del atleta, al tiempo que se promueve el placer de la práctica y se fomenta el logro de la autonomía”.
            </p>
            <p>
              La preparación mental le permitirá preparar un partido de la mejor manera posible y optimizar su rendimiento en la competición. 
            </p>
            <br />
            <h5 class="blue">
              ¿Cuáles son los objetivos de la preparación mental?
            </h5>
            <br />
            <p>
              Permite al individuo tomar conciencia de los numerosos parámetros mentales presentes en cada actividad, deportiva o no, y trabajar sobre ellos para mejorar. 
            </p>
            <p>
              El objetivo de la preparación mental es también desarrollar, potenciar y optimizar las capacidades mentales del individuo para que pueda expresar plenamente sus cualidades (físicas, técnicas y tácticas) durante el entrenamiento y la competición o en la vida cotidiana y el día de la prueba, y permitirle estar en condiciones psicológicas óptimas el día D a la hora H para lograr un rendimiento, de forma autónoma.
              <br />
              Por lo tanto, el objetivo de la preparación mental es mejorar el rendimiento, preservando el equilibrio y la salud del individuo.  <br />
              Para Denis Troch, se trata de “¡sacar lo mejor de un potencial!”.
            </p>`,
        },
        {
          id: 1,
          title: "¿Qué métodos se utilizan en la preparación mental?",
          description: `
            <p>
              La preparación mental utiliza diversos métodos y técnicas, que pueden ser de orden Fisiológico (relajación, control respiratorio, meditación...), Cognitivo (imágenes, diálogo interno…), Conductual (confianza en sí mismo, concentración, PNL…) o Compositivo (manejo del estrés, de la ansiedad...).
            </p>`,
        },
        {
          id: 2,
          title: "¿Cuáles son los intereses y ventajas de la preparación mental?",
          description: `
            <p>
              Los intereses y ventajas de trabajar en la preparación mental son numerosos, tanto para el individuo como para el grupo. Esta es una lista exhaustiva:
            </p>
            <h5><i>Para el individuo:</i></h5>
            <br />
            <ul class="list">
              <li>Mejora su conocimiento de sí mismo</li>
              <li>Mejora su motivación</li>
              <li>Mejora la confianza en sí mismo</li>
              <li>Mejora las habilidades de relacionarse y la comunicación</li>
              <li>Mejora su concentración</li>
              <li>Ayuda a manejar mejor sus emociones</li>
              <li>Ayuda a manejar su estrés</li>
              <li>Ayuda a recuperarse mejor</li>
              <li>Favorece el rendimiento:</li>
              <li>Aprendizaje de nuevos movimientos/Corrección de movimientos/</li>
              <li>
                Anclaje de sensaciones motrices/Anclaje de los recuerdos positivos
              </li>
              <li>Limitación del riesgo de lesiones</li>
              <li>Mejoramiento del reacondicionamiento físico/de la reeducación</li>
            </ul>
            <br />
            <h5><i>Para el grupo:</i></h5>
            <br />
            <ul class="list">
              <li>Desarrolla la cohesión de grupo</li>
              <li>Hace destacar a los diferentes líderes</li>
              <li>Desarrolla estrategias deportivas</li>
              <li>
                Gracias a este trabajo de preparación mental, se puede:
              </li>
              <li>reforzar los éxitos y reducir los errores</li>
              <li>
                expresar plenamente las cualidades físicas, técnicas y tácticas en el entrenamiento y en la competición
              </li>
              <li>
                estar en condiciones psicológicas óptimas el día D a la hora H
              </li>
            </ul>`,
        },
        {
          id: 3,
          title: "¿Quién puede utilizar la preparación mental?",
          description: `
              <p>
                La preparación mental está dirigida a cualquier atleta que desee desarrollar sus capacidades, alcanzar sus objetivos, mejorar su rendimiento, restaurar sus relaciones con su entrenador y sus compañeros, convertirse en el actor de su rendimiento… pero también a todos los entrenadores y todos los grupos de deportistas. 
            </p>`,
        },
        {
          id: 4,
          title: "No soy futbolista de alto nivel, ¿puedo practicar la preparación mental?",
          description: `
            <p>
              La preparación mental tiene como objetivo su bienestar y debe ayudarle a desarrollar sus habilidades (concentración, motivación, confianza en sí mismo, manejo de las emociones...) ¡No es necesario ser un deportista de alto nivel para conocerse mejor y querer mejorar su rendimiento!
            </p>`,
        },
        {
          id: 5,
          title: "¿Es difícil utilizar la preparación mental?",
          description: `
            <p>
              No. A menudo tenemos una imagen equivocada de lo que es la preparación mental, pero es mucho más sencilla de aplicar de lo que imaginamos.
            </p>`,
        },
        {
          id: 6,
          title: "¿Hay que creer en la preparación mental para obtener resultados positivos?",
          description: `
            <p>
              No. La preparación mental es una disciplina resultante de la investigación en psicología del deporte y ¡cuenta con teorías y herramientas eficaces y científicamente validadas para mejorar y optimizar las habilidades mentales y el bienestar de los deportistas!
            </p>`,
        },
        {
          id: 7,
          title: "No tengo ningún “problema” a nivel mental, entonces ¿la preparación mental es inútil?",
          description: `
            <p>
              En Francia, estamos muy atrasados en cuanto a la preparación mental de los deportistas y con frecuencia pensamos que sólo es útil cuando algo va mal. <br />
              En el extranjero, ¡muchos deportistas recurren a un entrenador mental cuando todo va bien! ¿Por qué? Para reforzar y mejorar ciertos aspectos relacionados con lo mental (concentración, manejo de las emociones,…) <br />
              Es un poco como la medicina, la utilizamos cuando estamos enfermos, otros (los chinos, por ejemplo) ¡la utilizan cuando están sanos!
            </p>
            <p>
              Al igual que lo físico, lo técnico y lo táctico, ¡lo mental puede y debe entrenarse!<br />
              A menudo ¡es el aspecto mental el que marca la diferencia en la competición! <br />
              ¡Hay que aprenderlo y desarrollarlo!
            </p>
            <p>
              “Yo creo que el aspecto mental es la parte esencial del tenis, pero las personas no lo saben”.<br />
              Boris Becker, ex tenista.
            </p>
            <p>
              “No debes estar estresado o te ganarán los nervios, ni demasiado relajado o no te darás cuenta de lo que está en juego. Por lo tanto, debes encontrar el equilibrio adecuado. Cuando me fui al OM y luego a Milán, me acostumbré a tener la actitud adecuada: saber ser un luchador y permanecer lúcido al mismo tiempo, pero también someterme a una presión extra sin tener problemas. Es cuestión de equilibrio”.<br />
              Marcel Desailly (futbolista).
            </p>
            <p>
              “El aspecto mental del deporte sólo puede llevarte hasta cierto punto”, dijo Shannon Miller, campeona olímpica de gimnasia en una entrevista con la Fundación Dana. “El aspecto mental debe imponerse, sobre todo cuando se trata de ser el mejor de los mejores. En los Juegos olímpicos, todo el mundo tiene talento. Todo el mundo entrena duro. Todo el mundo trabaja duro. Lo que marca la diferencia entre un medallista de oro y un medallista de plata es simplemente el aspecto mental”.
            </p>`,
        },
      ],
    },
    FIFTH: {
      TITLE: '5. Gestión',
      LINK_1: 'Gestión',
      LIST: {
        FAQ_1: {
          TEXT: `Encontrará información sobre este tema de la gestión en el blog. `,
          BUTTON: "Más información",
        },
        FAQ_2: {
          TEXT: `También ofrecemos una guía sobre la gestión para los entrenadores de fútbol amateur >>>`,
          BUTTON: "Más información",
        },
      },
    },
    SIX: {
      TITLE: '6. Otros',
      LIST: [{
          id: 0,
          title: "¿Qué preparación física utilizar para un grupo femenino?",
          description: `
            <p>
            Le recomendamos este libro: “La préparation physique en footbal féminin - préparation athlétique, spécificités et prévention” (La preparación física en el fútbol femenino-preparación atlética, especificidades y prevención)”. 
            </p>`,
        },
        {
          id: 1,
          title: "¿Qué debe comer un futbolista?",
          description: `
            <div class="content">
              <p>
                Le ofrecemos explicaciones en el blog en la parte de nutrición 
              </p>
            </div>`,
          link: "BlogCategory",
          paramId: "Nutrición",
        },
        {
          id: 2,
          title: "¿Cómo recuperarse en el fútbol?",
          links: [{
              description: `
              <p>
                Hay 3 pilares principales:
              </p>
  
                <p>
                  El sueño. <br />
                  Le ofrecemos más información en este artículo:
                </p>
  
              <br />
            `,
              link: "Article",
              article: {
                year: '2019',
                id: '7-consejos-para-dormir-bien',
              },
            },

            {
              description: `
              <div class="content">
                <p>
                  La alimentación. <br />
                  Le ofrecemos más información en este artículo:
                </p>
              </div>
              <br />
            `,
              link: "Article",
              article: {
                year: '2019',
                id: 'QUE-COMER-DESPUES-DE-LOS-PARTIDOS',
              },
            },
            {
              description: `
              <div class="content">
                <p>
                  La hidratación. <br />
                  Le ofrecemos más información en este artículo:
                </p>
              </div>
              <br />
            `,
              link: "Article",
              external: 'https://www.preparationphysiquefootball.com/201705/Hydratation'
            },
            {
              description: `
              <p>
                Luego, hay técnicas adicionales que pueden promover la recuperación, tales como:
              </p>
              <br />
              <div class="content">
                <p>
                  El frío. <br />
                  Le ofrecemos más información en este artículo:
                </p>
              </div>
              <br />
            `,
              link: "Article",
              article: {
                year: '2019',
                id: 'Recuperacion-en-futbol-uso-de-frio-y-calor',
              },
            },
            {
              description: `
              <div class="content">
                <p>
                  La electroestimulación. <br />
                  Le ofrecemos más información en este artículo:
                </p>
              </div>`,
              external: 'https://www.preparationphysiquefootball.com/201707/Recuperer-avec-l-electrostimulation',
            },
            {
              description: `
              <div class="content">
                <p>
                  Las medias de compresión. <br />
                  Le ofrecemos más información en este artículo:
                </p>
              </div>
              <br />
            `,
              link: "Article",
              article: {
                year: '2019',
                id: 'LAS-MEDIAS-DE-COMPRESION',
              },
            },
          ],
        },
        {
          id: 3,
          title: "¿Qué materiales utilizar para trabajar la velocidad?",
          description: `
            <p>
              Es posible trabajar la velocidad sin materiales.
            </p>
            <p>
              ¡Con unos pocos conos se pueden hacer un montón de ejercicios!
            </p>
            <p>Por ejemplo, usted puede:</p>
            <ul class="list">
              <li>
                variar las salidas (de espalda, de lado, en movimiento, en retroceso,...),
              </li>
              <li>
                variar los activadores de la salida (sonoros, visuales,...),
              </li>
              <li>•	variar las distancias,</li>
              <li>
                introducir cambios de dirección (conos escalonados),
              </li>
              <li>introducir idas y vueltas,</li>
              <li>
                introducir idas en carrera rápida y vueltas en retroceso o con cambio de dirección,
              </li>
              <li>
                introducir “conos especiales” donde el jugador debe realizar una acción específica (una vuelta al cono, por ejemplo),
              </li>
            </ul>
            <p>...</p>
            <p>
              Así que incluso sin materiales o equipos, usted puede ofrecer cosas interesantes.
            </p>
            <p>
              Para aportar un poco de variedad, de novedad y un lado divertido, es posible utilizar materiales.
            </p>
            <p>Usted puede introducir equipos para:</p>
            <ul class="list">
              <li>
                crear resistencia (trineo de potencia, elásticos, paracaídas, chaleco con peso...),
              </li>
              <li>
                para ayudar o aligerar la carrera (elástico estirado colocado en la dirección de la carrera), para crear obstáculos (vallas bajas, conos,...),
              </li>
              <li>
                para la salida (por ejemplo, balón medicinal en las manos en posición de “silla” o de cuclillas, estático durante 5 segundos y luego dejar caer el balón medicinal y “explotar” en la salida,...), para modificar una parte de la carrera (colocar una escalera rítmica después de 10 metros de carrera rápida, el jugador debe frenar rápidamente, cambiar los apoyos de los pies en la escalera y regresar en carrera rápida durante unos metros...).
              </li>
            </ul>`,
        },
      ],
    },
    SEVENTH: {
      TITLE: '7. Lo que ofrecemos para ayudarle',
      LIST: {
        FAQ_1: {
          TEXT: `Le ofrecemos un blog con información totalmente gratuita.`,
          BUTTON: "Más información",
        },
        FAQ_2: {
          TEXT: `Le ofrecemos ejemplos de programas (ofrecidos) para las vacaciones de verano y de invierno.`,
          BUTTON: "Más información",
        },
        FAQ_3: {
          TEXT: `Le ofrecemos una guía sobre la preparación física en los jóvenes futbolistas (ofrecido)`,
          BUTTON: "Más información",
        },
        FAQ_4: {
          TEXT: `Le ofrecemos un documento PDF con ejemplos de ejercicios físicos para trabajar la velocidad y la resistencia (ofrecido)`,
          BUTTON: "Más información",
          LINK: 'https://www.preparacionfisicafutbol.com/documentos/85-ejercicios-fisicos-con-balon.pdf',
        },
        FAQ_5: {
          TEXT: `Le ofrecemos ideas de ejercicios para trabajar la parte física`,
          BUTTON: "Más información",
        },
        FAQ_6: {
          TEXT: `Le ofrecemos ejemplos de programas de temporada y ciclos de entrenamientos para todas las categorías`,
          BUTTON: "Más información",
        },
        FAQ_7: {
          TEXT: `Le ofrecemos cursos para mejorar sus conocimientos sobre varios temas relacionados con la preparación física en el fútbol amateur.`,
          BUTTON: "Más información",
        },
      }
    },
    EIGHTH: {
      TITLE: '8. Lo que no le ofrecemos',
      CONTENT: {
        TEXT_1: `4-4-2, 4-3-3, 4-5-1, animación ofensiva-defensiva, posesión, bloqueo defensivo, bloqueo en equipo...: Recibimos muchos mensajes sobre estos temas y sobre la táctica en general, sin embargo, “sólo” somos especialistas en la preparación física y queremos mantenernos dentro de lo que conocemos bien para ofrecerle un contenido de calidad.`,
        TEXT_2: `Le recomendamos otros sitios muy interesantes que abordan la parte táctica y que podrían responder a sus necesidades, como <a href="https://www.efficientfootball.com/" target="_blank">https://www.efficientfootball.com/</a> o <a href="https://www.futbolsesion.com/" target="_blank">https://www.futbolsesion.com/</a>`,
        TEXT_3: `Igual para las preguntas relacionadas específicamente con la parte técnica como el trabajo de pases, de controles, de dribleo,…`,
        TEXT_4: `A menudo incluimos trabajo técnico o táctico en nuestros ejercicios físicos (para trabajar de manera más integral), pero no ofrecemos ejercicios específicos sobre la técnica o la táctica en el fútbol.`,
      }
    },
  },
  PROGRAMMES: {
    TITLE: 'NOS PROGRAMMES SPÉCIAL',
    CARDS: [{
        title: "Programme Pré-Saison",
        subtitle: "Gardiens de But",
        text: `<p class="card-text">Votre programme à <span class="promo">12.90€</span> au lieu de <span class="price">19.90€</span></p>`,
        button: "En savoir plus",
      },
      {
        title: "Programme Pré-Saison",
        subtitle: "futsal",
        text: `<p class="card-text">Votre programme à <span class="promo">12.90€</span> au lieu de <span class="price">19.90€</span></p>`,
        button: "En savoir plus",
      },
    ],
    COMMANDE: {
      TEXT_1: `Après avoir commandé, vous allez recevoir directement un email
      (dans les 5 minutes) avec le lien d’accès à la plateforme en ligne
      et à vos programmes.`,
      TEXT_2: `Tous les programmes sont disponibles sur mobile, tablette et
      ordinateur.`,
    },
    END: {
      TEXT_1: `Nous souhaitons vous proposer un contenu de qualité et un
      contenu qui vous aide et vous satisfait pleinement. Ainsi,
      toutes nos formations, programmes ou guides sont 100% satisfait
      ou remboursé!`,
      TEXT_2: `Si le contenu ne vous convient pas, il vous suffit de nous
      envoyer un email et nous vous remboursons directement.`,
    }
  },
  FOOTER: {
    LINKS: ['Aviso legal', 'Sobre nosotros', 'Contacto'],
    END: '© 2023 Preparación Física Fútbol, todos los derechos reservados.',
    END_MOBILE: `© 2023 Preparación Física Fútbol, <br /> todos los derechos reservados.`,
    FACEBOOK: 'https://www.facebook.com/Prepafisicafutbol/',
    INSTAGRAM: 'https://instagram.com/preparacion.fisica.futbol',
  },
  PRODUCT_SHOP: {
    CONTENT: {
      TEXT_1: 'Acceda directamente',
      TEXT_2: 'después de hacer su pedido.',
    },
    BUTTON: 'Hacer pedido',
    END: `Al hacer clic en HACER PEDIDO, usted será redirigido a la tienda en línea para hacer su pedido.`,
  },
  CYCLES: {
    PROGRAMME: `El programa en línea está disponible para teléfonos celulares, tabletas y computadoras. `,
    APRES: {
      TITLE: `Después de hacer el pedido, recibirá un correo electrónico con el enlace de acceso a la guía en línea.`,
      END: `El objetivo principal es darle ideas y ejemplos para que tenga una base sobre la cual ¡diseñar sus propios ciclos y ejercicios!`
    },
    GARANTIE: {
      TITLE: 'Garantía de satisfacción o le devolvemos su dinero',
      SUBTITLE: 'Si usted no está completamente satisfecho, ¡envíe un simple correo electrónico pidiendo la devolución de su dinero!',
    },
    PAIEMENT: {
      TITLE: 'Pago 100% seguro',
      SUBTITLE: 'El pago es totalmente seguro por las plataformas reconocidas como PayPal.',
    },
    CONTACT: {
      TEXT_1: 'Si tiene alguna duda sobre la guía, no dude en preguntarnos',
      TEXT_2: 'en el chat (abajo, a la derecha de la página) o en nuestra',
      LINK: 'página de contacto'
    },
  },
  FUTSAL_P: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE RESITENCIA-POTENCIA EN EL FÚTBOL SALA `,
      SUBTITLE: `Con 100 ejemplos de ejercicios de resistencia`,
      SUBSUB: "Para los seniors",
    },
    CHECK: {
      TITLE: "Adaptados a los seniors",
      CHECKS: [
        `¿A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?`,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la resistencia-potencia en el fútbol sala?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?`,
        `¿Le gustaría conocer más ejercicios para trabajar la resistencia-potencia en el fútbol sala?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
        Le ofrecemos un programa en línea con 
        <span>2 ciclos de 4 semanas de entrenamiento sobre la resistencia-potencia</span>
        y 100 ideas de ejercicios para trabajar la resistencia en el fútbol sala.
      </p>
      <p>
        Esta guía está dirigida a entrenadores de equipos seniors de fútbol sala que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la resistencia-potencia. 
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la velocidad
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de velocidad (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la velocidad)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la velocidad sin equipo o con poco equipo (que todos los clubes pueden tener, todos con balón)
      </p>`,
    APRES: {
      COL1: `<p>
          Encontrará 2 ciclos de entrenamiento físico para trabajar la resistencia-potencia en el fútbol sala. Por ejemplo, usted puede ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad con el fin de sorprender al cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento...)
        </p>`,
      COL2: `<p>
          Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios!
        </p>
        <p>
          Le ofrecemos más de 100 ejemplos de ejercicios para trabajar la resistencia (con y sin balón)
        </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO EN FUTBÓL SALA ESPECIAL "POTENCIA AERÓBICA"`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/4_a2773348-a19a-42c5-b427-30d423f36037_900x.png?v=1621270059",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-senior/products/2-ciclos-de-entrenamiento-fisico-en-futsal-especial-poder-de-resistencia",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Cycles/Pui left-1.png",
          title: "4 CONTRA 4 CON PORTEROS",
          text: `
        <p><i class="fas fa-check"></i> 4 zonas en el campo completo.</p>
        <p><i class="fas fa-check"></i> Nunca más de 2 jugadores de un mismo equipo en una misma zona./p>
        <p><i class="fas fa-check"></i> Juego libre, 2 minutos de juego, 6 partidos, por ejemplo.</p>
        `,
        },
        {
          img: "Cycles/Pui left-2.png",
          title: "CONSERVACIÓN DEL BALÓN 4 CONTRA 2 Y 6 CONTRA 6",
          text: `
        <p><i class="fas fa-check"></i> Alterne entre conservación del balón en situación de superioridad numérica en un espacio pequeño (4 contra 2) y conservación del balón en un espacio grande 6 contra 6.</p>
        <p><i class="fas fa-check"></i> 2 minutos, 4 contra 2 (los 2 jugadores haciendo presión cambian cada 30 segundos). Se anota 1 punto cada 10 pases seguidos.</p>
        <p><i class="fas fa-check"></i> Se anota un punto cuando el jugador que hace presión recupera el balón. </p>
        <p><i class="fas fa-check"></i> A continuación, directamente, un juego 6 contra 6 durante 4 minutos.</p>
        <p><i class="fas fa-check"></i> Posibilidad de anotar después de 10 pases seguidos.</p>
        <p><i class="fas fa-check"></i> Luego, 4 contra 2 durante 2 minutos y de nuevo 4 minutos 6 contra 6.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/Pui right-1.png",
          title: "2 CONTRA 2 CON APOYOS EN LOS LATERALES",
          text: `
        <p><i class="fas fa-check"></i> 2 equipos de 4 jugadores.</p>
        <p><i class="fas fa-check"></i> 2 jugadores en el campo, 2 jugadores de apoyo.</p>
        <p><i class="fas fa-check"></i> Cambiar de rol cada minuto, por ejemplo.</p>
        <p><i class="fas fa-check"></i> Por ejemplo, 10 minutos.</p>
        `,
        },
        {
          img: "Cycles/Pui right-2.png",
          title: `INTERMITENTE “MARCHA ADELANTE-ATRÁS”`,
          text: `
        <p><i class="fas fa-check"></i> Colocar 3-4 conos de colores diferentes en un cuadrado delimitado como en la foto. El jugador debe rodear el cono del color que su compañero le anuncia.</p>
        <p><i class="fas fa-check"></i> “verde” y el jugador debe ir hacia el cono verde, rodearlo y regresar rápidamente al inicio… en cuanto el jugador está de nuevo en el centro, su compañero le anuncia un nuevo color… no darle tiempo de descanso...</p>
        <p><i class="fas fa-check"></i> El jugador debe tomar la información (los colores) antes de comenzar para poder seguir muy rápido. </p>
        <p><i class="fas fa-check"></i> Trabajar en parejas (uno que hace el ejercicio y otro que anuncia los colores).</p>
        <p><i class="fas fa-check"></i> 20 segundos de trabajo - 20 segundos de descanso. 7 minutos, por ejemplo.</p>
        <p><i class="fas fa-check"></i> Variar los cuadrados (distancias diferentes, colores colocados de manera diferente…).</p>
        `,
        },
      ],
    },
  },
  FUTSAL_P_U15: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE RESITENCIA-POTENCIA EN EL FÚTBOL SALA `,
      SUBTITLE: `Con 100 ejemplos de ejercicios de resistencia`,
      SUBSUB: "Para las categorías sub15-sub18",
    },
    CHECK: {
      TITLE: "Adaptados a las categorías sub15-sub18",
      CHECKS: [
        `¿A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?`,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la resistencia-potencia en el fútbol sala?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?`,
        `¿Le gustaría conocer más ejercicios para trabajar la resistencia-potencia en el fútbol sala?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
        Le ofrecemos un programa en línea con 
        <span>2 ciclos de 4 semanas de entrenamiento sobre la resistencia-potencia</span>
        y 100 ideas de ejercicios para trabajar la resistencia en el fútbol sala.
      </p>
      <p>
        Esta guía está dirigida a entrenadores de equipos seniors de fútbol sala que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la resistencia-potencia. 
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la velocidad
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de velocidad (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la velocidad)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la velocidad sin equipo o con poco equipo (que todos los clubes pueden tener, todos con balón)
      </p>`,
    APRES: {
      COL1: `<p>
          Encontrará 2 ciclos de entrenamiento físico para trabajar la resistencia-potencia en el fútbol sala. Por ejemplo, usted puede ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad con el fin de sorprender al cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento...)
        </p>`,
      COL2: `<p>
          Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios!
        </p>
        <p>
          Le ofrecemos más de 100 ejemplos de ejercicios para trabajar la resistencia (con y sin balón)
        </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO EN FUTBÓL SALA ESPECIAL "RESISTENCIA" U14 - U18`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/CopiadeCopiadeejer-1_900x.png?v=1624906126",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-de-entrenadores-para-jovenes/products/2-ciclos-de-entrenamiento-fisico-en-futbol-sala-especial-resistencia-u14-u18",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Cycles/Pui left-1.png",
          title: "4 CONTRA 4 CON PORTEROS",
          text: `
        <p><i class="fas fa-check"></i> 4 zonas en el campo completo.</p>
        <p><i class="fas fa-check"></i> Nunca más de 2 jugadores de un mismo equipo en una misma zona./p>
        <p><i class="fas fa-check"></i> Juego libre, 2 minutos de juego, 6 partidos, por ejemplo.</p>
        `,
        },
        {
          img: "Cycles/Pui left-2.png",
          title: "CONSERVACIÓN DEL BALÓN 4 CONTRA 2 Y 6 CONTRA 6",
          text: `
        <p><i class="fas fa-check"></i> Alterne entre conservación del balón en situación de superioridad numérica en un espacio pequeño (4 contra 2) y conservación del balón en un espacio grande 6 contra 6.</p>
        <p><i class="fas fa-check"></i> 2 minutos, 4 contra 2 (los 2 jugadores haciendo presión cambian cada 30 segundos). Se anota 1 punto cada 10 pases seguidos.</p>
        <p><i class="fas fa-check"></i> Se anota un punto cuando el jugador que hace presión recupera el balón. </p>
        <p><i class="fas fa-check"></i> A continuación, directamente, un juego 6 contra 6 durante 4 minutos.</p>
        <p><i class="fas fa-check"></i> Posibilidad de anotar después de 10 pases seguidos.</p>
        <p><i class="fas fa-check"></i> Luego, 4 contra 2 durante 2 minutos y de nuevo 4 minutos 6 contra 6.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/Pui right-1.png",
          title: "2 CONTRA 2 CON APOYOS EN LOS LATERALES",
          text: `
        <p><i class="fas fa-check"></i> 2 equipos de 4 jugadores.</p>
        <p><i class="fas fa-check"></i> 2 jugadores en el campo, 2 jugadores de apoyo.</p>
        <p><i class="fas fa-check"></i> Cambiar de rol cada minuto, por ejemplo.</p>
        <p><i class="fas fa-check"></i> Por ejemplo, 10 minutos.</p>
        `,
        },
        {
          img: "Cycles/Pui right-2.png",
          title: `INTERMITENTE “MARCHA ADELANTE-ATRÁS”`,
          text: `
        <p><i class="fas fa-check"></i> Colocar 3-4 conos de colores diferentes en un cuadrado delimitado como en la foto. El jugador debe rodear el cono del color que su compañero le anuncia.</p>
        <p><i class="fas fa-check"></i> “verde” y el jugador debe ir hacia el cono verde, rodearlo y regresar rápidamente al inicio… en cuanto el jugador está de nuevo en el centro, su compañero le anuncia un nuevo color… no darle tiempo de descanso...</p>
        <p><i class="fas fa-check"></i> El jugador debe tomar la información (los colores) antes de comenzar para poder seguir muy rápido. </p>
        <p><i class="fas fa-check"></i> Trabajar en parejas (uno que hace el ejercicio y otro que anuncia los colores).</p>
        <p><i class="fas fa-check"></i> 20 segundos de trabajo - 20 segundos de descanso. 7 minutos, por ejemplo.</p>
        <p><i class="fas fa-check"></i> Variar los cuadrados (distancias diferentes, colores colocados de manera diferente…).</p>
        `,
        },
      ],
    },
  },
  FUTSAL_V: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE VELOCIDAD EN EL FÚTBOL SALA`,
      SUBTITLE: `Con 100 ejemplos de ejercicios de resistencia y de velocidad`,
      SUBSUB: "Para los seniors",
    },
    CHECK: {
      TITLE: "Adaptados a los seniors",
      CHECKS: [
        `¿A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?`,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la velocidad en el fútbol sala?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?`,
        `¿Le gustaría conocer más ejercicios para trabajar la velocidad en el fútbol sala?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores en ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
            Le ofrecemos un programa en línea con
            <span>2 ciclos de 4 semanas de entrenamiento de la velocidad</span>
            y 100 ideas de ejercicios para trabajar la velocidad en el fútbol sala. 
          </p>
          <p>
            Esta guía está dirigida a entrenadores de equipos de la categoría senior que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la velocidad. 
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la velocidad.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de la velocidad (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la velocidad)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la velocidad sin equipo o con poco equipo (que todos los clubes pueden tener, todos con balón)
          </p>`,
    APRES: {
      COL1: `<p>
      Encontrará 2 ciclos de entrenamiento físico para trabajar la velocidad en el fútbol sala. Por ejemplo, usted puede ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad con el fin de sorprender al cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento...)
          </p>`,
      COL2: `<p>
            Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios!
          </p>
          <p>
            Le ofrecemos más de 100 ejemplos de ejercicios para trabajar la velocidad (con o sin balón)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO EN FUTBÓL SALA ESPECIAL "VELOCIDAD"`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/6_2f8073e0-7c8a-4f9e-8fcc-58c936ae96f8_900x.png?v=1621276904",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-senior/products/2-ciclos-de-entrenamiento-fisico-en-especial-futsal-velocidad",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Cycles/FutV left-1.png",
          title: "VELOCIDAD CON CAMBIO DE DIRECCIÓN Y DUELO",
          text: `
        <p><i class="fas fa-check"></i> Al inicio (anuncio de un color), los jugadores comienzan por el lado del color anunciado (pequeño cuadrado con 4 colores), pasando luego detrás del cono del color anunciado. Terminando con duelo o patada…</p>
        <p><i class="fas fa-check"></i> Usted puede variar los iniciadores (sonoros, visuales,...) para trabajar el tiempo de reacción.</p>
        <p><i class="fas fa-check"></i> También puede variar las posiciones de salida (de lado, de espaldas, trotando,...)</p>
        <p><i class="fas fa-check"></i> Ejemplo: Usted muestra el color amarillo: salida por el lado amarillo y luego pasar por detrás del cono amarillo. </p>
        `,
        },
        {
          img: "Cycles/FutV left-2.png",
          title: "TRABAJO DE APOYOS-FUERZA + REACCIÓN",
          text: `
        <p><i class="fas fa-check"></i> Los jugadores defensores realizan un trabajo de fuerza (saltos con vallas, pliometría) y luego tienen como objetivo impedir que el atacante anote...</p>
        <p><i class="fas fa-check"></i> El atacante realiza una aceleración y luego debe anotar…</p>
        <p><i class="fas fa-check"></i> 2 opciones de recorrido (el entrenador anuncia un color).</p>
        <p><i class="fas fa-check"></i> Usted puede cambiar el trabajo de fuerza por un trabajo de coordinación o de apoyos, o bien un trabajo de frecuencia, o simplemente una aceleración con frenado...</p>
        <p><i class="fas fa-check"></i> Puede variar las distancias, proponer varios recorridos, variar las salidas (detenido, en movimiento, de espaldas, sentado,…)</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutV right-1.png",
          title: "2 CONTRA 2 CON APOYOS EN LOS LATERALES",
          text: `
        <p><i class="fas fa-check"></i> 2 equipo. Cada jugador tiene un número.</p>
        <p><i class="fas fa-check"></i> El entrenador anuncia un número.</p>
        <p><i class="fas fa-check"></i> Los dos jugadores cuyo número ha sido anunciado deben reaccionar rápido y correr hasta el balón (el balón se coloca en el centro o es enviado por el entrenador a lugares distintos del campo en cada nuevo anuncio).</p>
        <p><i class="fas fa-check"></i> El primero en llegar al balón se convierte en atacante y debe ir a detener el balón en la zona de su adversario. El otro jugador se convierte en defensor y debe evitarlo.</p>
        <p><i class="fas fa-check"></i> Variante: el entrenador anuncia 2 o 3 números (2 contra 2, 3 contra 3).</p>
        <p><i class="fas fa-check"></i> Otra variante: el jugador que ataca se convierte automáticamente en defensor (trabajo de transición ataque-defensa).</p>
        `,
        },
        {
          img: "Cycles/FutV right-2.png",
          title: `RÉPÉTITIONS DE SPRINTS`,
          text: `
        <p><i class="fas fa-check"></i> Juego de Pacman.</p>
        <p><i class="fas fa-check"></i> Trazar líneas (por ejemplo, con conos) en una mitad del campo (o en una zona adaptada al número de jugadores disponibles.</p>
        <p><i class="fas fa-check"></i> Los jugadores deben correr siempre a lo largo de una línea.</p>
        `,
        },
      ],
    },
  },
  FUTSAL_V_U15: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE VELOCIDAD EN EL FÚTBOL SALA`,
      SUBTITLE: `Con 100 ejemplos de ejercicios de resistencia y de velocidad`,
      SUBSUB: "Categorías Sub15-Sub18",
    },
    CHECK: {
      TITLE: "Adaptados a las categorías Sub15-Sub18",
      CHECKS: [
        `¿A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?`,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la velocidad en el fútbol sala?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?`,
        `¿Le gustaría conocer más ejercicios para trabajar la velocidad en el fútbol sala?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores en ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
            Le ofrecemos un programa en línea con
            <span>2 ciclos de 4 semanas de entrenamiento de la velocidad</span>
            y 100 ideas de ejercicios para trabajar la velocidad en el fútbol sala. 
          </p>
          <p>
            Esta guía está dirigida a entrenadores de equipos de la categoría senior que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la velocidad. 
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la velocidad.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de la velocidad (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la velocidad)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la velocidad sin equipo o con poco equipo (que todos los clubes pueden tener, todos con balón)
          </p>`,
    APRES: {
      COL1: `<p>
      Encontrará 2 ciclos de entrenamiento físico para trabajar la velocidad en el fútbol sala. Por ejemplo, usted puede ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad con el fin de sorprender al cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento...)
          </p>`,
      COL2: `<p>
            Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios!
          </p>
          <p>
            Le ofrecemos más de 100 ejemplos de ejercicios para trabajar la velocidad (con o sin balón)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO EN FUTBÓL SALA ESPECIAL "VELOCIDAD" U14 - U18`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/7_900x.png?v=1621180587",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-de-entrenadores-para-jovenes/products/2-ciclos-de-entrenamiento-fisico-en-futsal-especial-velocidad-u14-u18",
    },
    SHOP_TITLE: ``,
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Cycles/FutV left-1.png",
          title: "VELOCIDAD CON CAMBIO DE DIRECCIÓN Y DUELO",
          text: `
        <p><i class="fas fa-check"></i> Al inicio (anuncio de un color), los jugadores comienzan por el lado del color anunciado (pequeño cuadrado con 4 colores), pasando luego detrás del cono del color anunciado. Terminando con duelo o patada…</p>
        <p><i class="fas fa-check"></i> Usted puede variar los iniciadores (sonoros, visuales,...) para trabajar el tiempo de reacción.</p>
        <p><i class="fas fa-check"></i> También puede variar las posiciones de salida (de lado, de espaldas, trotando,...)</p>
        <p><i class="fas fa-check"></i> Ejemplo: Usted muestra el color amarillo: salida por el lado amarillo y luego pasar por detrás del cono amarillo. </p>
        `,
        },
        {
          img: "Cycles/FutV left-2.png",
          title: "TRABAJO DE APOYOS-FUERZA + REACCIÓN",
          text: `
        <p><i class="fas fa-check"></i> Los jugadores defensores realizan un trabajo de fuerza (saltos con vallas, pliometría) y luego tienen como objetivo impedir que el atacante anote...</p>
        <p><i class="fas fa-check"></i> El atacante realiza una aceleración y luego debe anotar…</p>
        <p><i class="fas fa-check"></i> 2 opciones de recorrido (el entrenador anuncia un color).</p>
        <p><i class="fas fa-check"></i> Usted puede cambiar el trabajo de fuerza por un trabajo de coordinación o de apoyos, o bien un trabajo de frecuencia, o simplemente una aceleración con frenado...</p>
        <p><i class="fas fa-check"></i> Puede variar las distancias, proponer varios recorridos, variar las salidas (detenido, en movimiento, de espaldas, sentado,…)</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutV right-1.png",
          title: "2 CONTRA 2 CON APOYOS EN LOS LATERALES",
          text: `
        <p><i class="fas fa-check"></i> 2 equipo. Cada jugador tiene un número.</p>
        <p><i class="fas fa-check"></i> El entrenador anuncia un número.</p>
        <p><i class="fas fa-check"></i> Los dos jugadores cuyo número ha sido anunciado deben reaccionar rápido y correr hasta el balón (el balón se coloca en el centro o es enviado por el entrenador a lugares distintos del campo en cada nuevo anuncio).</p>
        <p><i class="fas fa-check"></i> El primero en llegar al balón se convierte en atacante y debe ir a detener el balón en la zona de su adversario. El otro jugador se convierte en defensor y debe evitarlo.</p>
        <p><i class="fas fa-check"></i> Variante: el entrenador anuncia 2 o 3 números (2 contra 2, 3 contra 3).</p>
        <p><i class="fas fa-check"></i> Otra variante: el jugador que ataca se convierte automáticamente en defensor (trabajo de transición ataque-defensa).</p>
        `,
        },
        {
          img: "Cycles/FutV right-2.png",
          title: `RÉPÉTITIONS DE SPRINTS`,
          text: `
        <p><i class="fas fa-check"></i> Juego de Pacman.</p>
        <p><i class="fas fa-check"></i> Trazar líneas (por ejemplo, con conos) en una mitad del campo (o en una zona adaptada al número de jugadores disponibles.</p>
        <p><i class="fas fa-check"></i> Los jugadores deben correr siempre a lo largo de una línea.</p>
        `,
        },
      ],
    },
  },
  GARDIEN_V: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE VELOCIDAD PARA LOS PORTEROS`,
      SUBTITLE: `Con 100 ejemplos de ejercicios de velocidad`,
      SUBSUB: "Para los seniors",
    },
    CHECK: {
      TITLE: "Adaptados a los porteros seniors",
      CHECKS: [
        `A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?`,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la velocidad en los porteros?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?`,
        `¿Le gustaría aprender más ejercicios para trabajar la velocidad en los porteros?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
        Le ofrecemos un programa en línea con <span>2 ciclos de 4 semanas de entrenamiento de velocidad</span> y 100 ideas de ejercicios para trabajar la velocidad.
      </p>
      <p>
      Esta guía está dirigida a los entrenadores de porteros que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la velocidad. 
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la velocidad.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de velocidad (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la coordinación)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la velocidad sin equipo o con poco equipo (que todos los clubes pueden tener, todos con balón)
      </p>`,
    APRES: {
      COL1: `<p>
          Encontrará 2 ciclos (de 4 semanas) de entrenamiento físico para trabajar la velocidad en los porteros. Por ejemplo, usted podría ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad, con el fin de sorprender el cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento...)
        </p>`,
      COL2: `<p>
          Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios! 
        </p>
        <p>
          Le ofrecemos más de 100 ejemplos de ejercicios para trabajar la velocidad (con y sin balón)
        </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE “VELOCIDAD” EN LOS PORTEROS`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/9_900x.png?v=1621181089",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-senior/products/2-ciclos-de-entrenamiento-fisico-para-porteros-especiales-de-velocidad",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Cycles/GardienV left-1.png",
          title: "REACCIÓN, FRECUENCIA Y REACCIÓN",
          text: `
        <p><i class="fas fa-check"></i> El portero comienza con un trabajo de reacción tocando la estaca del color anunciado por el entrenador, seguido de un trabajo de frecuencia (2 apoyos entre los listones)  y luego, debe reaccionar rápido e ir a buscar el balón tirándose hacia el lado del color anunciado.</p>
        <p><i class="fas fa-check"></i> Usted puede variar los iniciadores (sonoros, visuales,...) para trabajar el tiempo de reacción.</p>
        <p><i class="fas fa-check"></i> También puede variar las posiciones de salida (de lado, de espaldas, sentado,…)</p>
        <p><i class="fas fa-check"></i> De 6 a 8 veces. 45 segundos de recuperación entre cada turno.</p>
        `,
        },
        {
          img: "Cycles/GardienV left-2.png",
          title: "VELOCIDAD DE REACCIÓN Y VELOCIDAD DE MOVIMIENTO",
          text: `
        <p><i class="fas fa-check"></i> El portero se encuentra a 1m50 - 2m aproximadamente.</p>
        <p><i class="fas fa-check"></i> Usted tiene un balón en cada mano.</p>
        <p><i class="fas fa-check"></i> Deje caer uno de los 2 balones.</p>
        <p><i class="fas fa-check"></i> El portero debe tocar el balón antes de que toque el suelo.</p>
        <p><i class="fas fa-check"></i> Usted puede anunciar los colores de los conos que el portero debe tocar para desestabilizarlo antes de lanzar un balón.</p>
        <p><i class="fas fa-check"></i> De 8 a 10 veces. 20 segundos de recuperación.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/GardienV right-1.png",
          title: "JUEGOS REDUCIDOS 5 CONTRA 2",
          text: `
        <p><i class="fas fa-check"></i> Colocar conos al azar a unos metros de la portería.</p>
        <p><i class="fas fa-check"></i> El portero comienza con un trabajo de reacción tocando el cono del color anunciado por el entrenador, luego se acomoda delante de la portería y detiene el tiro del entrenador.</p>
        <p><i class="fas fa-check"></i> Patada a ras de tierra, el balón puede tocar un cono y desviar su trayectoria.</p>
        <p><i class="fas fa-check"></i> Unas diez veces.</p>
        <p><i class="fas fa-check"></i> 20 segundos de recuperación entre cada turno.</p>
        `,
        },
        {
          img: "Cycles/GardienV right-2.png",
          title: "PLIOMETRÍA + HACE UNA ESTIRADA",
          text: `
        <p><i class="fas fa-check"></i> 4 saltos (piernas flexionadas) por encima de vallas y luego hacer una estirada horizontal. </p>
        <p><i class="fas fa-check"></i> De 6 a 8 veces. 45 segundos de recuperación.</p>
        <p><i class="fas fa-check"></i> La calidad de los saltos debe ser el objetivo.</p>
        `,
        },
      ],
    },
  },
  GARDIEN_V_U15: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE VELOCIDAD PARA LOS PORTEROS`,
      SUBTITLE: `Con 100 ejemplos de ejercicios de velocidad`,
      SUBSUB: "Categorías Sub15-Sub18",
    },
    CHECK: {
      TITLE: "Adaptados a los porteros Sub15-Sub18",
      CHECKS: [
        `A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?`,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la velocidad en los porteros?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?`,
        `¿Le gustaría aprender más ejercicios para trabajar la velocidad en los porteros?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
        Le ofrecemos un programa en línea con <span>2 ciclos de 4 semanas de entrenamiento de velocidad</span> y 100 ideas de ejercicios para trabajar la velocidad.
      </p>
      <p>
      Esta guía está dirigida a los entrenadores de porteros que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la velocidad. 
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la velocidad.
      </p>
      <p>
        <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de velocidad (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la coordinación)
      </p>
      <p>
        <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la velocidad sin equipo o con poco equipo (que todos los clubes pueden tener, todos con balón)
      </p>`,
    APRES: {
      COL1: `<p>
          Encontrará 2 ciclos (de 4 semanas) de entrenamiento físico para trabajar la velocidad en los porteros. Por ejemplo, usted podría ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad, con el fin de sorprender el cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento...)
        </p>`,
      COL2: `<p>
          Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios! 
        </p>
        <p>
          Le ofrecemos más de 100 ejemplos de ejercicios para trabajar la velocidad (con y sin balón)
        </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE “VELOCIDAD” EN LOS PORTEROS`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/9_900x.png?v=1621181089",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-de-entrenadores-para-jovenes/products/2-ciclos-de-entrenamiento-fisico-para-porteros-especiales-de-velocidad",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Cycles/GardienV left-1.png",
          title: "REACCIÓN, FRECUENCIA Y REACCIÓN",
          text: `
        <p><i class="fas fa-check"></i> El portero comienza con un trabajo de reacción tocando la estaca del color anunciado por el entrenador, seguido de un trabajo de frecuencia (2 apoyos entre los listones)  y luego, debe reaccionar rápido e ir a buscar el balón tirándose hacia el lado del color anunciado.</p>
        <p><i class="fas fa-check"></i> Usted puede variar los iniciadores (sonoros, visuales,...) para trabajar el tiempo de reacción.</p>
        <p><i class="fas fa-check"></i> También puede variar las posiciones de salida (de lado, de espaldas, sentado,…)</p>
        <p><i class="fas fa-check"></i> De 6 a 8 veces. 45 segundos de recuperación entre cada turno.</p>
        `,
        },
        {
          img: "Cycles/GardienV left-2.png",
          title: "VELOCIDAD DE REACCIÓN Y VELOCIDAD DE MOVIMIENTO",
          text: `
        <p><i class="fas fa-check"></i> El portero se encuentra a 1m50 - 2m aproximadamente.</p>
        <p><i class="fas fa-check"></i> Usted tiene un balón en cada mano.</p>
        <p><i class="fas fa-check"></i> Deje caer uno de los 2 balones.</p>
        <p><i class="fas fa-check"></i> El portero debe tocar el balón antes de que toque el suelo.</p>
        <p><i class="fas fa-check"></i> Usted puede anunciar los colores de los conos que el portero debe tocar para desestabilizarlo antes de lanzar un balón.</p>
        <p><i class="fas fa-check"></i> De 8 a 10 veces. 20 segundos de recuperación.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/GardienV right-1.png",
          title: "JUEGOS REDUCIDOS 5 CONTRA 2",
          text: `
        <p><i class="fas fa-check"></i> Colocar conos al azar a unos metros de la portería.</p>
        <p><i class="fas fa-check"></i> El portero comienza con un trabajo de reacción tocando el cono del color anunciado por el entrenador, luego se acomoda delante de la portería y detiene el tiro del entrenador.</p>
        <p><i class="fas fa-check"></i> Patada a ras de tierra, el balón puede tocar un cono y desviar su trayectoria.</p>
        <p><i class="fas fa-check"></i> Unas diez veces.</p>
        <p><i class="fas fa-check"></i> 20 segundos de recuperación entre cada turno.</p>
        `,
        },
        {
          img: "Cycles/GardienV right-2.png",
          title: "PLIOMETRÍA + HACE UNA ESTIRADA",
          text: `
        <p><i class="fas fa-check"></i> 4 saltos (piernas flexionadas) por encima de vallas y luego hacer una estirada horizontal. </p>
        <p><i class="fas fa-check"></i> De 6 a 8 veces. 45 segundos de recuperación.</p>
        <p><i class="fas fa-check"></i> La calidad de los saltos debe ser el objetivo.</p>
        `,
        },
      ],
    },
  },
  AVEC: {
    FIRST: {
      TITLE: `CICLOS DE ENTRENAMIENTO FÍSICO 100% CON BALÓN `,
      SUBTITLE: `Con 300 ejemplos de ejercicios de resistencia y de velocidad`,
      SUBSUB: "Para los seniors",
    },
    CHECK: {
      TITLE: "Adaptados a los seniors",
      CHECKS: [
        `¿A veces le faltan ideas para aportar algo nuevo a sus entrenamientos? `,
        `¿Le gustaría tener ejemplos de ciclos de entrenamientos para trabajar la resistencia-potencia o la velocidad con balón?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en Internet)?`,
        `¿Le gustaría conocer más ejercicios para trabajar la resistencia y la velocidad con balón?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan sus ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
            Le ofrecemos un programa en línea con
            <span>2 ciclos de entrenamientos físicos “100% con balón” </span>
            y 300 ejemplos de ejercicios de resistencia y de velocidad.
          </p>
          <p>
          Esta guía está dirigida a los entrenadores de equipos seniors que buscan ejemplos de ciclos de entrenamientos e ideas de ejercicios para trabajar la resistencia y la velocidad con el balón. 
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Encontrará una parte de “explicaciones” sobre la velocidad. 
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas de trabajo de velocidad (con 2 sesiones por semana o 16 sesiones para trabajar la velocidad)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la velocidad sin equipo o con poco equipo (que todos los clubes pueden tener, y todos con balón). 
          </p>`,
    APRES: {
      COL1: `<p>
            Encontrará 2 ciclos (de 4 semanas) de entrenamientos físicos para trabajar lo físico con el balón. Por ejemplo, usted puede proponer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (a menudo) es necesario aportar variedad y novedad para sorprender al cuerpo (fuente de progreso) y para evitar la monotonía (pérdida de motivación, sobre-entrenamiento,…)
          </p>`,
      COL2: `<p>
      Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios!
          </p>
          <p>
          Le ofrecemos más de 300 ejemplos de ejercicios para trabajar la resistencia y la velocidad (con o sin balón)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO "100% CON BALON"`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/1_1746771e-dcab-43d1-be21-bbc8307311df_900x.png?v=1621267571",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-senior/products/2-ciclos-de-entrenamiento-fisico-100-globo",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Idees/Left1.png",
          title: "JUEGOS REDUCIDOS 1 CONTRA 1",
          text: `
        <p><i class="fas fa-check"></i> En forma de mini-torneo (ascenso-descenso) con evolución de los juegos. </p>
        <p><i class="fas fa-check"></i> Los ganadores suben para terminar en el campo 1. Los perdedores bajan, siendo el último campo el 4 (o el 5 o el 6 según el número de jugadores presentes).</p>
        <p><i class="fas fa-check"></i> Campo 4: Retención del balón (gana el jugador que conserve el balón por más tiempo).</p>
        <p><i class="fas fa-check"></i> Campo 3: Balón detenido en la zona roja.</p>
        <p><i class="fas fa-check"></i> Campo 2: El jugador pasa entre los conos del contrario (rojos o azules).</p>
        <p><i class="fas fa-check"></i> Campo 1: Anotar en la pequeña portería contraria. La intensidad debe ser alta.</p>
        `,
        },
        {
          img: "Idees/Left2.png",
          title: "JUEGOS REDUCIDOS 4 CONTRA 1",
          text: `
        <p><i class="fas fa-check"></i> 5 cuadrados.</p>
        <p><i class="fas fa-check"></i> 4 contra 1 en cada cuadrado.</p>
        <p><i class="fas fa-check"></i> Entre los 12 jugadores amarillos que deben conservar el balón, 4 jugadores (flechas blancas) juegan en 2 cuadrados al mismo tiempo.</p>
        <p><i class="fas fa-check"></i> Estos 4 jugadores nunca deben recibir 2 balones al mismo tiempo.</p>
        <p><i class="fas fa-check"></i> Usted puede variar el tamaño de los cuadrados, dar instrucciones especiales, proponer otros obstáculos,…</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/Avec right-1.png",
          title: "1 CONTRA 1 Y VELOCIDAD DE REACCIÓN",
          text: `
        <p><i class="fas fa-check"></i> 1 contra 1.</p>
        <p><i class="fas fa-check"></i> Objetivo: anotar en el campo del adversario (zona).</p>
        <p><i class="fas fa-check"></i> 2 equipos.</p>
        <p><i class="fas fa-check"></i> Cada jugador tiene un número.</p>
        <p><i class="fas fa-check"></i> El entrenador anuncia un número y envía el balón a una parte del campo. El primer jugador en llegar al balón se convierte en el atacante y tiene que anotar en el campo de su adversario.</p>
        <p><i class="fas fa-check"></i> El otro jugador debe defender.</p>
        `,
        },
        {
          img: "Cycles/Avec right-2.png",
          title: "JUEGOS REDUCIDOS 4 CONTRA 4",
          text: `
        <p><i class="fas fa-check"></i>  3 equipos.</p>
        <p><i class="fas fa-check"></i> Un equipo que ataca (en rojo) que escoge al equipo contra el que va a atacar (contra los blancos o los amarillos).</p>
        <p><i class="fas fa-check"></i> Si anota, ataca al siguiente equipo. Si pierde el balón, pasa a defender y el otro equipo pasa a atacar.</p>
        `,
        },
      ],
    },
  },
  PUISSANCE: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL "POTENCIA AERÓBICA"`,
      SUBTITLE: `Con 200 ejemplos de ejercicios de resistencia`,
      SUBSUB: "Para los seniors",
    },
    CHECK: {
      TITLE: "Adaptados a los seniors",
      CHECKS: [
        `¿A veces le faltan ideas para aportar algo nuevo a sus entrenamientos? `,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la resistencia-potencia?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en Internet)?`,
        `¿Le gustaría aprender más ejercicios para trabajar la resistencia?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan sus ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
            Le ofrecemos un programa en línea con
            <span>2 ciclos de 4 semanas de entrenamiento de la resistencia-potencia</span>
            y 200 ideas de ejercicios para trabajar la resistencia.
          </p>
          <p>
            Esta guía está dirigida a los entrenadores de equipos senior que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la resistencia-potencia. 
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la resistencia
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de resistencia-potencia (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la resistencia)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la resistencia sin equipo o con poco equipo (que todos los clubes pueden tener, todos con balón)
          </p>`,
    APRES: {
      COL1: `<p>
          Encontrará 2 ciclos (de 4 semanas) de entrenamiento físico para trabajar la resistencia-potencia. Por ejemplo, usted podría ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad, con el fin de sorprender el cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-calentamiento...)
          </p>`,
      COL2: `<p>
            Para ello, puede variar las intensidades, los volúmenes... ¡y también los ejercicios!
          </p>
          <p>
            Le ofrecemos más de 200 ejemplos de ejercicios para trabajar la resistencia (con y sin balón)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL "POTENCIA AERÓBICA"`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/7_d236d772-3aec-44f1-a053-b0371f008d1c_900x.png?v=1621277144",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-senior/products/2-ciclos-de-entrenamiento-fisico-especial-poder-de-resistencia",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Idees/Left1.png",
          title: "JUEGOS REDUCIDOS 1 CONTRA 1",
          text: `
        <p><i class="fas fa-check"></i> En forma de mini-torneo (ascenso-descenso) con evolución de los juegos.</p>
        <p><i class="fas fa-check"></i> Los ganadores suben al campo 1. Los perdedores bajan, siendo el 4 el último campo (o el 5 o el 6 según el número de jugadores presentes).</p>
        <p><i class="fas fa-check"></i> Campo 4: Conservación del balón (gana el jugador que conserve más tiempo el balón).</p>
        <p><i class="fas fa-check"></i> Campo 3: pelota detenida en la zona roja.</p>
        <p><i class="fas fa-check"></i> Campo 2: El jugador debe pasar entre los conos contrarios (rojos o azules).</p>
        <p><i class="fas fa-check"></i> Campo 1: Anotar en la pequeña portería del equipo contrario. La intensidad debe ser alta.</p>
        `,
        },
        {
          img: "Idees/Left2.png",
          title: "JUEGOS REDUCIDOS 4 CONTRA 1",
          text: `
        <p><i class="fas fa-check"></i> 5 cuadrados.</p>
        <p><i class="fas fa-check"></i> 4 contra 1 en cada cuadrado.</p>
        <p><i class="fas fa-check"></i> Entre los 12 jugadores que deben conservar el balón, 4 jugadores (flechas blancas) juegan en 2 cuadrados al mismo tiempo.</p>
        <p><i class="fas fa-check"></i> Estos 4 jugadores nunca deben recibir 2 balones al mismo tiempo.</p>
        <p><i class="fas fa-check"></i> Usted puede variar el tamaño de los cuadrados, dar instrucciones especiales, proponer otros obstáculos,…</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutPui right-1.png",
          title: "JUEGOS REDUCIDOS 5 CONTRA 2",
          text: `
        <p><i class="fas fa-check"></i> 3 zonas.</p>
        <p><i class="fas fa-check"></i> El equipo con mayor número de jugadores debe superar las 3 “etapas”  para anotar un punto. </p>
        <p><i class="fas fa-check"></i> El equipo con mayor número de jugadores debe realizar 8 pases antes de poder continuar a la zona siguiente.</p>
        <p><i class="fas fa-check"></i> En cada zona, 2 nuevos jugadores haciendo presión.</p>
        <p><i class="fas fa-check"></i> El equipo con mayor número de jugadores tiene, por ejemplo, 2 minutos para superar las 3 etapas y anotar un punto. </p>
        <p><i class="fas fa-check"></i> Cada vez que se pierde el balón, el equipo comienza desde el principio.</p>
        `,
        },
        {
          img: "Cycles/Avec right-2.png",
          title: "JUEGOS REDUCIDOS 4 CONTRA 4",
          text: `
        <p><i class="fas fa-check"></i> 3 equipos.</p>
        <p><i class="fas fa-check"></i> Un equipo que ataca (en rojo) y elige al equipo al que va a atacar (contra los blancos o los amarillos).</p>
        <p><i class="fas fa-check"></i> Si el equipo anota, ataca al otro equipo. Si pierde el balón, pasa a defender y el otro equipo para al ataque.</p>
        `,
        },
      ],
    },
  },
  PUISSANCE_J: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE RESISTENCIA-POTENCIA`,
      SUBTITLE: `Categorías sub15-sub18`,
      SUBSUB: `Con 200 ejemplos de ejercicios de resistencia`,
    },
    CHECK: {
      TITLE: "Adaptados a las categorías sub15-sub18",
      CHECKS: [
        `¿A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?`,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la resistencia-potencia en las categorías sub15-sub18?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?`,
        `¿Le gustaría aprender más ejercicios para trabajar la resistencia en las categorías sub15-sub18?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
            Le ofrecemos un programa en línea con
            <span>2 ciclos de 4 semanas de entrenamiento de la resistencia-potencia</span>
            y 200 ideas de ejercicios para trabajar la resistencia.
          </p>
          <p>
            Esta guía está dirigida a los entrenadores de equipos de las categorías sub15-sub18 que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la resistencia-potencia. 
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la resistencia
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de resistencia-potencia (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la resistencia)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la resistencia sin equipo o con poco equipo (que todos los clubes pueden tener, todos con balón)
          </p>`,
    APRES: {
      COL1: `<p>
          Encontrará 2 ciclos (de 4 semanas) de entrenamiento físico para trabajar la resistencia-potencia en las categorías sub15-sub18. Por ejemplo, usted podría ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad, con el fin de sorprender el cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-calentamiento...)
          </p>`,
      COL2: `<p>
            Para ello, puede variar las intensidades, los volúmenes... ¡y también los ejercicios!
          </p>
          <p>
            Le ofrecemos más de 200 ejemplos de ejercicios para trabajar la resistencia (con y sin balón)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL "POTENCIA AEROBICA" U15 - U18`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/5_900x.png?v=1621180280",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-de-entrenadores-para-jovenes/products/2-ciclos-de-entrenamiento-fisico-especial-poder-de-resistencia-u15-u18",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Idees/Left1.png",
          title: "JUEGOS REDUCIDOS 1 CONTRA 1",
          text: `
        <p><i class="fas fa-check"></i> En forma de mini-torneo (ascenso-descenso) con evolución de los juegos.</p>
        <p><i class="fas fa-check"></i> Los ganadores suben al campo 1. Los perdedores bajan, siendo el 4 el último campo (o el 5 o el 6 según el número de jugadores presentes).</p>
        <p><i class="fas fa-check"></i> Campo 4: Conservación del balón (gana el jugador que conserve más tiempo el balón).</p>
        <p><i class="fas fa-check"></i> Campo 3: pelota detenida en la zona roja.</p>
        <p><i class="fas fa-check"></i> Campo 2: El jugador debe pasar entre los conos contrarios (rojos o azules).</p>
        <p><i class="fas fa-check"></i> Campo 1: Anotar en la pequeña portería del equipo contrario. La intensidad debe ser alta.</p>
        `,
        },
        {
          img: "Idees/Left2.png",
          title: "JUEGOS REDUCIDOS 4 CONTRA 1",
          text: `
        <p><i class="fas fa-check"></i> 5 cuadrados.</p>
        <p><i class="fas fa-check"></i> 4 contra 1 en cada cuadrado.</p>
        <p><i class="fas fa-check"></i> Entre los 12 jugadores que deben conservar el balón, 4 jugadores (flechas blancas) juegan en 2 cuadrados al mismo tiempo.</p>
        <p><i class="fas fa-check"></i> Estos 4 jugadores nunca deben recibir 2 balones al mismo tiempo.</p>
        <p><i class="fas fa-check"></i> Usted puede variar el tamaño de los cuadrados, dar instrucciones especiales, proponer otros obstáculos,…</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/FutPui right-1.png",
          title: "JUEGOS REDUCIDOS 5 CONTRA 2",
          text: `
        <p><i class="fas fa-check"></i> 3 zonas.</p>
        <p><i class="fas fa-check"></i> El equipo con mayor número de jugadores debe superar las 3 “etapas”  para anotar un punto. </p>
        <p><i class="fas fa-check"></i> El equipo con mayor número de jugadores debe realizar 8 pases antes de poder continuar a la zona siguiente.</p>
        <p><i class="fas fa-check"></i> En cada zona, 2 nuevos jugadores haciendo presión.</p>
        <p><i class="fas fa-check"></i> El equipo con mayor número de jugadores tiene, por ejemplo, 2 minutos para superar las 3 etapas y anotar un punto. </p>
        <p><i class="fas fa-check"></i> Cada vez que se pierde el balón, el equipo comienza desde el principio.</p>
        `,
        },
        {
          img: "Cycles/Avec right-2.png",
          title: "JUEGOS REDUCIDOS 4 CONTRA 4",
          text: `
        <p><i class="fas fa-check"></i> 3 equipos.</p>
        <p><i class="fas fa-check"></i> Un equipo que ataca (en rojo) y elige al equipo al que va a atacar (contra los blancos o los amarillos).</p>
        <p><i class="fas fa-check"></i> Si el equipo anota, ataca al otro equipo. Si pierde el balón, pasa a defender y el otro equipo para al ataque.</p>
        `,
        },
      ],
    },
  },
  COORDINATION: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE COORDINACIÓN`,
      SUBTITLE: `Categorías sub8-sub12`,
      SUBSUB: `Con 200 ejemplos de ejercicios de coordinación-motricidad`,
    },
    CHECK: {
      TITLE: "Adaptados a las categorías sub8-sub12",
      CHECKS: [
        `¿A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?`,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la coordinación en las categorías sub8-sub12?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?`,
        `¿Le gustaría conocer más ejercicios para trabajar la coordinación y la motricidad?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
            Le ofrecemos un programa en línea con 
            <span>2 ciclos de 4 semanas de entrenamiento sobre la coordinación</span>
            y 200 ideas de ejercicios para trabajar la coordinación y la motricidad.
          </p>
          <p>
            Esta guía está dirigida a entrenadores de equipos de las categorías  sub8-sub12 que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la coordinación. 
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la coordinación
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de la coordinación (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la coordinación)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la coordinación sin equipo o con poco equipo (que todos los clubes pueden tener, todos con balón)
          </p>`,
    APRES: {
      COL1: `<p>
            Encontrará 2 ciclos de entrenamiento físico para trabajar la velocidad. Por ejemplo, usted puede ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad con el fin de sorprender al cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento...)
          </p>`,
      COL2: `<p>
            Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios!
          </p>
          <p>
            Le ofrecemos más de 200 ejemplos de ejercicios para trabajar la velocidad (con o sin balón)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL "COORDINACIÓN" U8 - U12`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/1_900x.png?v=1621178480",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-de-entrenadores-para-jovenes/products/2-ciclos-de-entrenamiento-fisico-especial-coordinacion-u8-u12",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Cycles/Coordination left-1.png",
          title: "EJERCICIO DE PASES Y COORDINACIÓN",
          text: `
        <p><i class="fas fa-check"></i> El jugador realiza un pase y va a realizar un ejercicio de coordinación de apoyos; luego, toma el lugar del compañero al que le realizó el pase. 2.</p>
        <p><i class="fas fa-check"></i> Un ejercicio diferente cada vez.</p>
        `,
        },
        {
          img: "Cycles/Coordination left-2.png",
          title: "APRENDIZAJE DEL ESQUEMA CORPORAL",
          text: `
        <p><i class="fas fa-check"></i> Recorrido de motricidad.</p>
        <p><i class="fas fa-check"></i> El jugador comienza con un zigzag  en carrera lateral (cono rojo), seguido de apoyos de pies escalonados en los aros amarillos.</p>
        <p><i class="fas fa-check"></i> Luego, el jugador debe pasar arrastrándose por debajo de una valla.</p>
        <p><i class="fas fa-check"></i> Apoyos de pies en los aros amarillos, salto sobre la valla roja, de nuevo por debajo de la valla y luego por encima de la valla roja.</p>
        <p><i class="fas fa-check"></i> Zigzag en carrera lateral hacia atrás (conos azules) y finalmente pequeños apoyos para rodear los aros verdes.</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/Coordination right-1.png",
          title: "COORDINACIÓN-DISOCIACIÓN OJOS-MIEMBROS INFERIORES",
          text: `
        <p><i class="fas fa-check"></i> 2 jugadores frente a frente (8 a 15m) con un balón cada uno.</p>
        <p><i class="fas fa-check"></i> Objetivo: pases sincronizados (los 2 balones salen al mismo tiempo).</p>
        <p><i class="fas fa-check"></i> El jugador debe mirar siempre al balón y a su compañero.</p>
        `,
        },
        {
          img: "Cycles/Coordination right-2.png",
          title: `CONDUCCIÓN DEL BALÓN Y “ORGANIZACIÓN PERCEPTIVA”`,
          text: `
        <p><i class="fas fa-check"></i> En un cuadrado definido (tamaño en función del número de jugadores). Conducción libre. Prohibido tocar al compañero (y a su balón) ni los conos en el cuadrado.</p>
        <p><i class="fas fa-check"></i> Luego, colocar pie derecho, pie izquierdo, suela… Variar los ritmos (lento, normal y rápido).</p>
        <p><i class="fas fa-check"></i> Al final del ejercicio: los jugadores deben quitarle el balón al compañero sin perder el suyo. El jugador que pierda el balón es eliminado. Gana el último jugador en el campo.</p>
        `,
        },
      ],
    },
  },
  PERIODISATION: {
    FIRST: {
      TITLE: `UN CICLO DE 4 SEMANAS ESPECIAL DE “PERIODIZACIÓN TÁCTICA”`,
      SUBTITLE: `Categorías Sub15 - Sub17 - Seniors`,
      SUBSUB: `Para descubrir este método de entrenamiento`,
    },
    CHECK: {
      TITLE: "Adaptados a las categorías Sub15 – Sub17 - Seniors",
      CHECKS: [
        `¿Le gustaría descubrir la periodización táctica y ver cómo trabajar lo físico con este método de entrenamiento? `,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento de este método de entrenamiento?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en Internet)?`,
        `¿Le gustaría aprender más ejercicios utilizados en la periodización táctica?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan sus ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
            Le ofrecemos un programa en línea con
            <span>un ciclo de 4 semanas de entrenamiento especial de “periodización táctica”.</span>
          </p>
          <p>
            Esta guía está dirigida a entrenadores de equipos de las categorías  Sub15, Sub17 y Seniors que quieran descubrir la periodización táctica (iniciación), que quieran entender cómo trabajar lo físico con este método de entrenamiento y que busquen ejemplos de sesiones de entrenamiento e ideas para ejercicios de periodización táctica.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la periodización táctica.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Un ciclo de 4 semanas (2 o 3 sesiones por semana) sobre la periodización táctica.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Por ejemplo, en una semana con 3 sesiones encontrará una sesión enfocada al trabajo de fuerza, una sesión enfocada al trabajo de resistencia y una sesión enfocada al trabajo de velocidad. 
          </p>
          <p>
            <i class="fas fa-check-circle"></i> También encontrará ideas de ejercicios utilizados en la periodización táctica.
          </p>`,
    APRES: {
      COL1: `<p>
            Encontrará un ciclo (de 4 semanas) de periodización táctica.
          </p>`,
      COL2: `<p>
            También le ofrecemos información, ejemplos de ejercicios y micro-ciclos para descubrir y trabajar con este método de entrenamiento.
            </p>`,
      SUB: `El objetivo principal es darle ideas y ejemplos para que tenga una base sobre la cual ¡diseñar sus propios ciclos y ejercicios! <br /> <br /> Sin embargo, este programa es una iniciación a la periodización táctica, si usted ya es un experto o conoce bien este método, entonces no es para usted ;).`,
    },
    INFOSHOP: {
      title: `UN CICLO ESPECIAL DE 4 SEMANAS "PERIODIZACIÓN TÁCTICA" U15-U18`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/CopiadeCopiadeCopiadeCopiadeejer-1_1_900x.png?v=1624905077",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-de-entrenadores-para-jovenes/products/programa-de-temporada-completa-u16-u17",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Cycles/PLeft-1.png",
          title: "JUEGOS REDUCIDOS 8 CONTRA 4",
          text: `
        <p><i class="fas fa-check"></i> 3 equipos de 4 jugadores.</p>
        <p><i class="fas fa-check"></i> 2 zonas de 15m x 15m, por ejemplo (adaptar según la categoría y el nivel de su grupo).</p>
        <p><i class="fas fa-check"></i> Un equipo “suplente”' (aquí, el azul), que juega con el equipo que tiene el balón, con un jugador a cada lado de una zona..</p>
        <p><i class="fas fa-check"></i> El equipo que tiene el balón debe hacer 6 pases consecutivos (por ejemplo), pasar a la siguiente zona y volver a hacer 6 pases consecutivos para anotar un punto.</p>
        <p><i class="fas fa-check"></i> Cuando hay un cambio de zona, los jugadores suplentes también se deslizan para apoyar en la otra zona. </p>
        <p><i class="fas fa-check"></i> El objetivo táctico aquí es trabajar la conservación del balón en situación de superioridad numérica (8 contra 4) y trabajar la progresión de una zona a otra.</p>
        `,
        },
        {
          img: "Cycles/PLeft-2.png",
          title: "JUEGOS REDUCIDOS 7 CONTRA 7",
          text: `
        <p><i class="fas fa-check"></i> Juegos reducidos para trabajar la potencia aeróbica y el bloqueo en equipo.</p>
        <p><i class="fas fa-check"></i> Por ejemplo, en medio campo.</p>
        <p><i class="fas fa-check"></i> Trazar varias zonas (aquí 4).</p>
        <p><i class="fas fa-check"></i> Un equipo debe tener siempre a todos sus jugadores en 2 zonas juntas. </p>
        <p><i class="fas fa-check"></i> Si un jugador de un equipo está en otra zona, el equipo pierde el balón. </p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/PRight-1.png",
          title: "TRABAJO DE FUERZA - CABEZAZOS (OFENSIVA Y DEFENSIVA)",
          text: `
        <p><i class="fas fa-check"></i> 4 contra 4 en un campo pequeño con 2 jugadores en apoyo para centrar. </p>
        <p><i class="fas fa-check"></i> Gol de cabezazo, válido sólo en la zona y tras un centro.</p>
        <p><i class="fas fa-check"></i> El equipo que tiene el balón debe realizar al menos 8 pases antes de poder anotar. </p>
        <p><i class="fas fa-check"></i> 8 minutos.</p>
        <p><i class="fas fa-check"></i> Cambiar los roles cada 2 minutos.</p>
        `,
        },
        {
          img: "Cycles/PRight-2.png",
          title: `VELOCIDAD EN 3 CONTRA 2`,
          text: `
        <p><i class="fas fa-check"></i> Salida en el centro del campo para los atacantes y en la línea de meta para los defensores.</p>
        <p><i class="fas fa-check"></i> Máximo 10 segundos para anotar (por ejemplo).</p>
        <p><i class="fas fa-check"></i> 3-4 turnos por jugador en los 2 roles.</p>
        <p><i class="fas fa-check"></i> Mínimo 45 segundos de recuperación entre 2 carreras.</p>
        `,
        },
      ],
    },
  },
  SPECIAL_V: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTOS FÍSICOS ESPECIAL DE VELOCIDAD`,
      SUBTITLE: `Con 200 ejemplos de ejercicios de velocidad`,
      SUBSUB: "Para los seniors",
    },
    CHECK: {
      TITLE: "Adaptados a los seniors",
      CHECKS: [
        `¿A veces le faltan ideas para aportar algo nuevo a sus entrenamientos? `,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la velocidad?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en Internet)?`,
        `¿Le gustaría aprender más ejercicios para trabajar la velocidad?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?`,
      ],
    },
    SEMAINE: `<p>
            Le ofrecemos un programa en línea con <span>2 ciclos de 4 semanas de entrenamiento de velocidad</span> ey 200 ideas de ejercicios para trabajar la velocidad. <br />
            Esta guía está dirigida a entrenadores de equipos seniors que buscan ejemplos de entrenamiento e ideas de ejercicios para trabajar la velocidad. 
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la velocidad.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de velocidad (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la velocidad)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la velocidad sin equipo o con poco equipo (que todos los clubes pueden tener, y todos con balón)
          </p>`,
    APRES: {
      COL1: `<p>
            Encontrará 2 ciclos de entrenamiento físico para trabajar la velocidad. Por ejemplo, usted puede ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad con el fin de sorprender al cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento...)
          </p>`,
      COL2: `<p>
            Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios!
          </p>
          <p>
            Le ofrecemos más de 200 ejemplos de ejercicios para trabajar la velocidad (con o sin balón)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL "VELOCIDAD"`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/3_d54185f0-68aa-49a0-ba9f-7cd14da1c8c7_900x.png?v=1621269875",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-senior/products/2-ciclos-de-entrenamiento-fisico-especial-velocidad",
    },
    EXERCICES: {
      TITLE: `Exemples d'exercices retrouvés dans les programmes`,
      SUBTITLE: `Voici des exemples d'exercices physiques sous format 3D que vous retrouverez dans le guide en ligne.`,
      EXERCICESL: [{
          img: "Cycles/SpecialV left-1.png",
          title: "VELOCIDAD DE REACCIÓN Y VELOCIDAD CON CAMBIOS DE DIRECCIÓN",
          text: `
        <p><i class="fas fa-check"></i> 2 equipos</p>
        <p><i class="fas fa-check"></i> 2 cuadrados. Cada jugador de un equipo tiene un número.</p>
        <p><i class="fas fa-check"></i> 2 conos de colores a cada lado de un cuadrado...</p>
        <p><i class="fas fa-check"></i> El entrenador anuncia un color (que corresponde a un recorrido, los jugadores deben rodear los 2 conos del color anunciado) y un número que corresponde a un jugador de cada equipo. </p>
        <p><i class="fas fa-check"></i> Los jugadores deben reaccionar rápido al anuncio de su número, rodear los conos y regresar al punto de partida.</p>
        `,
        },
        {
          img: "Cycles/SpecialV left-2.png",
          title: "VELOCIDAD DE REACCIÓN",
          text: `
        <p><i class="fas fa-check"></i> 4 conos de colores diferentes, el entrenador anuncia 2 colores. El jugador debe reaccionar rápido a la señal, rodear los 2 conos del color anunciado y patear a las pequeñas porterías.</p>
        <p><i class="fas fa-check"></i> En forma de duelo.</p>
        <p><i class="fas fa-check"></i> Obtiene un punto el jugador que patee primero.</p>
        <p><i class="fas fa-check"></i> Si anota, obtiene 3 puntos.</p>
        <p><i class="fas fa-check"></i> El otro jugador que pateó de segundo, no puede anotar ningún punto. Recuperación de 45 minutos mínimo. </p>
        <p><i class="fas fa-check"></i> Usted puede variar las distancias, anunciar sólo un color o 3 colores, variar las salidas (detenido, en movimiento, de espaldas, sentados…), variar el final...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/SpecialV right-1.png",
          title: "Velocidad y finalización",
          text: `
        <p><i class="fas fa-check"></i> 3 grupos, uno al centro del campo (en rojo), otro a un lado (en blanco) y otro cerca de la portería (en amarillo).</p>
        <p><i class="fas fa-check"></i> 3 cuadrados cerca de la portería: uno rojo, otro azul y otro amarillo.</p>
        <p><i class="fas fa-check"></i> Un jugador del grupo blanco debe correr con el balón y centrar.</p>
        <p><i class="fas fa-check"></i> Debe centrar para uno de los dos jugadores rojos (anunciado antes de salir)…</p>
        <p><i class="fas fa-check"></i> El jugador amarillo debe correr rápido para colocarse en uno de los 2 cuadrados.</p>
        <p><i class="fas fa-check"></i> Los jugadores rojos deben correr rápido e ir a los otros cuadrados.</p>
        <p><i class="fas fa-check"></i> El jugador blanco debe tomar la información antes de centrar para saber en cuál cuadrado estará el jugador rojo. </p>
        <p><i class="fas fa-check"></i> Los jugadores que han centrado y pateado vuelven caminando.</p>
        `,
        },
        {
          img: "Cycles/SpecialV right-2.png",
          title: "1 CONTRA 1 SEGUIDO DE PATADA",
          text: `
        <p><i class="fas fa-check"></i> Los atacantes deben driblar a un defensor antes de poder patear.</p>
        <p><i class="fas fa-check"></i> Si el defensor recupera el balón, puede patear.</p>
        <p><i class="fas fa-check"></i> Establezca un límite de tiempo de 6 a 10 segundos para el duelo.</p>
        <p><i class="fas fa-check"></i> 3-4 veces en los 2 roles. 45 segundos de recuperación entre 2 turnos.</p>
        `,
        },
      ],
    },
  },
  VITESSE_J_U8: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE VELOCIDAD`,
      SUBTITLE: `Categorías Sub8-Sub12`,
      SUBSUB: `Con 200 ejemplos de ejercicios de velocidad`,
    },
    CHECK: {
      TITLE: "Adaptados a las categorías Sub8-Sub12",
      CHECKS: [
        `¿A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?`,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la velocidad en las categorías Sub8-Sub12?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?`,
        `¿Le gustaría aprender más ejercicios para trabajar la velocidad?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?
        `,
      ],
    },
    SEMAINE: `<p>
            Le ofrecemos un programa en línea con
            <span>2 ciclos de 4 semanas de entrenamiento de velocidad</span>
            y 200 ideas de ejercicios para trabajar la velocidad.
          </p>
          <p>
          Esta guía está dirigida a entrenadores de equipos de las categorías Sub8-Sub12 que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la velocidad.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la coordinación
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de coordinación (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la coordinación)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la coordinación sin equipo o poco equipo (que todos los clubes pueden tener, y todos con balón)
          </p>`,
    APRES: {
      COL1: `<p>
            Encontrará 2 ciclos de entrenamiento físico para trabajar la velocidad en las categorías Sub8-Sub12. Por ejemplo, usted podría ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad, con el fin de sorprender al cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento…)
          </p>`,
      COL2: `<p>
            Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios!
          </p>
          <p>
            Le ofrecemos más de 200 ejemplos de ejercicios para trabajar la velocidad (con y sin balón)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE “VELOCIDAD” PARA LAS CATEGORÍAS SUB8 - SUB12`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/2_900x.png?v=1621178827",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-de-entrenadores-para-jovenes/products/2-ciclos-de-entrenamiento-fisico-especial-velocidad-u8-u12",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Cycles/JeunesV left-1.png",
          title: "VELOCIDAD DE REACCIÓN Y VELOCIDAD CON CAMBIOS DE DIRECCIÓN",
          text: `
        <p><i class="fas fa-check"></i> 2 equipos</p>
        <p><i class="fas fa-check"></i> 2 cuadrados</p>
        <p><i class="fas fa-check"></i> Cada jugador de un equipo tiene un número.</p>
        <p><i class="fas fa-check"></i> 2 conos de colores a cada lado de un cuadrado. El entrenador anuncia un color (que corresponde al recorrido, los jugadores deben rodear los 2 conos del color anunciado) y un número que corresponde a un jugador de cada equipo.</p>
        <p><i class="fas fa-check"></i> Los jugadores deben reaccionar rápido al anuncio de su número, rodear los conos y regresar al punto de partida.</p>
        `,
        },
        {
          img: "Cycles/JeunesV left-2.png",
          title: "VELOCIDAD DE REACCIÓN",
          text: `
        <p><i class="fas fa-check"></i> 4 conos de colores diferentes, el entrenador anuncia 2 colores. El jugador debe reaccionar rápido a la señal, rodear los 2 conos del color anunciado y patear a las pequeñas porterías.</p>
        <p><i class="fas fa-check"></i> En forma de duelo.</p>
        <p><i class="fas fa-check"></i> Obtiene un punto el jugador que patee primero.</p>
        <p><i class="fas fa-check"></i> Si anota, obtiene 3 puntos.</p>
        <p><i class="fas fa-check"></i> El otro jugador que pateó de segundo no puede anotar ningún punto. Recuperación de 45 segundos mínimo.</p>
        <p><i class="fas fa-check"></i> Usted puede variar las distancias, anunciar sólo un color o 3 colores, variar las salidas (detenido, en movimiento, de espalda, sentados...), variar el final...</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/JeunesV right-1.png",
          title: "VELOCIDAD DE REACCIÓN",
          text: `
        <p><i class="fas fa-check"></i> En forma de duelo (o 3 o 4 jugadores).</p>
        <p><i class="fas fa-check"></i> Un cuadrado central.</p>
        <p><i class="fas fa-check"></i> 4 puertas de colores diferentes.</p>
        <p><i class="fas fa-check"></i> 4 balones (cada uno frente a una puerta).</p>
        <p><i class="fas fa-check"></i> Los jugadores trotan dentro del cuadrado central.</p>
        <p><i class="fas fa-check"></i> El entrenador anuncia un color (el color azul, por ejemplo), los jugadores deben reaccionar rápido, tomar el balón y pasar por la puerta del color anunciado. </p>
        <p><i class="fas fa-check"></i> El primero en llegar al balón, lo toma; el segundo debe impedirle pasar la puerta con el balón.</p>
        `,
        },
        {
          img: "Cycles/JeunesV right-2.png",
          title: `1 CONTRA 1 SEGUIDO DE PATADA`,
          text: `
        <p><i class="fas fa-check"></i> Los atacantes deben driblar a un defensor antes de poder anotar.</p>
        <p><i class="fas fa-check"></i> Si el defensor recupera el balón, puede patear.</p>
        <p><i class="fas fa-check"></i> Establezca un límite de tiempo de 6 a 10 segundos para el duelo.</p>
        <p><i class="fas fa-check"></i> 3-4 veces en los 2 roles. 45 segundos de recuperación entre 2 turnos.</p>
        `,
        },
      ],
    },
  },
  VITESSE_J_U12: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE VELOCIDAD`,
      SUBTITLE: `Categorías Sub12-Sub14`,
      SUBSUB: `Con 200 ejemplos de ejercicios de velocidad`,
    },
    CHECK: {
      TITLE: "Adaptados a las categorías Sub12-Sub14",
      CHECKS: [
        `¿A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?`,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la velocidad en las categorías Sub12-Sub14?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?`,
        `¿Le gustaría aprender más ejercicios para trabajar la velocidad?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?
        `,
      ],
    },
    SEMAINE: `<p>
            Le ofrecemos un programa en línea con
            <span>2 ciclos de 4 semanas de entrenamiento de velocidad</span>
            y 200 ideas de ejercicios para trabajar la velocidad.
          </p>
          <p>
          Esta guía está dirigida a entrenadores de equipos de las categorías Sub12-Sub14 que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la velocidad.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la coordinación
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de coordinación (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la coordinación)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la coordinación sin equipo o poco equipo (que todos los clubes pueden tener, y todos con balón)
          </p>`,
    APRES: {
      COL1: `<p>
            Encontrará 2 ciclos de entrenamiento físico para trabajar la velocidad en las categorías Sub12-Sub14. Por ejemplo, usted podría ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad, con el fin de sorprender al cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento…)
          </p>`,
      COL2: `<p>
            Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios!
          </p>
          <p>
            Le ofrecemos más de 200 ejemplos de ejercicios para trabajar la velocidad (con y sin balón)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS ESPECIALES DE ENTRENAMIENTO FÍSICO "VELOCIDAD" U12 - U14`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/3_900x.png?v=1621179175",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-de-entrenadores-para-jovenes/products/2-ciclos-especiales-de-entrenamiento-fisico-velocidad-u12-u14",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
        img: "Cycles/JeunesV left-1.png",
        title: "Velocidad de reacción y cambios de dirección",
        text: `
      <p><i class="fas fa-check"></i> 2 equipos.</p>
      <p><i class="fas fa-check"></i> 2 cuadrados.</p>
      <p><i class="fas fa-check"></i> Cada jugador de un equipo tiene un número.</p>
      <p><i class="fas fa-check"></i> 2 conos de colores a cada lado de los cuadrados.. 
      El entrenador anuncia un color (que corresponde al curso, los
      jugadores deben rodear los 2 conos del color indicado) y un
      número que corresponda a un jugador de cada equipo.</p>
      <p><i class="fas fa-check"></i> Los jugadores deben reaccionar rápido al anunciarse su
      número, rodear los conos y regresar al punto de partida.</p>
      `,
      },
      {
        img: "Cycles/JeunesV left-2.png",
        title: "Velocidad de reacción",
        text: `
      <p><i class="fas fa-check"></i> 4 conos de diferentes colores, el entrenador anuncia 2 colores. 
      El jugador debe reaccionar rápidamente a la señal, pasar por alto los 2 conos del color anunciado y luego golpear las pequeñas jaulas</p>
      <p><i class="fas fa-check"></i> En reto</p>
      <p><i class="fas fa-check"></i> El jugador que golpea primero tiene un punto.</p>
      <p><i class="fas fa-check"></i> Si anota, tiene 3 puntos.</p>
      <p><i class="fas fa-check"></i> El otro jugador que golpeó en segundo lugar no puede anotar
      ningún punto. Recuperación en 45 segundos mínimo.</p>
      <p><i class="fas fa-check"></i> Usted puede variar las distancias, anunciar solo un color o 3
      colores, variar las salidas (parado, en movimiento, de
      espaldas, sentado...), variar el final…</p>
      `,
      },
    ],
    EXERCICESR: [{
        img: "Cycles/JeunesV14 right-es-1.png",
        title: "Velocidad de reacción y agilidad",
        text: `
        <p><i class="fas fa-check"></i> 2 grupos de jugadores cada uno en una zona (aquí 4 jugadores por zona).</p>
        <p><i class="fas fa-check"></i> Cada jugador tiene un número (aquí de 1 a 4).</p>
        <p><i class="fas fa-check"></i> Colocar conos de colores diferentes (aquí rojo y amarillo).</p>
        <p><i class="fas fa-check"></i> El entrenador anuncia un color de cono (aquí rojo) que los
        jugadores deben rodear y un número (aquí el 2) que
        corresponde a un jugador de cada grupo.</p>
        <p><i class="fas fa-check"></i> Los jugadores deben reaccionar rápidamente, rodear los conos
        del color anunciado, enfrentarse en duelo e intentar anotar.</p>
        <p><i class="fas fa-check"></i> Por ejemplo, 4-5 rondas por cada jugador.</p>
        <p><i class="fas fa-check"></i> Variante: el entrenador anuncia 2 números (es decir, 2
          jugadores de cada grupo), usted puede cambiar los recorridos
          (más colores, más obstáculos...).</p>
        `,
        },
        {
          img: "Cycles/JeunesV12 right-2.png",
          title: `VELOCIDAD`,
          text: `
        <p><i class="fas fa-check"></i> Cada jugador tiene un aro.</p>
        <p><i class="fas fa-check"></i> El entrenador quita un aro antes de cada nuevo comienzo.</p>
        <p><i class="fas fa-check"></i> Los jugadores deben salir de su aro, rodear el maniquí (o estaca) del color anunciado por el entrenador y encontrar un nuevo aro libre.</p>
        <p><i class="fas fa-check"></i> Se elimina un jugador en cada juego.</p>
        `,
        },
      ],
    },
  },
  VITESSE_J_U14: {
    FIRST: {
      TITLE: `2 CICLOS DE ENTRENAMIENTO FÍSICO ESPECIAL DE VELOCIDAD`,
      SUBTITLE: `Categorías Sub14-Sub18`,
      SUBSUB: `Con 200 ejemplos de ejercicios de velocidad`,
    },
    CHECK: {
      TITLE: "Adaptados a las categorías Sub14-Sub18",
      CHECKS: [
        `¿A veces le faltan ideas para aportar novedad a sus sesiones de entrenamiento?`,
        `¿Le gustaría tener ejemplos de ciclos de entrenamiento para trabajar la velocidad en las categorías Sub14-Sub18?`,
        `¿Le gustaría ahorrar tiempo en la creación de sus sesiones (y no pasar horas buscando nuevos ejercicios en internet)?`,
        `¿Le gustaría aprender más ejercicios para trabajar la velocidad?`,
        `¿Le gustaría ver más motivación y progreso en sus jugadores cuando realizan ejercicios físicos?
        `,
      ],
    },
    SEMAINE: `<p>
            Le ofrecemos un programa en línea con
            <span>2 ciclos de 4 semanas de entrenamiento de velocidad</span>
            y 200 ideas de ejercicios para trabajar la velocidad.
          </p>
          <p>
          Esta guía está dirigida a entrenadores de equipos de las categorías Sub14-Sub18 que buscan ejemplos de ciclos de entrenamiento e ideas de ejercicios para trabajar la velocidad.
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Comprende una parte de “explicaciones” sobre la coordinación
          </p>
          <p>
            <i class="fas fa-check-circle"></i> 2 ciclos de 4 semanas sobre el trabajo de coordinación (con 2 sesiones por semana, es decir, 16 sesiones para trabajar la coordinación)
          </p>
          <p>
            <i class="fas fa-check-circle"></i> Ideas de ejercicios para trabajar la coordinación sin equipo o poco equipo (que todos los clubes pueden tener, y todos con balón)
          </p>`,
    APRES: {
      COL1: `<p>
            Encontrará 2 ciclos de entrenamiento físico para trabajar la velocidad en las categorías Sub14-Sub18. Por ejemplo, usted podría ofrecer el primer ciclo en la primera parte de la temporada y el segundo ciclo en la segunda parte de la temporada. Para progresar, a veces (o a menudo) es necesario aportar variedad y novedad, con el fin de sorprender al cuerpo (fuente de progreso) y de evitar la monotonía (pérdida de motivación, sobre-entrenamiento…)
          </p>`,
      COL2: `<p>
            Para ello, usted puede variar las intensidades, los volúmenes,… ¡y también los ejercicios!
          </p>
          <p>
            Le ofrecemos más de 200 ejemplos de ejercicios para trabajar la velocidad (con y sin balón)
          </p>`,
    },
    INFOSHOP: {
      title: `2 CICLOS ESPECIALES DE ENTRENAMIENTO FÍSICO "VELOCIDAD" U14 - U16`,
      img:
        "https://cdn.shopify.com/s/files/1/0566/8811/5880/products/4_900x.png?v=1621180034",
      link:
        "https://preparacionfisicafutbol-tienda.com/collections/programas-de-entrenadores-para-jovenes/products/2-ciclos-especiales-de-entrenamiento-fisico-velocidad-u14-u16",
    },
    EXERCICES: {
      TITLE: `EJERCICIOS FÍSICOS EN FORMATO 3D`,
      SUBTITLE: `Estos son algunos ejemplos de los ejercicios físicos que encontrará en el programa en línea.`,
      EXERCICESL: [{
          img: "Cycles/JeunesV left-1.png",
          title: "Velocidad de reacción y cambios de dirección",
          text: `
        <p><i class="fas fa-check"></i> 2 equipos.</p>
        <p><i class="fas fa-check"></i> 2 cuadrados.</p>
        <p><i class="fas fa-check"></i> Cada jugador de un equipo tiene un número.</p>
        <p><i class="fas fa-check"></i> 2 conos de colores a cada lado de los cuadrados.. 
        El entrenador anuncia un color (que corresponde al curso, los
        jugadores deben rodear los 2 conos del color indicado) y un
        número que corresponda a un jugador de cada equipo.</p>
        <p><i class="fas fa-check"></i> Los jugadores deben reaccionar rápido al anunciarse su
        número, rodear los conos y regresar al punto de partida.</p>
        `,
        },
        {
          img: "Cycles/JeunesV left-2.png",
          title: "Velocidad de reacción",
          text: `
        <p><i class="fas fa-check"></i> 4 conos de diferentes colores, el entrenador anuncia 2 colores. 
        El jugador debe reaccionar rápidamente a la señal, pasar por alto los 2 conos del color anunciado y luego golpear las pequeñas jaulas</p>
        <p><i class="fas fa-check"></i> En reto</p>
        <p><i class="fas fa-check"></i> El jugador que golpea primero tiene un punto.</p>
        <p><i class="fas fa-check"></i> Si anota, tiene 3 puntos.</p>
        <p><i class="fas fa-check"></i> El otro jugador que golpeó en segundo lugar no puede anotar
        ningún punto. Recuperación en 45 segundos mínimo.</p>
        <p><i class="fas fa-check"></i> Usted puede variar las distancias, anunciar solo un color o 3
        colores, variar las salidas (parado, en movimiento, de
        espaldas, sentado...), variar el final…</p>
        `,
        },
      ],
      EXERCICESR: [{
          img: "Cycles/JeunesV14 right-es-1.png",
          title: "Velocidad de reacción y agilidad",
          text: `
        <p><i class="fas fa-check"></i> 2 grupos de jugadores cada uno en una zona (aquí 4 jugadores por zona).</p>
        <p><i class="fas fa-check"></i> Cada jugador tiene un número (aquí de 1 a 4).</p>
        <p><i class="fas fa-check"></i> Colocar conos de colores diferentes (aquí rojo y amarillo).</p>
        <p><i class="fas fa-check"></i> El entrenador anuncia un color de cono (aquí rojo) que los
        jugadores deben rodear y un número (aquí el 2) que
        corresponde a un jugador de cada grupo.</p>
        <p><i class="fas fa-check"></i> Los jugadores deben reaccionar rápidamente, rodear los conos
        del color anunciado, enfrentarse en duelo e intentar anotar.</p>
        <p><i class="fas fa-check"></i> Por ejemplo, 4-5 rondas por cada jugador.</p>
        <p><i class="fas fa-check"></i> Variante: el entrenador anuncia 2 números (es decir, 2
          jugadores de cada grupo), usted puede cambiar los recorridos
          (más colores, más obstáculos...).</p>
        `,
        },
        {
          img: "Cycles/JeunesV right-2.png",
          title: `1 contra 1 y luego golpear`,
          text: `
        <p><i class="fas fa-check"></i> Los atacantes deben driblar a un defensor antes de que
        puedan disparar.</p>
        <p><i class="fas fa-check"></i> Si el defensor recibe el balón, puede ir y patear.</p>
        <p><i class="fas fa-check"></i> Establezca un límite de tiempo de 6 a 10 segundos para el
        duelo.</p>
        <p><i class="fas fa-check"></i> 3-4 veces en ambos roles. 45 segundos de recuperación entre 2 pasajes.</p>
        `,
        },
      ],
    },
  },
  MODAL_LINKS: {
    GUIDE_JEUNES: 'https://2fc70f3b.sibforms.com/serve/MUIEAA56G0-opKaSRNiHaTb5s8wOUYNq_n_yNWdf6JpWUHKg5M2J2eaKCeI62rRlxBGEc5Nkq4WkX4tmoTHIuiijESsz-RpBT7RcOie0w1GxitYyraH50GGPnDxo_9kOhgQBaRLT53OMjGKEbTBqulCwsrX-S8FBl-q2rfswFvK_oOYUcldZZ0uftrycah6NmRbgzunB6IIhroBe',
    SAISONS_J: 'https://2fc70f3b.sibforms.com/serve/MUIEAK42unc8lYhY_vh3aVmRJ4he6jv0E_AS8U9bUVdtvcSYMsJLJrLHqUdp9kOK1YB4g1O0HrwxCWmmRpaXzaDAktMrg6vJhTbnilxE5GVMcorycQXweJNtec4QYwlvjEXE7ObKzkLnznrFODv4shLYPV1-FwOUZEAHX6Gymc44ver1a-oFjsTNRqQ3LinRss25PvRRPd_SGTIF',
    SAISONS_SS: 'https://2fc70f3b.sibforms.com/serve/MUIEAK42unc8lYhY_vh3aVmRJ4he6jv0E_AS8U9bUVdtvcSYMsJLJrLHqUdp9kOK1YB4g1O0HrwxCWmmRpaXzaDAktMrg6vJhTbnilxE5GVMcorycQXweJNtec4QYwlvjEXE7ObKzkLnznrFODv4shLYPV1-FwOUZEAHX6Gymc44ver1a-oFjsTNRqQ3LinRss25PvRRPd_SGTIF',
    EXERCICES: 'https://2fc70f3b.sibforms.com/serve/MUIEABUp-LWqf3KXpG7hEmMLbqoQRcA2BbgwkCxKu-qM22bKPGkhzfqxAAcMZYDQip99ewpLkyudWleB3QrRJVb6LmWLZP_R-RdXDoOdBahqS9fiFAG5ThGlYxwwCtrUKYPyH3owyHYEPHl2gIXTed7dBrqXki4F0oy_6trDe6afFRxWIa3Gw0_8K4mWRrFyMuthx6AS6CUfA3JY',
    FORMATION: 'https://2fc70f3b.sibforms.com/serve/MUIEAE5InBegKL-0tdmEYtaAU6vQw5na4PEEnlJrgazDo5PdEX_MoQfUe79BJtamv_nf8ARd1g_bBrR3Q0ru-pw8RcAr3o0R5T2c6xMWeJLRirjSzzjTdb6fIysoq-cmbblRVe2t264cfbpG8NDS1zvipaohFvQhATUEF56xMoTNLYTQIpfbBZBfjLVKx_vSNJl_XmfLGW3Y8J1n',
    COACHS: 'https://2fc70f3b.sibforms.com/serve/MUIEAMxw1ggcwkO-TTFJZ98nI0Z6Rdg4X8EXPwfYC9YVBXbqRCMJP7DLzRYKkI1rg1i5DzNb0XV7rprN2iP-dvHtgdMGiX-vprae1_d8paqmwR4CoD_wmRbYX4q9MCfzHq0qNX1Nd9dKsD5IeGz66YMWB1GGeFZT8VrUW4z_3UPKXvUX6TlpDYbLXL2CBHImjlWBT5aJu6nvvYY0',
    TREVE: 'https://preparacionfisicafutbol-tienda.com/collections/programas-senior/products/programa-pre-temporada-seniors',
  },
  BLOG_LINK: 'https://www.preparacionfisicafutbol.com/blog',
  CHANGE_P: 'al programa',
  CHANGE_G: 'a la guía',
  GARANTIE_IMG: 'garantia.png',
  PAY_IMG: 'payment.png',
  DISPONIBLE: 'DISPONIBLE PRONTO',
};