export default {
    ["/coach-senior"]                                                 : "/coach-senior",
    ["/coach-jeunes"]                                                 : "/coach-youth",
    ["/espace-membres"]                                               : "/members-area",
    ["/blog/category"]                                                : "/blog/category",
    ["/guide-800-exercices"]                                          : "/guide-800-exercises",
    ["/300-exercices-app"]                                            : "/300-exercises-app",
    ["/seances-coach-jeunes"]                                         : "/sessions-coach-youth",
    ["/seances-coach-seniors"]                                        : "/sessions-coach-seniors",
    ["/coach-de-senior"]                                              : "/senior-coach",
    ["/coach-de-jeunes"]                                              : "/youth-coach",
    ["/contact"]                                                      : "/contact",
    ["/preparation-physique-coach"]                                   : "/physical-preparation-coach",
    ["/programmes"]                                                   : "/programs",
    ["/programmes/treve-hivernale"]                                   : "/programs/winter-break",
    ["/programmes/treve-estivale-coach"]                              : "/programs/summer-break-coach",
    ["/guide/200-exercices-jeux-reduits"]                             : "/guide/200-exercises-reduced-games",
    ["/guide/management"]                                             : "/guide/management",
    ["/cycle-entrainement/seniors/100-pour-100-ballon"]               : "/cycle-training/adult/100-for-100-with-ball",
    ["/cycle-entrainement/seniors/vitesse"]                           : "/cycle-training/adult/speed",
    ["/cycle-entrainement/seniors/vitesse-gardien"]                   : "/cycle-training/adult/speed-goalkeeper",
    ["/cycle-entrainement/seniors/vitesse-gardien-U15-U18"]           : "/cycle-training/adult/speed-goalkeeper-U15-U18",
    ["/cycle-entrainement/seniors/endurance-puissance-futsal"]        : "/cycle-training/adult/endurance-power-futsal",
    ["/cycle-entrainement/seniors/endurance-puissance-futsal-U15-U18"]: "/cycle-training/adult/endurance-power-futsal-U15-U18",
    ["/cycle-entrainement/seniors/vitesse-futsal"]                    : "/cycle-training/adult/speed-futsal",
    ["/cycle-entrainement/seniors/vitesse-futsal-U15-U18"]            : "/cycle-training/youth/speed-futsal-U15-U18",
    ["/cycle-entrainement/seniors/endurance-puissance"]               : "/cycle-training/adult/endurance-power",
    ["/cycle-entrainement/jeunes/endurance-puissance-U15-U18"]        : "/cycle-training/youth/endurance-power-U15-U18",
    ["/cycle-entrainement/jeunes/coordination-U8-U12"]                : "/cycle-training/youth/coordination-U8-U12",
    ["/cycle-entrainement/jeunes/vitesse-U8-U12"]                     : "/cycle-training/youth/speed-U8-U12",
    ["/cycle-entrainement/jeunes/vitesse-U12-14"]                     : "/cycle-training/youth/speed-U12-14",
    ["/cycle-entrainement/jeunes/vitesse-U14-18"]                     : "/cycle-training/youth/speed-U14-18",
    ["/cycle-entrainement/seniors/periodisation-tactique"]            : "/cycle-training/adult/periodization-tactical",
    ["/les-emails"]                                                   : "/emails",
    ["/preparation-physique-des-jeunes-footballeurs"]                 : "/physical-preparation-of-young-footballers",
    ["/boutique-en-ligne"]                                            : "/online-store",
    ["/bientot-disponible"]                                           : "/coming-soon",
    ["/mention-legal"]                                                : "/legal-notices",
    ["/qui-sommes-nous"]                                              : "/about-us",
  }